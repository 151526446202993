import { useValues } from "kea";
import React, { Suspense, useEffect } from "react";
import "./assets/styles/app.scss";
import { LoadingIndicator } from "./components/loading-indicator/loading-indicator";
import i18n from "./i18n";
import { authenticationLogic } from "./logics/authentication.logic";
import { userLogic } from "./logics/user.logic";
import { IUser } from "./models/user.i";
import { AppLayout } from "./pages/app-layout/app-layout";

export const App: React.FC<{}> = () => {
  const { isAuthenticated } = useValues(authenticationLogic);
  const {
    user,
    userLoading,
  }: { user: IUser; userLoading: boolean } = useValues(userLogic);
  // set the language when app starts
  useEffect(() => {
    if (user && !userLoading) {
      i18n.changeLanguage(user.languagePreference);
    }
  });
  return (
    <Suspense fallback={LoadingIndicator}>
      {isAuthenticated ? (
        <AppLayout />
      ) : (
        <div>
          Unauthorized Request. Please login and try opening this window again.
        </div>
      )}
    </Suspense>
  );
};
