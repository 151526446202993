import {
  Button,
  Classes,
  Dialog,
  Icon,
  IconName,
  Intent,
} from "@blueprintjs/core";
import classNames from "classnames";
import React, { useEffect } from "react";
import { IComponentProps } from "../../component-props.i";
import "./modal.scss";

export interface IModalProps extends IComponentProps {
  isOpen: boolean;
  icon?: IconName;
  button1Text?: string;
  button1Intent?: Intent;
  button1Action?: () => void;
  button2Text?: string;
  button2Intent?: Intent;
  button2Action?: () => void;
  button3Text?: string;
  button3Intent?: Intent;
  button3Action?: () => void;
  onClose?: () => void;
  onLoad?: () => void;
}

export const Modal: React.FC<IModalProps> = (props) => {
  const defaultClass = "modal";
  useEffect(() => {
    if (props.onLoad) {
      props.onLoad();
    }
  }, []);

  return (
    <Dialog
      className={classNames(defaultClass, props?.className)}
      onClose={() => props.onClose?.call(null)}
      isOpen={props.isOpen}
    >
      <div className={`bp3-dialog-header ${defaultClass}__header`}>
        <h4 className="bp3-heading"></h4>
        <button
          aria-label="close"
          onClick={() => props.onClose?.call(null)}
          className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
        ></button>
      </div>
      <div
        className={classNames(
          Classes.ALERT_BODY,
          Classes.DIALOG_BODY,
          "align-items-center",
          `${defaultClass}__body`
        )}
      >
        {props.icon && (
          <Icon
            icon={props.icon}
            iconSize={62}
            className={`${defaultClass}__icon`}
          ></Icon>
        )}
        <div
          className={classNames(
            Classes.ALERT_CONTENTS,
            `${defaultClass}__content`
          )}
        >
          {props.children}
        </div>
      </div>
      <div
        className={classNames(Classes.DIALOG_FOOTER, `${defaultClass}__footer`)}
      >
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {!!props.button1Text && !!props.button1Action && (
            <Button
              intent={props?.button1Intent}
              onClick={() => props.button1Action?.call(null)}
            >
              {props.button1Text}
            </Button>
          )}
          {!!props.button2Text && !!props.button2Action && (
            <Button
              intent={props?.button2Intent}
              onClick={() => props.button2Action?.call(null)}
            >
              {props.button2Text}
            </Button>
          )}
          {!!props.button3Text && !!props.button3Action && (
            <Button
              intent={props?.button3Intent}
              onClick={() => props.button3Action?.call(null)}
            >
              {props.button3Text}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
