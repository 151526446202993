import { useService } from "@creately/use-service";
import { kea } from "kea";
import { ApiService } from "../services/api.s";
import {
  buildTranslatedErrorNotification,
  buildTranslatedSuccessNotification,
} from "../helpers/notification.h";
import { API_SERVICE, AUTHENTICATION } from "../token";
import { notificationLogic } from "./notification.logic";
import { Authentication } from "../services/authentication.s";
import { appLogic } from "./app.logic";
import { routeLogic } from "./route.logic";
import { IPage } from "./../models/page.i";

interface IAction {
  actions: {
    loadUsers: any;
    loadOrgUsers: any;
    loadOrganization: any;
    registerService: any;
    initAuth: any;
    showNotification: any;
  };
}

export const userLogic = kea({
  connect: {
    actions: [
      notificationLogic,
      ["showNotification"],
      appLogic,
      ["track", "closeIfEmbedded"],
      routeLogic,
      ["setMainSection"],
      appLogic,
      ["track", "trackNotification"],
    ],
  },

  actions: () => ({
    registerService: true,
    setEmail: (email: any) => ({ email }),
    setUser: (user: any) => ({ user }),
    updateEmail: (email: any) => ({ email }),
    loadOrgUsers: (page: IPage) => ({ page }),
    loadOrganization: () => ({}),
    organizationUpdate: (organization: any) => ({ organization }),
    organizationUpdateImage: (image: any) => ({ image }),
    organizationRemoveMember: (email: any) => ({ email }),
    organizationUpdateMember: (user: any) => ({ user }),
    removeBillingContact: (email: any) => ({ email }),
    updateAvatar: (avatar: any) => ({ avatar }),
    setLanguage: (language: any) => ({ language }),
  }),

  reducers: () => ({
    organization: [
      [],
      {
        organizationUpdateImage: (state: any, { profileImage }: any) => {
          return { ...state, profileImage };
        },
      },
    ],
    orgUserList: [
      [],
      {
        loadOrgUsersSuccess: (state: any, { orgUserList }: any) => {
          return { ...state, ...orgUserList.organization };
        },
        organizationUpdateMember: (state: any, { user }: any) => {
          const updatedUsers = state.users.map((orgUser: any) => {
            if (orgUser.email == user.email) {
              orgUser = { ...orgUser, ...user };
            }
            return orgUser;
          });
          return { ...state, users: updatedUsers };
        },
        organizationRemoveMember: (state: any, { email }: any) => {
          const filteredUsers = state.users.filter(
            (user: { email: any }) => user.email !== email
          );
          return { ...state, users: filteredUsers };
        },
      },
    ],
    user: [
      [],
      {
        loadUsersSuccess: (state: any, { user }: any) => {
          const filledSeats = user?.organization?.users?.length;
          let organization = {
            ...state.organization,
            ...user?.organization,
            filledSeats,
          };
          return { ...user, organization };
        },
        setEmail: (state: any, { email }: any) => {
          return { ...state, email };
        },
        organizationUpdate: (state: any, { organization }: any) => {
          organization = { ...state.organization, ...organization };
          return { ...state, organization };
        },
        organizationRemoveMember: (state: any) => {
          let organization = { ...state.organization };
          organization.filledSeats =
            organization.filledSeats > 0 ? organization.filledSeats - 1 : 0;
          return { ...state, organization };
        },
        removeBillingContact: (state: any, { email }: any) => {
          const filteredBillingContacts = state.organization.billingContacts.filter(
            (contactEmail: any) => contactEmail.email !== email
          );
          let organization = { ...state.organization };
          organization.billingContacts = filteredBillingContacts;
          return { ...state, organization };
        },
        updateAvatar: (state: any, { avatar }: any) => {
          return { ...state, avatar };
        },
        setLanguage: (state: any, { language }: any) => {
          return { ...state, language };
        },
      },
    ],
  }),

  listeners: ({ actions }: any) => ({
    updateEmail: async ({ email }: any) => {
      const api = useService<ApiService>(API_SERVICE);
      await api.updateUser({ email });
      actions.setEmail(email);
      actions.showNotification(
        buildTranslatedSuccessNotification(
          "notifications.userEmailUpdated.heading",
          "notifications.userEmailUpdated.description"
        )
      );
    },

    updateUserSuccess: ({ updateUser }: any) => {
      if (!updateUser.userUpdate) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.setUser(updateUser);
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.userUpdated.heading",
            "notifications.userUpdated.description"
          )
        );
      }
    },
    addExtendSubOfferSuccess: ({ addExtendSubOffer }: any) => {
      if (!addExtendSubOffer.userPromotionalExtendSubEndTermDate) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.loadUsers();
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.addExtendSubOffer.heading",
            "notifications.addExtendSubOffer.description"
          )
        );
      }
    },
    claimPersonalMonPlanOfferSuccess: ({ claimPersonalMonPlanOffer }: any) => {
      if (!claimPersonalMonPlanOffer.userPromotionalPersonalMonthly) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.loadUsers();
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.claimPersonalMonPlanOffer.heading",
            "notifications.claimPersonalMonPlanOffer.description"
          )
        );
      }
    },
    closeUserAccountSuccess: ({ closeUserAccount }: any) => {
      if (!closeUserAccount.userCloseAccount) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.accountClose.heading",
            "notifications.accountClose.description"
          )
        );
        actions.setMainSection(true);
        const auth: Authentication = useService<Authentication>(AUTHENTICATION);
        auth.logOut();
        actions.closeIfEmbedded();
      }
    },
    deleteUserAccountSuccess: ({ deleteUserAccount }: any) => {
      if (!deleteUserAccount.userCloseAccount) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.accountClose.heading",
            "notifications.accountClose.description"
          )
        );
        actions.setMainSection(true);
        const auth: Authentication = useService<Authentication>(AUTHENTICATION);
        auth.logOut();
        actions.closeIfEmbedded();
      }
    },
    uploadAvatarSuccess: ({ uploadAvatar }: any) => {
      if (uploadAvatar.avatar.url) {
        actions.updateAvatar(uploadAvatar.avatar);
      } else {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "notifications.uploadFailed.heading",
            "notifications.uploadFailed.description"
          )
        );
      }
    },
  }),

  loaders: ({ actions }: any) => ({
    organization: [
      [],
      {
        loadOrganization: async () => {
          const auth: Authentication = useService<Authentication>(
            AUTHENTICATION
          );
          if (auth.isAuthenticated) {
            const api = useService<ApiService>(API_SERVICE);
            const data = await api.getOrganizationDetails();
            return data?.organization;
          } else {
            return undefined;
          }
        },
      },
    ],
    orgUserList: [
      [],
      {
        loadOrgUsers: async ({ page }: any) => {
          const auth: Authentication = useService<Authentication>(
            AUTHENTICATION
          );
          if (auth.isAuthenticated) {
            const api = useService<ApiService>(API_SERVICE);
            const data = await api.getOrgUsersDetails(page);
            return data;
          } else {
            return undefined;
          }
        },
      },
    ],
    user: [
      [],
      {
        loadUsers: async () => {
          const auth: Authentication = useService<Authentication>(
            AUTHENTICATION
          );
          if (auth.isAuthenticated) {
            const api = useService<ApiService>(API_SERVICE);
            const data = await api.getUserDetails();
            return data?.user;
          } else {
            return undefined;
          }
        },
      },
    ],
    updateUser: [
      false,
      {
        updateUser: async (user: any) => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser(user);
          return { ...user, ...res };
        },
      },
    ],
    addExtendSubOffer: [
      false,
      {
        addExtendSubOffer: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.addExtendSubOffer();
          return { ...res };
        },
      },
    ],
    claimPersonalMonPlanOffer: [
      false,
      {
        claimPersonalMonPlanOffer: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.claimPersonalMonPlanOffer();
          return { ...res };
        },
      },
    ],
    closeUserAccount: [
      false,
      {
        closeUserAccount: async (answers: any) => {
          const api = useService<ApiService>(API_SERVICE);
          await api.closeAccountAnswers(answers);
          const res = await api.closeUserAccount();
          return { ...res };
        },
      },
    ],
    deleteUserAccount: [
      false,
      {
        deleteUserAccount: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.closeUserAccount();
          return { ...res };
        },
      },
    ],
    uploadAvatar: [
      "",
      {
        uploadAvatar: async (data: { fileBase64: string }) => {
          const api = useService<ApiService>(API_SERVICE);
          let payloadData;
          await api
            .uploadAvatar(data.fileBase64)
            .then((file) => {
              const payload = { avatar: { url: file?.userUpdateAvatar?.url } };
              actions.track("left.account.myAccount.uploadLogo.click", {
                value1Type: "status",
                value1: "success",
              });
              payloadData = payload;
            })
            .catch(() => {
              actions.track("left.account.myAccount.uploadLogo.click", {
                value1Type: "status",
                value1: "fail",
              });
            });
          return payloadData;
        },
      },
    ],
  }),

  events: ({ actions }: IAction) => ({
    afterMount: [actions.loadUsers],
  }),
});
