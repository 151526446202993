import { useActions, useValues } from "kea";
import React, { useEffect } from "react";
import { Grid } from "react-flexbox-grid";
import { PageTitle } from "../../components/page-title/page-title";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";
import { OrganizationCard } from "../../components/organization-card/organization-card";
import { OrganizationPrivileges } from "../../components/organization-privileges/organization-privileges";
import { PageSection } from "../../components/page-section/page-section";
import {
  hasPermission,
  PermissionType,
} from "../../components/permissions/permissions";
import { organizationLogic } from "../../logics/organization.logic";
import { userLogic } from "../../logics/user.logic";
import { IOrganization } from "../../models/organization.i";
import { IUser } from "../../models/user.i";
import "./organization-page.scss";

export const OrganizationPage: React.FC<{}> = () => {
  const defaultClass = "organization-page";

  const {
    user,
    organization,
    organizationLoading,
  }: { user : IUser, organization: IOrganization; organizationLoading: boolean } = useValues(
    userLogic
  );
  const { loadOrganization, updateOrganization, uploadImage } = useActions(
    organizationLogic
  );

  const { t } = useTranslation();

  const handleOrgNameChange = (name: string) => {
    updateOrganization({ name: name, id: organization.id });
  };

  const handleImageUpload = (image: string) => {
    uploadImage({ fileBase64: image });
  };

  const getPlanName = () => {
    let name = user?.planName;
    if (organization?.planName) {
      name = organization?.planName;
    }
    return name;
  };

  useEffect(() => {
    loadOrganization();
  }, []);

  return organizationLoading ? (
    LoadingIndicator
  ) : (
    <div className={defaultClass}>
      <Grid fluid>
        <PageSection>
          <PageTitle>{t("organization")}</PageTitle>
        </PageSection>
        <PageSection>
          {organization && (
            <OrganizationCard
              name={organization.name}
              website={organization.domain}
              plan={getPlanName()}
              logoUrl={organization.profileImage?.url}
              updateOrganizationName={handleOrgNameChange}
              uploadImage={handleImageUpload}
              disabled={
                !hasPermission(user?.role, PermissionType.CanEditOrganization)
              }
            />
          )}
        </PageSection>
        <PageSection>
          <OrganizationPrivileges />
        </PageSection>
      </Grid>
    </div>
  );
};
