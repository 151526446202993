import axios, { AxiosRequestConfig } from "axios";

export async function graphql(
  query: string,
  headers: object = {}
): Promise<any> {
  const url: string = `${process.env.API_URL}/v0`;
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: headers,
    data: { query },
    url,
  };
  try {
    const { data } = await axios(options);
    if (data?.errors) {
      throw new Error(data.errors?.shift()?.extensions?.exception?.code);
    }
    return data.data;
  } catch (error) {
    throw new Error(error?.extensions?.exception.code || error?.message);
  }
}
