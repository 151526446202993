import { Button, MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IComponentProps } from "../../component-props.i";
import "./select-input.scss";
import PerfectScrollbar from "react-perfect-scrollbar";

export interface ISelectInputProps extends IComponentProps {
  placeholder?: string;
  value?: ISelectItem;
  onChange?: (value: ISelectItem) => void;
  items: ISelectItem[];
  disabled?: boolean;
  filterable?: boolean;
}

export interface ISelectItem {
  id: any;
  label: string;
}

export const SelectInput: FC<ISelectInputProps> = (props) => {
  const defaultClass = "select-input";

  const [selectedValue, setSelectedValue] = useState<ISelectItem>();

  const { t } = useTranslation();

  const CustomSelect = Select.ofType<ISelectItem>();

  const renderSelectItem: ItemRenderer<ISelectItem> = (
    item,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        text={item.label}
        key={item.id}
        onClick={handleClick}
      />
    );
  };

  const filterItems: ItemPredicate<ISelectItem> = (
    query,
    item,
    _index,
    exactMatch
  ) => {
    const normalizedTitle = item.label.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  };

  const handleItemSelect = (item: ISelectItem | null) => {
    !!item && setSelectedValue(item);
    !!item && props?.onChange?.call(null, item);
  };

  useEffect(() => {
    if (!!props.value) {
      setSelectedValue(props.value);
    }
  });

  return (
    <PerfectScrollbar>
    <CustomSelect
      className={classNames(
        defaultClass,
        props?.className,
        !!!selectedValue && `${defaultClass}__unselected`
      )}
      items={props.items}
      onItemSelect={handleItemSelect}
      itemPredicate={!!props.filterable ? filterItems : undefined}
      filterable={!!props?.filterable}
      itemRenderer={renderSelectItem}
      activeItem={selectedValue}
      noResults={<MenuItem disabled={true} text={t("noResults")} />}
      popoverProps={{ usePortal: true, minimal: true }}
      disabled={props?.disabled}
    >
      <Button
        className="placeholder-text"
        text={
          selectedValue?.label ||
          props?.placeholder ||
          t("components.selectInput.placeholder")
        }
        rightIcon="caret-down"
      />
    </CustomSelect>
    </PerfectScrollbar>
  );
};
