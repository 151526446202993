import React, { useState } from "react";
import { IComponentProps } from "../../../component-props.i";
import { userLogic } from "../../../logics/user.logic";
import { IUser } from "../../../models/user.i";
import "./billing-contact-item.scss";
import { useActions, useValues } from "kea";
import { organizationLogic } from "../../../logics/organization.logic";
import { Role } from "../../../components/permissions/permissions";
import classNames from "classnames";
import { AnchorButton, H6, Icon, Position, Tooltip } from "@blueprintjs/core";
import { Col, Row } from "react-flexbox-grid";
import { Modal } from "../../../components/modal/modal";
import { useTranslation } from "react-i18next";
import { IconTrash } from "../../../components/icons/icons";
import { appLogic } from "../../../logics/app.logic";

export interface IBillingContactItemProps extends IComponentProps {
  label: string;
  email: string;
  thumbnail?: string;
  isYou: boolean;
  role?: string;
  billingContactsCount: number;
  currentUserRole?: string;
}

/**
 * This component is the used to show Billing contact (Billing contact tile).
 * @author Nuwan
 * @since 2021-04-08
 */
export const BillingContactItem: React.FC<IBillingContactItemProps> = (
  props
) => {
  const defaultClass = "billing-contact-item";
  const { updateOrganization } = useActions(organizationLogic);
  const { user }: { user: IUser; userLoading: boolean } = useValues(userLogic);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { track } = useActions(appLogic);

  const handleDeleteButtonClick = (email: string) => {
    updateOrganization({
      id: user.organization.id,
      removeBillingContact: email,
    });
    track("dialog.accountPortal.billing.click", {
      value1Type: "button label",
      value1: "Remove",
    });
    toggleIsOpen();
  };

  const handleCancelButtonClick = () => {
    toggleIsOpen();
    track("dialog.accountPortal.billing.click", {
      value1Type: "button label",
      value1: "Cancel",
    });
  };

  const isTrashIconDisabled = () => {
    if (props.billingContactsCount === 1) {
      return true;
    } else {
      return (
        props?.currentUserRole !== Role.SuperAdmin &&
        props.role === Role.SuperAdmin
      );
    }
  };

  const handleTrashBtnClick = () => {
    toggleIsOpen();
    let role: string;
    if (props.isYou) {
      role = "Myself";
    } else {
      role = props.role ? props.role : "";
    }
    track("accountPortal.billing.removeContact.click", {
      value1Type: "role",
      value1: role,
    });
  };

  const TrashButton = () => {
    let tooltip = <div>{t("pages.billing.removePopUp")}</div>;
    if (props?.role === Role.SuperAdmin && props.billingContactsCount == 1) {
      tooltip = (
        <div className={classNames(`${defaultClass}__tooltip`)}>
          {t("pages.billing.removeOwnerPopUp")}
        </div>
      );
    }
    return (
      <Tooltip content={tooltip} position={Position.BOTTOM_RIGHT}>
        <AnchorButton
          className={classNames(`${defaultClass}__trash-icon`)}
          disabled={isTrashIconDisabled()}
          icon={IconTrash}
          minimal={true}
          onClick={() => handleTrashBtnClick()}
        />
      </Tooltip>
    );
  };

  const loadTrackEvent = () => {
    track("dialog.accountPortal.billing.load", {
      value1Type: "title",
      value1: "Remove Billing Contact",
    });
  };

  const getUserLabel = (label: string, isYou?: boolean) => {
    return isYou ? `${label}(You)` : label;
  };

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const getPopUpRemoveBtnName = () => {
    return props.isYou ? t("removeYourself") : t("remove");
  };

  const handleClose = () => {
    toggleIsOpen();
    track("dialog.accountPortal.billing.click", {
      value1Type: "button label",
      value1: "close",
    });
  };

  const billingContactRemovePopUp = () => {
    if (props.isYou && props?.role === Role.SuperAdmin) {
      return (
        <Row>
          <Col md={2}>
            <Icon
              className={classNames(`${defaultClass}__icon-main`)}
              iconSize={62}
              icon="error"
            ></Icon>
          </Col>
          <Col md={10}>
            <H6>{t("pages.billing.deleteTooltip.ownerTitle")}</H6>
            <p>{t("pages.billing.deleteTooltip.ownerDescription")}</p>
            <p className={classNames(`${defaultClass}__footer-text`)}>
              {t("pages.billing.deleteTooltip.ownerSubDescription")}
            </p>
          </Col>
        </Row>
      );
    } else if (props.isYou) {
      return (
        <Row>
          <Col md={2}>
            <Icon
              className={classNames(`${defaultClass}__icon-main`)}
              iconSize={62}
              icon="error"
            ></Icon>
          </Col>
          <Col md={10}>
            <H6>{t("pages.billing.deleteTooltip.ownerTitle")}</H6>
            <p>{t("pages.billing.deleteTooltip.ownerDescription")}</p>
            <p className={classNames(`${defaultClass}__footer-text`)}>
              {t("pages.billing.deleteTooltip.nonOwnerSubDescription")}
            </p>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col md={2}>
            <Icon
              className={classNames(`${defaultClass}__icon-main`)}
              iconSize={62}
              icon="error"
            ></Icon>
          </Col>
          <Col md={10}>
            <H6>{t("pages.billing.deleteTooltip.title")}</H6>
            <p>
              {t("pages.billing.deleteTooltip.newDescription", {
                email: props.email,
              })}
            </p>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div>
      <div className={classNames(`${defaultClass}__tile-container`)}>
        <Row>
          <Col
            sm={11}
            md={11}
            className={classNames(`${defaultClass}__wrapper`)}
          >
            {!props.thumbnail && (
              <Icon
                iconSize={30}
                className={classNames(`${defaultClass}__avatar-icon`)}
                icon="user"
                color="grey"
              />
            )}
            {props.thumbnail && (
              <img
                className={classNames(`${defaultClass}__avatar-icon`)}
                src={props.thumbnail}
              />
            )}
            <div>
              {!props.label && (
                <div>
                  <span
                    className={classNames(`${defaultClass}__contact-title`)}
                  >
                    {props.email}
                  </span>
                </div>
              )}
              {props.label && (
                <div>
                  <div>
                    <span
                      className={classNames(`${defaultClass}__contact-title`)}
                    >
                      {getUserLabel(props.label, props.isYou)}
                    </span>
                  </div>
                  <div>
                    <span className={classNames(`${defaultClass}__email-text`)}>
                      {props.email}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </Col>

          <Col sm={1} md={1}>
            <TrashButton />
            <Modal
              className={classNames(`${defaultClass}__confirmation-popup`)}
              onClose={handleClose}
              isOpen={isOpen}
              button2Text={t("cancel")}
              button2Action={handleCancelButtonClick}
              button1Text={getPopUpRemoveBtnName()}
              button1Action={() => {
                handleDeleteButtonClick(props.email);
              }}
              button1Intent={"primary"}
              onLoad={loadTrackEvent}
            >
              {billingContactRemovePopUp()}
            </Modal>
          </Col>
        </Row>
      </div>
    </div>
  );
};
