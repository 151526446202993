import classNames from "classnames";
import React from "react";
import { IComponentProps } from "../../component-props.i";
import "./page-title.scss";

export const PageTitle: React.FC<IComponentProps> = (props) => {
  const defaultClass = "page-title";

  return (
    <div className={classNames(defaultClass, props?.className)}>
      <h1 className="page-title__title">{props.children}</h1>
    </div>
  );
};
