import { Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IComponentProps } from "../../component-props.i";
import { EditableTextInput } from "../editable-text-input/editable-text-input";
import { ImageViewUploadInput } from "../image-view-upload-input/image-view-upload-input";
import "./organization-card.scss";
import { IconNoImage } from "../icons/icons";

export interface IOrganizationCardProps extends IComponentProps {
  name: string;
  website?: string;
  plan: string;
  logoUrl?: string;
  updateOrganizationName(name: string): void;
  uploadImage(image: string): void;
  disabled?: boolean;
}

export const OrganizationCard: FC<IOrganizationCardProps> = (props) => {
  const defaultClass = "organization-card";

  const maxNameLength = 40;

  const [name, setName] = useState(props.name);

  const [isInvalid, setInvalid] = useState(false);

  const { t } = useTranslation();

  const handleNameConfirm = (value: string): void => {
    setName(value);
    props.updateOrganizationName.call(null, value);
  };

  const handleNameChange = (value: string): void => {
    setName(value);
  };

  const handleImageChange = (image: string): void => {
    props.uploadImage.call(null, image);
  };

  const handleNameInvalid = (invalid: boolean) => {
    setInvalid(invalid);
  };

  const validateNameLength = (name: string) => {
    return name.length <= maxNameLength;
  };

  const validateNameChars = (name: string) => {
    return window.gravity.validator.isValidName(name) === true;
  };

  const validateName = (name: string) => {
    if (!!!name?.length) {
      return false;
    }
    return validateNameLength(name) && validateNameChars(name);
  };

  return (
    <div className={classNames(defaultClass, props?.className)}>
      <div className={`${defaultClass}__logo-container`}>
        <ImageViewUploadInput
          imageUrl={props.logoUrl}
          uploadImage={handleImageChange}
          className={`${defaultClass}__logo`}
          disabled={props.disabled}
          placeholderIcon={IconNoImage}
          imageUnavailableIcon={IconNoImage}
        />
      </div>
      <div className="w-100">
        {!(!!!props.name && props.disabled) && (
          <Tooltip
            content={t("components.organizationCard.editOrganizationTooltip")}
            disabled={props.disabled}
          >
            <EditableTextInput
              className={`${defaultClass}__title`}
              name="organization_name"
              value={name}
              onConfirm={handleNameConfirm}
              onChange={handleNameChange}
              disabled={props.disabled}
              onInvalid={handleNameInvalid}
              maxLength={maxNameLength}
              validate={validateName}
            />
          </Tooltip>
        )}
        <div className={`${defaultClass}__info-container`}>
          <div>
            {props.website && (
              <span className={`${defaultClass}__website"`}>
                {props.website} |{" "}
              </span>
            )}
            <span className={`${defaultClass}__plan"`}>{props.plan}</span>
          </div>
          {isInvalid && !validateNameLength(name) && (
            <div className={`${defaultClass}__char-count-error`}>
              {name.length}/{maxNameLength}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
