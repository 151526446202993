import { Intent, Portal, Spinner } from "@blueprintjs/core";
import React from "react";
import "./loading-indicator.scss";

const defaultClass = "loading-indicator";

export const LoadingIndicator = (
  <Portal className={`${defaultClass}__portal`}>
    <Spinner className={`${defaultClass}__spinner`} intent={Intent.PRIMARY} />
  </Portal>
);
