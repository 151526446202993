import { Card } from "@blueprintjs/core";
import { useActions, useValues } from "kea";
import React, { useEffect, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";
import { PageSection } from "../../components/page-section/page-section";
import { PageTitle } from "../../components/page-title/page-title";
import {
  ISelectItem,
  SelectInput,
} from "../../components/select-input/select-input";
import { teamRoleSetting, teamRoleSettingV2, teamSettingEnableDisable } from "../../const/team";
import { organizationLogic } from "../../logics/organization.logic";
import { userLogic } from "../../logics/user.logic";
import { IUser } from "../../models/user.i";
import "./setting-page.scss";

export const SettingPage: React.FC<{}> = () => {
  const defaultClass = "setting-page";

  const {
    user,
    userLoading,
  }: { user: IUser; userLoading: boolean } = useValues(userLogic);

  const { updateOrganizationSetting } = useActions(organizationLogic);

  const [selectedAnyoneWithOrgSetting, setSelectedAnyoneWithOrgSetting] = useState<
    ISelectItem | undefined
  >();

  const [selectedAnyoneWithLinkSetting, setSelectedAnyoneWithLinkSetting] = useState<
    ISelectItem | undefined
  >();

  const [selectedShareLimitationForExternalGuests, setSelectedShareLimitationForExternalGuests] = useState<
    ISelectItem | undefined
  >();

  const [selectedEmbedPanelSetting, setSelectedEmbedPanelSetting] = useState<
    ISelectItem | undefined
  >();

  const [selectedAllowLinkShareWithoutPassword, setSelectedAllowLinkShareWithoutPassword] = useState<
    ISelectItem | undefined
  >();

  const [selectedPromotionalEmailsSetting, setSelectedPromotionalEmailsSetting] = useState<
    ISelectItem | undefined
  >();

  const [selected2FASetting, setSelected2FASetting] = useState<
    ISelectItem | undefined
  >();

  const [selectedOnboardingAndTutorialEmailsSetting, setSelectedOnboardingAndTutorialEmailsSetting] = useState<
    ISelectItem | undefined
  >(); // Questions

  const [selectedOnboardingQuestionsSetting, setSelectedOnboardingQuestionsSetting] = useState<
    ISelectItem | undefined
  >();

  const [selectedSendTeamInvitaionWithDiagramShare, setSelectedSendTeamInvitaionWithDiagramShare] = useState<
    ISelectItem | undefined
  >();

  const { t } = useTranslation();

  const handleAnyoneWithOrganizationChanges = (data: any) => {
    const payload = {
      settingId: 'AnyoneInTheOrganization',
      userAccess: data.id,
    };
    setSelectedAnyoneWithOrgSetting(data);
    updateOrganizationSetting(payload);
  };

  const handleShareLimitationForExternalGuestsChanges = (data: any) => {
    const payload = {
      settingId: 'ShareLimitationForExternalGuests',
      userAccess: data.id,
    };
    setSelectedShareLimitationForExternalGuests(data);
    updateOrganizationSetting(payload);
  };

  const handleAnyoneWithLinkChanges = (data: any) => {
    const payload = {
      settingId: 'AnyoneWithTheLink',
      userAccess: data.id,
    };
    setSelectedAnyoneWithLinkSetting(data);
    updateOrganizationSetting(payload);
  };

  const handleAmbedPanelChanges = (data: any) => {
    const payload = {
      settingId: 'EmbedPanel',
      userAccess: data.id,
    };
    setSelectedEmbedPanelSetting(data);
    updateOrganizationSetting(payload);
  };

  const handleAllowLinkShareWithoutPassword = (data: any) => {
    const payload = {
      settingId: 'AllowSharingWithoutPassword',
      userAccess: data.id,
    };
    setSelectedAllowLinkShareWithoutPassword(data);
    updateOrganizationSetting(payload);
  }

  const handlePromotionalEmailsChanges = (data: any) => {
    const payload = {
      settingId: 'PromotionalEmails',
      userAccess: data.id,
    };
    setSelectedPromotionalEmailsSetting(data);
    updateOrganizationSetting(payload);
  };

  const handle2FASettingChange = (data: any) => {
    const payload = {
      settingId: 'mandatory2FA',
      userAccess: data.id,
    };
    setSelected2FASetting(data);
    updateOrganizationSetting(payload);
  };

  const handleOnboardingAndTutorialEmailsChanges = (data: any) => {
    const payload = {
      settingId: 'OnboardingAndTutorialEmails',
      userAccess: data.id,
    };
    setSelectedOnboardingAndTutorialEmailsSetting(data);
    updateOrganizationSetting(payload);
  };

  const handleOnboardingQuestionsChanges = (data: any) => {
    const payload = {
      settingId: 'OnboardingQuestions',
      userAccess: data.id,
    };
    setSelectedOnboardingQuestionsSetting(data);
    updateOrganizationSetting(payload);
  };

  const handleSendTeamInvitaionWithDiagramShare = (data: any) => {
    const payload = {
      settingId: 'SendTeamInvitaionWithDiagramShare',
      userAccess: data.id,
    };
    setSelectedSendTeamInvitaionWithDiagramShare(data);
    updateOrganizationSetting(payload);
  };

  const filteredAnyoneWithOrgSetting = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Team"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'AnyoneInTheOrganization');
    if ( setting ) {
      value = teamRoleSetting.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredShareLimitationForExternalGuests = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Enable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'ShareLimitationForExternalGuests');
    if ( setting ) {
      value = teamSettingEnableDisable.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredAnyoneWithLinkSetting = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Team"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'AnyoneWithTheLink');
    if ( setting ) {
      value = teamRoleSetting.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredEmbedPanelkSetting = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Enable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'EmbedPanel');
    if ( setting ) {
      value = teamSettingEnableDisable.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredAllowSharingWithoutPasswordSetting = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Enable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'AllowSharingWithoutPassword');
    if ( setting ) {
      value = teamSettingEnableDisable.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredPromotionalEmailsSetting = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Enable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'PromotionalEmails');
    if ( setting ) {
      value = teamSettingEnableDisable.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filtered2FASetting = () => {
    let value: ISelectItem | undefined = { id: 0, label: "Disable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'mandatory2FA');
    if ( setting ) {
      value = teamSettingEnableDisable.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredOnboardingAndTutorialEmailsSetting = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Enable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'OnboardingAndTutorialEmails');
    if ( setting ) {
      value = teamSettingEnableDisable.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredOnboardingQuestionsSetting = () => {
    let value: ISelectItem | undefined = { id: 15, label: "Enable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'OnboardingQuestions');
    if ( setting ) {
      value = teamSettingEnableDisable.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  const filteredSendTeamInvitaionWithDiagramShareSetting = () => {
    let value: ISelectItem | undefined = { id: 0, label: "Disable"};
    const setting = user.organization.settings.find(( setting ) => setting.settingId === 'SendTeamInvitaionWithDiagramShare');
    if ( setting ) {
      value = teamRoleSettingV2.find((role) => role.id === setting.userAccess);
    }
    return value;
  }

  useEffect(() => {
    setSelectedAnyoneWithOrgSetting(filteredAnyoneWithOrgSetting());
  }, [user, teamRoleSetting]);

  useEffect(() => {
    setSelectedShareLimitationForExternalGuests(filteredShareLimitationForExternalGuests());
  }, [user, teamSettingEnableDisable]);

  useEffect(() => {
    setSelectedAnyoneWithLinkSetting(filteredAnyoneWithLinkSetting());
  }, [user, teamRoleSetting]);

  useEffect(() => {
    setSelectedEmbedPanelSetting(filteredEmbedPanelkSetting());
  }, [user, teamSettingEnableDisable]);

  useEffect(() => {
    setSelectedAllowLinkShareWithoutPassword(filteredAllowSharingWithoutPasswordSetting());
  }, [user, teamSettingEnableDisable]);

  useEffect(() => {
    setSelectedPromotionalEmailsSetting(filteredPromotionalEmailsSetting());
  }, [user, teamSettingEnableDisable]);

  useEffect(() => {
    setSelected2FASetting(filtered2FASetting());
  }, [user, teamSettingEnableDisable]);

  useEffect(() => {
    setSelectedOnboardingAndTutorialEmailsSetting(filteredOnboardingAndTutorialEmailsSetting());
  }, [user, teamSettingEnableDisable]);

  useEffect(() => {
    setSelectedOnboardingQuestionsSetting(filteredOnboardingQuestionsSetting());
  }, [user, teamSettingEnableDisable]);

  useEffect(() => {
    setSelectedSendTeamInvitaionWithDiagramShare(filteredSendTeamInvitaionWithDiagramShareSetting());
  }, [user, teamRoleSettingV2]);

  return userLoading ? (
    LoadingIndicator
  ) : (
      <div className={defaultClass}>
        <Grid fluid>
          <PageSection>
            <PageTitle>{t("pages.organization-setting.orgPreferences")}</PageTitle>
          </PageSection>
          <PageSection>
            <h2>{t("pages.organization-setting.shareSettings")}</h2>
            <Card className="account-label">
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                    {t("pages.organization-setting.shareAnyoneInTheOrganization")}
                  </label>
                  <SelectInput
                    items={teamRoleSetting}
                    onChange={handleAnyoneWithOrganizationChanges}
                    value={selectedAnyoneWithOrgSetting}
                  />
                </Col>              
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.shareAnyoneInTheOrganizationDescription")}</label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                  {t("pages.organization-setting.sharingAccessForGuests")}
                  </label>
                  <SelectInput
                    items={teamSettingEnableDisable}
                    onChange={handleShareLimitationForExternalGuestsChanges}
                    value={selectedShareLimitationForExternalGuests}
                  />
                </Col>              
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.sharingAccessForGuestsDescription")}</label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                  {t("pages.organization-setting.sendTeamInvitaionWithDiagramShare")}
                  </label>
                  <SelectInput
                    items={teamRoleSettingV2}
                    onChange={handleSendTeamInvitaionWithDiagramShare}
                    value={selectedSendTeamInvitaionWithDiagramShare}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.sendTeamInvitaionWithDiagramShareDescription")}</label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                    {t("pages.organization-setting.shareAnyoneWithTheLink")}
                  </label>
                  <SelectInput
                    items={teamRoleSetting}
                    onChange={handleAnyoneWithLinkChanges}
                    value={selectedAnyoneWithLinkSetting}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.shareAnyoneWithTheLinkDescription")}</label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                    {t("pages.organization-setting.embedPanel")}
                  </label>
                  <SelectInput
                    items={teamSettingEnableDisable}
                    onChange={handleAmbedPanelChanges}
                    value={selectedEmbedPanelSetting}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.embedPanelDescription")}</label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                    {t("pages.organization-setting.allowLinkSharingWithoutPassword")}
                  </label>
                  <SelectInput
                    items={teamSettingEnableDisable}
                    onChange={handleAllowLinkShareWithoutPassword}
                    value={selectedAllowLinkShareWithoutPassword}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.allowLinkSharingWithoutPasswordDescription")}</label>
                </Col>
              </Row>


            </Card>
          </PageSection>
          <PageSection>
            <h2>{t("pages.organization-setting.setting2FAHeading")}</h2>
            <Card className="account-label">
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label">
                    {t("pages.organization-setting.setting2FAInfo")}
                  </label>
                  <SelectInput
                    items={teamSettingEnableDisable}
                    onChange={handle2FASettingChange}
                    value={selected2FASetting}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.setting2FADescription")}</label>
                </Col>
              </Row>
            </Card>
          </PageSection>
          <PageSection>
            <h2>{t("pages.organization-setting.onboardingAndPromotions")}</h2>
            <Card className="account-label">
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label">
                    {t("pages.organization-setting.promotionalEmails")}
                  </label>
                  <SelectInput
                    items={teamSettingEnableDisable}
                    onChange={handlePromotionalEmailsChanges}
                    value={selectedPromotionalEmailsSetting}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.promotionalEmailsDescription")}</label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                    {t("pages.organization-setting.onboardingAndTutorialEmails")}
                  </label>
                  <SelectInput
                    items={teamSettingEnableDisable}
                    onChange={handleOnboardingAndTutorialEmailsChanges}
                    value={selectedOnboardingAndTutorialEmailsSetting}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.onboardingAndTutorialEmailsDescription")}</label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label setting-label">
                    {t("pages.organization-setting.onboardingQuestions")}
                  </label>
                  <SelectInput
                    items={teamSettingEnableDisable}
                    onChange={handleOnboardingQuestionsChanges}
                    value={selectedOnboardingQuestionsSetting}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <label className="setting-description-label">{t("pages.organization-setting.onboardingQuestionsDescription")}</label>
                </Col>
              </Row>
            </Card>
          </PageSection>
        </Grid>
      </div>
    );
};
