import React from "react";
import { useValues } from "kea";
import { userLogic } from "../../logics/user.logic";
import { IUser } from "../../models/user.i";

/**
 * Role capabilities as per organization.
 */
export interface IRoleType {
  name: string;
  editable: boolean;
}

/**
 * User privileges as per the organization.
 */
export interface IPrivilege {
  name: string;
  roles: Array<IRoleType>;
}

/**
 * The various roles available in the app.
 */
export enum Role {
  Admin = "admin",
  SuperAdmin = "suAdmin",
  Member = "member",
  Guest = "guest",
  Reviewer = "reviewer",
  Suspended = "suspended",
}

export const PermissionsMatrix = [
  {
    name: "changeAdminPrivileges",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
    ],
  },
  {
    name: "addEditMembers",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
      {
        name: Role.Admin,
        editable: false,
      },
    ],
  },
  {
    name: "manageAccessAndSharing",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
      {
        name: Role.Admin,
        editable: true,
      },
    ],
  },
  {
    name: "addEditGroups",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
      {
        name: Role.Admin,
        editable: true,
      },
    ],
  },
  {
    name: "viewWorkspaces",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
      {
        name: Role.Admin,
        editable: true,
      },
      {
        name: Role.Member,
        editable: true,
      },
      {
        name: Role.Guest,
        editable: true,
      },
    ],
  },
  {
    name: "commentOnWorkspaces",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
      {
        name: Role.Admin,
        editable: false,
      },
      {
        name: Role.Member,
        editable: false,
      },
      {
        name: Role.Guest,
        editable: true,
      },
    ],
  },
  {
    name: "shareWorkspaces",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
      {
        name: Role.Admin,
        editable: true,
      },
      {
        name: Role.Member,
        editable: true,
      },
    ],
  },
  {
    name: "viewActivityLog",
    roles: [
      {
        name: Role.SuperAdmin,
        editable: false,
      },
      {
        name: Role.Admin,
        editable: true,
      },
    ],
  },
];

/**
 * User status available in the app.
 */
export enum Status {
  Active = 1,
  Inactive = 0,
  Pending = 2,
}

/**
 * The various plans available in the app.
 */
export enum Plan {
  Free = "PUBLIC",
  Personal = "PERSONAL",
  Pro = "PRO",
  Team = "TEAM",
  Enterprise = "ENTERPRISE",
  Lite = "LITE",
  PublicTeam = "PUBLIC_TEAM",
  LiteTeam = "LITE_TEAM",
}

/**
 * The various permission types available in the app.
 */
export enum PermissionType {
  CanEditOrganization = "canEditOrganization",
  CanViewLog = "canViewLog",
  CanInviteUsers = "canInviteUsers",
  CanViewMembers = "canViewMembers",
  CanEditUserRole = "canEditUserRole",
  CanUpgradeAdmin = "canUpgradeAdmin",
  CanUpgradeMember = "canUpgradeMember",
  CanUpgradeReviewer = "canUpgradeReviewer",
  CanUpgradeSuspended = "canUpgradeSuspended",
  CanDowngradeSuperAdmin = "canDowngradeSuperAdmin",
  CanDowngradeAdmin = "canDowngradeAdmin",
  CanDowngradeMember = "canDowngradeMember",
  CanDowngradeReviewer = "canDowngradeReviewer",
  CanRemoveMember = "canRemoveMember",
  CanRemoveReviewer = "canRemoveReviewer",
  CanRemoveAdmin = "canRemoveAdmin",
  CanRemoveSuperAdmin = "canRemoveSuperAdmin",
  CanResendInvitation = "canResendInvitation",
  CanViewPlan = "canViewPlan",
  CanChangePlan = "canChangePlan",
  CanCloseAccount = "canCloseAccount",
  CanCancelPlan = "canCancelPlan",
  CanBuyNewPlan = "canBuyNewPlan",
  CanViewBillingContacts = "canViewBillingContacts",
  CanAddBillingContact = "canAddBillingContact",
  CanRemoveBillingContact = "canRemoveBillingContact",
  CanViewPaymentMethod = "canViewPaymentMethod",
  CanChangePaymentMethod = "canChangePaymentMethod",
  CanViewNextPaymentDate = "canViewNextPaymentDate",
  CanViewBillingHistory = "canViewBillingHistory",
  CanViewBillingAddress = "canViewBillingAddress",
  CanEditBillingAddress = "canEditBillingAddress",
  CanApplyOffers = "canApplyOffers",
}

/**
 * Abbreviated permission types for convenience.
 */
export const PT = PermissionType;

/**
 * A permission map with available PermissionTypes mapped to Plan.
 */
const PlanPermissions = {
  [Plan.Enterprise]: [
    PT.CanEditOrganization,
    PT.CanViewLog,
    PT.CanInviteUsers,
    PT.CanViewMembers,
    PT.CanEditUserRole,
    PT.CanUpgradeAdmin,
    PT.CanUpgradeMember,
    PT.CanUpgradeReviewer,
    PT.CanUpgradeSuspended,
    PT.CanDowngradeSuperAdmin,
    PT.CanDowngradeAdmin,
    PT.CanDowngradeMember,
    PT.CanDowngradeReviewer,
    PT.CanRemoveMember,
    PT.CanRemoveReviewer,
    PT.CanRemoveAdmin,
    PT.CanRemoveSuperAdmin,
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanAddBillingContact,
    PT.CanRemoveBillingContact,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanViewNextPaymentDate,
    PT.CanViewBillingHistory,
    PT.CanViewBillingAddress,
    PT.CanEditBillingAddress,
    PT.CanApplyOffers,
  ],
  [Plan.Team]: [
    PT.CanEditOrganization,
    PT.CanViewLog,
    PT.CanInviteUsers,
    PT.CanViewMembers,
    PT.CanEditUserRole,
    PT.CanUpgradeAdmin,
    PT.CanUpgradeMember,
    PT.CanUpgradeReviewer,
    PT.CanUpgradeSuspended,
    PT.CanDowngradeSuperAdmin,
    PT.CanDowngradeAdmin,
    PT.CanDowngradeMember,
    PT.CanDowngradeReviewer,
    PT.CanRemoveMember,
    PT.CanRemoveReviewer,
    PT.CanRemoveAdmin,
    PT.CanRemoveSuperAdmin,
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanAddBillingContact,
    PT.CanRemoveBillingContact,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanViewNextPaymentDate,
    PT.CanViewBillingHistory,
    PT.CanViewBillingAddress,
    PT.CanEditBillingAddress,
    PT.CanApplyOffers,
  ],
  [Plan.LiteTeam]: [
    PT.CanEditOrganization,
    PT.CanViewLog,
    PT.CanInviteUsers,
    PT.CanViewMembers,
    PT.CanEditUserRole,
    PT.CanUpgradeAdmin,
    PT.CanUpgradeMember,
    PT.CanUpgradeReviewer,
    PT.CanUpgradeSuspended,
    PT.CanDowngradeSuperAdmin,
    PT.CanDowngradeAdmin,
    PT.CanDowngradeMember,
    PT.CanDowngradeReviewer,
    PT.CanRemoveMember,
    PT.CanRemoveReviewer,
    PT.CanRemoveAdmin,
    PT.CanRemoveSuperAdmin,
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanAddBillingContact,
    PT.CanRemoveBillingContact,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanViewNextPaymentDate,
    PT.CanViewBillingHistory,
    PT.CanViewBillingAddress,
    PT.CanEditBillingAddress,
    PT.CanApplyOffers,
  ],
  [Plan.PublicTeam]: [
    PT.CanEditOrganization,
    PT.CanViewLog,
    PT.CanInviteUsers,
    PT.CanViewMembers,
    PT.CanEditUserRole,
    PT.CanUpgradeAdmin,
    PT.CanUpgradeMember,
    PT.CanUpgradeReviewer,
    PT.CanUpgradeSuspended,
    PT.CanDowngradeSuperAdmin,
    PT.CanDowngradeAdmin,
    PT.CanDowngradeMember,
    PT.CanDowngradeReviewer,
    PT.CanRemoveMember,
    PT.CanRemoveReviewer,
    PT.CanRemoveAdmin,
    PT.CanRemoveSuperAdmin,
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanAddBillingContact,
    PT.CanRemoveBillingContact,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanViewNextPaymentDate,
    PT.CanViewBillingHistory,
    PT.CanViewBillingAddress,
    PT.CanEditBillingAddress,
    PT.CanApplyOffers,
  ],
  [Plan.Lite]: [
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanViewNextPaymentDate,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanApplyOffers,
  ],
  [Plan.Personal]: [
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanViewNextPaymentDate,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanApplyOffers,
  ],
  [Plan.Pro]: [
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanViewNextPaymentDate,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanApplyOffers,
  ],
  [Plan.Free]: [
    PT.CanViewPlan,
    PT.CanBuyNewPlan,
    PT.CanCloseAccount,
    PT.CanViewPaymentMethod,
    PT.CanViewNextPaymentDate,
  ],
};

/**
 * A permission map with available PermissionTypes mapped to Roles.
 */
const RolePermissions = {
  [Role.SuperAdmin]: [
    PT.CanEditOrganization,
    PT.CanViewLog,
    PT.CanInviteUsers,
    PT.CanViewMembers,
    PT.CanEditUserRole,
    PT.CanUpgradeAdmin,
    PT.CanUpgradeMember,
    // TODO: Enable these in V2 after adding Reviewer role
    // PT.CanUpgradeReviewer,
    // PT.CanUpgradeSuspended,
    PT.CanDowngradeSuperAdmin,
    PT.CanDowngradeAdmin,
    // PT.CanDowngradeMember,
    // PT.CanDowngradeReviewer,
    PT.CanRemoveMember,
    // PT.CanRemoveReviewer,
    PT.CanRemoveAdmin,
    PT.CanRemoveSuperAdmin,
    PT.CanResendInvitation,
    PT.CanViewPlan,
    PT.CanChangePlan,
    PT.CanCloseAccount,
    PT.CanCancelPlan,
    PT.CanViewBillingContacts,
    PT.CanAddBillingContact,
    PT.CanRemoveBillingContact,
    PT.CanViewPaymentMethod,
    PT.CanChangePaymentMethod,
    PT.CanViewNextPaymentDate,
    PT.CanViewBillingHistory,
    PT.CanViewBillingAddress,
    PT.CanEditBillingAddress,
    PT.CanApplyOffers,
  ],
  [Role.Admin]: [
    PT.CanEditOrganization,
    PT.CanViewLog,
    PT.CanInviteUsers,
    PT.CanViewMembers,
    PT.CanEditUserRole,
    PT.CanUpgradeReviewer,
    PT.CanDowngradeMember,
    PT.CanRemoveMember,
    PT.CanRemoveReviewer,
    PT.CanResendInvitation,
    PT.CanViewPlan,
    PT.CanViewBillingContacts,
    PT.CanViewNextPaymentDate,
  ],
  [Role.Member]: [
    PT.CanViewMembers,
    PT.CanViewPlan,
    PT.CanViewBillingContacts,
    PT.CanViewNextPaymentDate,
  ],
  [Role.Reviewer]: [
    PT.CanViewMembers,
    PT.CanViewPlan,
    PT.CanViewBillingContacts,
    PT.CanViewNextPaymentDate,
  ],
  [Role.Guest]: [] as PermissionType[],
  [Role.Suspended]: [
    PT.CanViewMembers,
    PT.CanViewPlan,
    PT.CanViewBillingContacts,
    PT.CanViewNextPaymentDate,
  ],
};

/**
 * A utility function that checks if the given role has at least one of the
 * the given permission types in the permissions map.
 * @param role the role to check for permission
 * @param permission the permission(s) to check against the role
 */
export const hasPermission = (
  role: Role,
  permission: PermissionType | PermissionType[],
  planType?: Plan
): boolean => {
  if (planType && !role) {
    if (
      typeof permission === "string" &&
      PlanPermissions[planType]?.includes(permission)
    ) {
      return true;
    } else if (
      Array.isArray(permission) &&
      !!PlanPermissions[planType]?.filter((plan) => permission.includes(plan))
        .length
    ) {
      return true;
    }
  }
  if (
    typeof permission === "string" &&
    RolePermissions[role]?.includes(permission)
  ) {
    return true;
  } else if (
    Array.isArray(permission) &&
    !!RolePermissions[role]?.filter((role) => permission.includes(role)).length
  ) {
    return true;
  }
  return false;
};

/**
 * Props for the Permission component.
 */
export interface IPermissionProps {
  permission: PermissionType | PermissionType[];
}

/**
 * A component that conditionally renders its children by
 * checking the given permissions and the current role set in the
 * PermissionContext against the permissions map.
 * @param props IPermissionProps for the component
 */
export const Permission: React.FC<IPermissionProps> = (props) => {
  const { user }: { user: IUser } = useValues(userLogic);
  return (
    <>
      {hasPermission(user.role, props.permission, user.planType)
        ? props.children
        : null}
    </>
  );
};
