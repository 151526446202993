import { Button, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IComponentProps } from "../../../component-props.i";
import { appLogic } from "../../../logics/app.logic";
import { useActions } from "kea";
import "./owner-details-popup.scss";

export interface IOwnerDetailsProps extends IComponentProps {
  firstname?: string;
  lastName?: string;
  email?: string;
  thumbnail?: string;
  closePopUp: () => void;
}

/**
 * This component will show the popup for the owner details.
 * @author Nuwan
 * @since 2021-04-08
 */
export const OwnerDetailsPopup: React.FC<IOwnerDetailsProps> = (props) => {
  const defaultClass = "owner-details-popup";
  const { t } = useTranslation();
  const { track } = useActions(appLogic);

  const handleCancel = () => {
    props.closePopUp();
    track("dialog.accountPortal.changePlan.click", {
      value1Type: "buttonName",
      value1: "cancel",
    });
  };

  useEffect(() => {
    track("dialog.accountPortal.changePlan.load", {
      value1: "Change your Plan",
    });
  }, []);

  return (
    <div className={classNames(`${defaultClass}__popup-box`)}>
      <div className={classNames(`${defaultClass}__box`)}>
        <div className={classNames(`${defaultClass}__section-1`)}>
          <div className={classNames(`${defaultClass}__close-btn-box`)}>
            <a
              className={classNames(`${defaultClass}__close-icon`)}
              onClick={props.closePopUp}
            >
              <Icon icon="cross" iconSize={10} />
            </a>
          </div>
          <div className={classNames(`${defaultClass}__content-section`)}>
            <div>
              <Icon
                icon="error"
                intent="primary"
                iconSize={50}
                className={classNames(`${defaultClass}__top-icon`)}
              />
            </div>
            <div className={classNames(`${defaultClass}__main-content`)}>
              <h5 className={classNames(`${defaultClass}__head1`)}>
                {t("pages.billing.changePlanPopUpTitle")}
              </h5>
              <p className={classNames(`${defaultClass}__description`)}>
                {t("pages.billing.changePlanPopUpSubTitle")}
              </p>
              <div className={classNames(`${defaultClass}__user-tile`)}>
                <div>
                  {!props.thumbnail && (
                    <Icon
                      iconSize={25}
                      className={classNames(`${defaultClass}__avatar`)}
                      icon="user"
                      color="grey"
                    />
                  )}
                  {props.thumbnail && (
                    <img
                      src={props.thumbnail}
                      className={classNames(`${defaultClass}__avatar`)}
                    />
                  )}
                </div>
                <div>
                  <p className={classNames(`${defaultClass}__owner`)}>
                    {`${props.firstname} ${props.lastName} (${t(
                      "pages.billing.accountManager"
                    )})`}
                  </p>
                  <p className={classNames(`${defaultClass}__email`)}>
                    {props.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(`${defaultClass}__section-2`)}>
          <Button
            className={classNames(`${defaultClass}__close-btn`)}
            onClick={handleCancel}
            minimal={false}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </div>
  );
};
