import React, { useEffect } from "react";
import {
  Button,
  Card,
  Popover,
  Position,
  Checkbox,
  Icon,
  Spinner,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Col, Row } from "react-flexbox-grid";
import { useTable, usePagination, Column, useSortBy } from "react-table";
import { useActions } from "kea";
import { MemberCard } from "../member-card/member-card";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { IFilter } from "./../../models/filter.i";
import { useDebounce } from "../../services/debounce";
import { IconFilter } from "./../../components/icons/icons";
import "./table.scss";
import {
  hasPermission,
  PermissionType,
  Role,
  Status,
} from "../permissions/permissions";
import { IUser } from "../../models/user.i";
import { appLogic } from "../../logics/app.logic";
import { tEn } from "../../services/en-translate.s";

//To Do need to change any to a interface.
const columns: Column<any>[] = [
  {
    accessor: "name",
    width: 3,
  },
  {
    accessor: "role",
    width: 2,
  },
  {
    accessor: "lastActiveDate",
    width: 1,
  },
  {
    accessor: "userStatus",
    width: 1,
  },
  {
    accessor: "confirmed",
    width: 3,
  },
];

export const Table: React.FC<any> = ({
  data,
  handleCancelInvitation,
  handleMemberDelete,
  handleMemberUpdate,
  getAllowedRoleChanges,
  handleResendMemberInvitation,
  currentRole,
  user,
  tablePage,
  pageCount,
  userLimitReached,
  getDeleteMemberPermission,
  handleLoadOrgUsers,
  openUpgradeModel,
  pageLoading,
}) => {
  const defaultClass = "table-section";
  const { t } = useTranslation();
  const { track } = useActions(appLogic);
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: tablePage.pageIndex,
        sortBy: [
          {
            id: "name",
            desc: tablePage.orderBy === "desc",
          },
        ],
      },
      manualPagination: true,
      pageCount: pageCount,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );

  const pages = Array.from({ length: pageCount }, (_, index) => index);

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    submitForm,
  } = useFormik({
    initialValues: tablePage.filter,
    onSubmit: (values: IFilter) => {
      handleLoadOrgUsers({ filter: values, pageIndex: 0 });
    },
  });

  const debouncedValues = useDebounce<IFilter>(values, 500);

  useEffect(() => {
    submitForm();
  }, [debouncedValues, submitForm]);

  const isChecked = <T extends unknown>(value: T, data: Array<T>) => {
    return data.includes(value);
  };

  const statusHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(
      `Clicked Filter Label ${tEn(
        `components.userTable.tags.${event.target.value}`
      )}`
    );
    track("accountPortal.users.searchFilters.click", {
      value1Type: "action",
      value1: `Clicked Filter Label ${tEn(
        `components.userTable.tags.${event.target.value}`
      )}`,
    });
    if (event.target.checked) {
      const status = values.status;
      status.push(parseInt(event.target.value));
      setValues({
        ...values,
        status,
      });
    } else {
      setValues({
        ...values,
        status: values.status.filter((o) => o !== parseInt(event.target.value)),
      });
    }
  };

  const startIndex = tablePage.pageIndex - 3 > 0 ? tablePage.pageIndex - 3 : 0;
  const endIndex = startIndex + 5;

  // Render the UI for your table
  return (
    <>
      <Card className={`d-flex flex-column ${defaultClass}__search user-search`}>
        <form
          onSubmit={handleSubmit}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <Row>
            <div className={`flex-grow-1 ${defaultClass}__search-box`}>
              <div className="bp3-input bp3-tag-input bp3-active">
                <div className="bp3-tag-input-values user-search">
                  <span className="bp3-icon bp3-icon-search"></span>
                  <input
                    className="bp3-input-ghost"
                    type="search"
                    onChange={(e) => {
                      track("accountPortal.users.search.click");
                      handleChange(e);
                    }}
                    placeholder={t("components.userTable.search")}
                    name="search"
                    value={values.search}
                  />
                  {[...values.status, ...values.accountType]
                    .splice(0, 3)
                    .map((tag: any, index: number) => (
                      <span key={index} className="bp3-tag">
                        {t(`components.userTable.tags.${tag}`)}
                        <button
                          type="button"
                          key={index}
                          className="bp3-tag-remove"
                          onClick={() => {
                            if (values.status.includes(tag)) {
                              setValues({
                                ...values,
                                status: values.status.filter((o) => o != tag),
                              });
                            } else {
                              setValues({
                                ...values,
                                accountType: values.accountType.filter(
                                  (o) => o != tag
                                ),
                              });
                            }
                          }}
                        ></button>
                      </span>
                    ))}
                  {values.status.length + values.accountType.length > 3 && (
                    <span className="bp3-tag">
                      + {values.status.length + values.accountType.length - 3}{" "}
                      more
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Popover
                content={
                  <Card className={`${defaultClass}__filter`}>
                    <Row>
                      <Col md={12}>
                        <Col sm={12}>
                          <h5 className="filter-heading">{t("components.userTable.filter.title")}</h5>
                        </Col>
                        <div role="group" aria-labelledby="checkbox-group">
                          <hr></hr>
                          <Col sm={12}>
                            <label
                              className={`${defaultClass}__filter-label ${defaultClass}__filter-top`}
                            >
                              {t("components.userTable.filter.status")}
                            </label>
                          </Col>
                          <Col sm={12}>
                            <Checkbox
                              onChange={statusHandleChange}
                              name="status"
                              checked={isChecked<number>(
                                Status.Active,
                                values.status
                              )}
                              label={t("status.active")}
                              value={Status.Active}
                            />
                          </Col>
                          <Col sm={12}>
                            <Checkbox
                              onChange={statusHandleChange}
                              name="status"
                              checked={isChecked<number>(
                                Status.Inactive,
                                values.status
                              )}
                              label={t("status.inactive")}
                              value={Status.Inactive}
                            />
                          </Col>
                          <Col sm={12}>
                            <Checkbox
                              onChange={statusHandleChange}
                              name="status"
                              checked={isChecked<number>(
                                Status.Pending,
                                values.status
                              )}
                              label={t("status.pending")}
                              value={Status.Pending}
                            />
                          </Col>
                        </div>
                        <hr></hr>
                        <div role="group" aria-labelledby="checkbox-group">
                          <Col sm={12}>
                            <label className={`${defaultClass}__filter-label`}>
                              {t("components.userTable.filter.accountType")}
                            </label>
                          </Col>
                          <Col sm={12}>
                            <Checkbox
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                track(
                                  "accountPortal.users.searchFilters.click",
                                  {
                                    value1Type: "action",
                                    value1: `Clicked Filter Label ${tEn(
                                      `components.userTable.tags.${e.target.value}`
                                    )}`,
                                  }
                                );
                                handleChange(e);
                              }}
                              name="accountType"
                              label={t("roles.owner")}
                              checked={isChecked<string>(
                                Role.SuperAdmin,
                                values.accountType
                              )}
                              value={Role.SuperAdmin}
                            />
                          </Col>
                          <Col sm={12}>
                            <Checkbox
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                track(
                                  "accountPortal.users.searchFilters.click",
                                  {
                                    value1Type: "action",
                                    value1: `Clicked Filter Label ${e.target.value}`,
                                  }
                                );
                                handleChange(e);
                              }}
                              name="accountType"
                              label={t("roles.admin")}
                              checked={isChecked<string>(
                                Role.Admin,
                                values.accountType
                              )}
                              value={Role.Admin}
                            />
                          </Col>
                          <Col sm={12}>
                            <Checkbox
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                track(
                                  "accountPortal.users.searchFilters.click",
                                  {
                                    value1Type: "action",
                                    value1: `Clicked Filter Label ${e.target.value}`,
                                  }
                                );
                                handleChange(e);
                              }}
                              name="accountType"
                              label={t("roles.member")}
                              checked={isChecked<string>(
                                Role.Member,
                                values.accountType
                              )}
                              value={Role.Member}
                            />
                          </Col>
                        </div>
                        <hr></hr>
                        <Col sm={12}>
                          <label className={`${defaultClass}__filter-label`}>
                            {t("components.userTable.filter.lastActiveDate")}
                          </label>
                        </Col>
                        <Col sm={12}>
                          <label className={`${defaultClass}__filter-label filter-date`}>
                            {t("components.userTable.filter.start")}
                          </label>
                        </Col>
                        <Col sm={12}>
                          <input
                            type="date"
                            onChange={handleChange}
                            name="start"
                            //placeholder={t("components.userTable.filter.start")}
                            className={`bp3-input-ghost ${defaultClass}__date`}
                            value={values.start}
                          />
                        </Col>
                        <Col sm={12}>
                          <label className={`${defaultClass}__filter-label filter-date`}>
                            {t("components.userTable.filter.end")}
                          </label>
                        </Col>
                        <Col sm={12}>
                          <input
                            type="date"
                            onChange={handleChange}
                            name="end"
                            //placeholder={t("components.userTable.filter.end")}
                            className={`bp3-input-ghost ${defaultClass}__date`}
                            value={values.end}
                          />
                        </Col>
                        <Col sm={12} className={`${defaultClass}__filter-line`}>
                          <label
                            className={`${defaultClass}__filter-label`}
                          ></label>
                        </Col>
                        <Col
                          sm={12}
                          className={`${defaultClass}__filter-clear`}
                        >
                          <a
                            href="#"
                            onClick={() => {
                              setValues({
                                ...values,
                                status: [],
                                accountType: [],
                                start: "",
                                end: "",
                              });
                              track("accountPortal.users.searchFilters.click", {
                                value1Type: "action",
                                value1: "Clear Filters",
                              });
                            }}
                          >
                            {t("components.userTable.filter.clear")}
                          </a>
                        </Col>
                      </Col>
                    </Row>
                  </Card>
                }
                className="d-flex"
                position={Position.BOTTOM}
              >
                <Button
                  className={`${defaultClass}__filter-button filter-btn`}
                  icon={IconFilter}
                >
                </Button>
              </Popover>
            </div>
          </Row>
        </form>
      </Card>
      <div className="table-sub-title">
        {t("components.userTable.UserRolePrivileges")}
      </div>
      <div className="table-pg" {...getTableProps()}>
        {headerGroups.map((headerGroup) => (
          <Row {...headerGroup.getHeaderGroupProps()}>
            <Col
              xs={12}
              sm={5}
              md={5}
              className="d-flex flex-row"
              {...headerGroup.headers[0].getHeaderProps()}
              onClick={() => {
                handleLoadOrgUsers({
                  orderBy: tablePage.orderBy === "desc" ? "asc" : "desc",
                });
              }}
            >
              <h2 className={`${defaultClass}__member-column`}>
                {t("components.userTable.members")} ({data.length})
              </h2>
              <span className="d-flex flex-column">
                <Icon
                  color={
                    headerGroup.headers[0].isSorted
                      ? tablePage.orderBy === "desc"
                        ? "grey"
                        : ""
                      : "grey"
                  }
                  iconSize={12}
                  className={`${defaultClass}__sort-up`}
                  icon="caret-up"
                ></Icon>
                <Icon
                  color={
                    headerGroup.headers[0].isSorted
                      ? tablePage.orderBy === "desc"
                        ? ""
                        : "grey"
                      : "grey"
                  }
                  iconSize={12}
                  className={`${defaultClass}__sort-down`}
                  icon="caret-down"
                ></Icon>
              </span>
            </Col>
            <Col
              xs={6}
              sm={7}
              md={2}
              {...headerGroup.headers[1].getHeaderProps()}
            >
              <h2>{t("components.userTable.accountType")}</h2>
            </Col>
            <Col sm={6} md={2} {...headerGroup.headers[2].getHeaderProps()}>
              <h2>{t("components.userTable.lastActive")}</h2>
            </Col>
            <Col md={2} {...headerGroup.headers[3].getHeaderProps()}>
              <h2>{t("components.userTable.status")}</h2>
            </Col>
            <Col md={2} {...headerGroup.headers[4].getHeaderProps()}>
              <h2></h2>
            </Col>
          </Row>
        ))}
        {pageLoading && page.length === 0 && (
          <Card>
            <Spinner intent={"primary"} size={30} />
          </Card>
        )}
        {!pageLoading && page.length === 0 && (
          <Card className="d-flex justify-content-center">
            {t("components.userTable.noUsersFound")}
          </Card>
        )}
        {!pageLoading &&
          page.map((row) => {
            prepareRow(row);
            const member = row.original as IUser;
            return (
              <MemberCard
                key={member.email}
                profileImageUrl={member?.avatar?.url}
                name={`${member?.firstName || ""} ${member?.lastName || ""}`}
                email={member.email}
                role={member.role}
                lastActive={member?.lastActiveDate}
                onDeleteConfirm={handleMemberDelete}
                onCancelInvitation={handleCancelInvitation}
                onResendMemberInvitation={handleResendMemberInvitation}
                confirmed={!member.teamConfirmPending}
                inviteLastSent={member.lastInviteDate}
                inviteResendable={hasPermission(
                  user.role,
                  PermissionType.CanResendInvitation
                )}
                suspended={false}
                status={member.status}
                onUpdate={handleMemberUpdate}
                className="mb-1"
                userLimitReached={userLimitReached}
                openUpgradeModel={openUpgradeModel}
                availableStatuses={
                  member.teamConfirmPending ? [0, 1, 2] : [0, 1]
                }
                availableRoles={getAllowedRoleChanges(currentRole, member.role)}
                editable={
                  hasPermission(currentRole, PermissionType.CanEditUserRole) &&
                  getAllowedRoleChanges(currentRole, member.role).length > 1 &&
                  member.id !== user.id
                }
                deletable={
                  getDeleteMemberPermission(member.role) &&
                  member.id !== user.id &&
                  member.status === Status.Inactive
                }
              />
            );
          })}
        {!pageLoading && page.length > 0 && (
          <Card className={`${defaultClass}__pagination`}>
            {canPreviousPage && (
              <Button
                onClick={() => handleLoadOrgUsers({ pageIndex: 0 })}
                icon={IconNames.DOUBLE_CHEVRON_LEFT}
                minimal={true}
                className={`${defaultClass}__paginate-button`}
              />
            )}
            {canPreviousPage && (
              <Button
                onClick={() =>
                  handleLoadOrgUsers({ pageIndex: tablePage.pageIndex - 1 })
                }
                icon={IconNames.CHEVRON_LEFT}
                minimal={true}
                className={`${defaultClass}__paginate-button`}
              />
            )}
            {pages.slice(startIndex, endIndex).map((p) => (
              <button
                key={p}
                onClick={() => handleLoadOrgUsers({ pageIndex: p })}
                disabled={p === tablePage.pageIndex}
                className={`${defaultClass}${
                  p === tablePage.pageIndex
                    ? "__paginate-button--selected"
                    : "__paginate-button"
                }`}
              >
                {p + 1}
              </button>
            ))}
            {canNextPage && (
              <Button
                onClick={() =>
                  handleLoadOrgUsers({ pageIndex: tablePage.pageIndex + 1 })
                }
                icon={IconNames.CHEVRON_RIGHT}
                minimal={true}
                className={`${defaultClass}__paginate-button`}
              />
            )}
            {canNextPage && (
              <Button
                onClick={() => handleLoadOrgUsers({ pageIndex: pageCount - 1 })}
                icon={IconNames.DOUBLE_CHEVRON_RIGHT}
                minimal={true}
                className={`${defaultClass}__paginate-button`}
              />
            )}
          </Card>
        )}
      </div>
    </>
  );
};