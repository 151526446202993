import { useEffect, useState } from "react";

/**
 * This function takes a value and debounce for a given period of time.
 * @param value debouncing value
 * @param delay delay value in ms
 * @return  value type T
 */
function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export { useDebounce };
