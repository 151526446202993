import classNames from "classnames";
import React from "react";
import { IComponentProps } from "../../component-props.i";
import "./form-error.scss";

export const FormError: React.FC<IComponentProps> = (props) => {
  const defaultClass = "form-error";

  return (
    <div className={classNames(defaultClass, props?.className)}>
      {props.children}
    </div>
  );
};
