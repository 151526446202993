import {
  Classes,
  Dialog,
  IconName,
  Intent,
} from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { IComponentProps } from "../../component-props.i";
import "./upgrade-modal.scss";

export interface IModalProps extends IComponentProps {
  isOpen: boolean;
  icon?: IconName;
  button1Text?: string;
  button1Intent?: Intent;
  button1Action?: () => void;
  button2Text?: string;
  button2Intent?: Intent;
  button2Action?: () => void;
  button3Text?: string;
  button3Intent?: Intent;
  button3Action?: () => void;
  onClose?: () => void;
}

export const UpgradeModal: React.FC<IModalProps> = (props) => {
  const defaultClass = "upgrade-modal";

  return (
    <Dialog
      className={classNames(defaultClass, props?.className)}
      onClose={() => props.onClose?.call(null)}
      isOpen={props.isOpen}
    >
      <div className={`${defaultClass}__background`}>
        <div className={`bp3-dialog-header ${defaultClass}__header`}>
          <h4 className="bp3-heading"></h4>
          <button
            aria-label="close"
            onClick={() => props.onClose?.call(null)}
            className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
          ></button>
        </div>
        <div
          className={classNames(
            Classes.ALERT_BODY,
            Classes.DIALOG_BODY,
            "align-items-center",
            `${defaultClass}__body`
          )}
        >
          <img className={`${defaultClass}__img`} src="/resources/images/upgrade-modal/star.png" />
          {props.children}
        </div>
      </div>
    </Dialog>
  );
};
