import { useService } from "@creately/use-service";
import { getContext, resetContext } from "kea";
import { loadersPlugin } from "kea-loaders";
import { routerPlugin } from "kea-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./app";
import "./i18n";
import { bootstrapProviders } from "./providers";
import { ErrorHandler } from "./services/error-handler.s";
import { ERROR_HANDLER } from "./token";

bootstrapProviders();

resetContext({
  createStore: {},
  plugins: [
    loadersPlugin({
      onFailure({ error }: any) {
        const errorHandler: ErrorHandler = useService<ErrorHandler>(
          ERROR_HANDLER
        );
        const code = error.message;
        errorHandler.handleOnError(code);
      },
    }),
    routerPlugin(),
  ],
});

ReactDOM.render(
  <Provider store={getContext().store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
