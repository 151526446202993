/// <reference types="gravity-client" />
import { AbstractAuthentication } from "./abstract-auth.s";
import { injectable } from "@creately/use-service";

/**
 *  This enum defines all possibles states authentication can be in.
 */
export enum AuthenticationStatus {
  /**
   * An authentication exists in the system, is NOT expired, and meets
   * all validation criteria.
   */
  AUTHENTICATED,

  /**
   * An authentication token exists in the system but is expired.
   */
  EXPIRED,

  /**
   * An authentication token is not present in the system.
   */
  NOT_AUTHENTICATED,
}

@injectable()
export class Authentication extends AbstractAuthentication {
  /**
   * Initializes the authentication service.
   */
  public initialize() {
    // nothing to initialize
  }

  /******************************************************
   * Token related functions that make use of gravity API
   ******************************************************/

  /**
   * Returns the current authentication token
   * Uses the gravity client library to check token existence.
   * @return string representation of the gravity token
   */
  public get token(): string {
    return window.gravity.client.getCookie();
  }

  /**
   * Replaces the gravity cookie.
   * NOTE: the app probably needs to be reloaded after doing this.
   * TODO: check whether it is safe to allow setting the auth cookie.
   */
  public set token(token: string) {
    window.gravity.client.setCookie(token);
  }

  /**
   * Returns if the user is authenticated or not.
   * Uses the gravity client library to check for a valid auth token.
   * @return true if authentication exists and valid, false otherwise
   */
  public get isAuthenticated(): boolean {
    return window.gravity.client.hasValidSession();
  }

  // /**
  //  * Checks the authentication status by analyzing the gravity token.
  //  * Updates the Authentication and CurrentUser states based on token
  //  * status.
  //  * @return - current state of Authentication
  //  */
  // public check(): AuthenticationStatus {
  //     this.updateStates();
  //     return this.state.get( AuthenticationStatus );
  // }

  /**
   * Fetches the user id associated with the current gravity token.
   * @return  user id as string
   */
  public get currentUserId(): any {
    if (this.token) {
      return window.gravity.client.getCurrentUserID();
    }
  }
  /******************************************************
   * Showing and hiding widgets
   ******************************************************/

  /**
   * Shows the login widget.
   * Updates the authentication and current user states after the
   * authentication result.
   * Hides the widget upon successful authentication.
   * @return An observable that emits the authentication status.
   *         and throw an error when authentication has failed (or
   *         if login widget was closed without authenticating )
   */
  public async showLogin() {
    // if you are not authenticated, we dont
    // try fix it here, should be done outside
    // FIXME - open phoenix in new tab.
    return false;
    // window.gravity.widget.showSignIn();
    // try {
    //   await window.gravity.widget.show();
    //   this.hideLogin();
    //   return true;
    // } catch (error) {
    //   return false;
    // }
  }

  public async changeEmail(email: string) {
    return await window.gravity.api.changeEmail(email);
  }

  public async changePassword(currentPassword: string, password: string) {
    return await window.gravity.api.changePassword(currentPassword, password);
  }

  /**
   * Hides any gravity widgets that are showing.
   */
  protected hideLogin() {
    // window.gravity.widget.hide();
  }

  /**
   * Logs out the current user and clears the data in the browser database
   */
  public logOut() {
    window.gravity.client.logOut();
  }
}
