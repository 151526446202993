import { Button, Card, Tooltip, Position } from "@blueprintjs/core";
import { IconRefresh } from "../../components/icons/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { useActions } from "kea";
import { IComponentProps } from "../../component-props.i";
import { IModalData } from "./../../models/modal.i";
import { formatTime, formatDate } from "../../helpers/date.h";
import { Role, Status } from "../permissions/permissions";
import { ISelectItem, SelectInput } from "../select-input/select-input";
import { TooltipText } from "../tooltip-text/tooltip-text";
import "./member-card.scss";
import i18n from "../../i18n";
import { Modal } from "../modal/modal";
import { IconEdit, IconTrash } from "../icons/icons";
import { appLogic } from "../../logics/app.logic";
import { tEn } from "../../services/en-translate.s";

export interface IMemberCardProps extends IComponentProps {
  profileImageUrl?: string;
  name: string;
  email: string;
  role: Role;
  availableRoles: Role[];
  lastActive?: string;
  confirmed: boolean;
  inviteLastSent?: string;
  inviteResendable?: boolean;
  status: Status;
  availableStatuses: Status[];
  userLimitReached: () => boolean;
  openUpgradeModel: (openModel: boolean) => void;
  suspended?: boolean;
  onDeleteConfirm: (email: string, name: string) => void;
  onUpdate: (payload: {
    email: string;
    role: Role | undefined;
    status: Status | undefined;
  }) => void;
  onCancelInvitation: (email: string) => void;
  onResendMemberInvitation: (email: string) => void;
  editable?: boolean;
  deletable?: boolean;
}

export const MemberCard: React.FC<IMemberCardProps> = ({
  profileImageUrl = "",
  ...props
}: IMemberCardProps) => {
  const defaultClass = "member-card";
  const [
    isPendingCancelInvitationAlertOpen,
    setPendingCancelInvitationAlertOpen,
  ] = useState(false);

  const [
    isResendInviteButtonDisabled,
    setResendInviteButtonDisabled,
  ] = useState(false);

  const [isEditing, setEditing] = useState(false);

  const [isDisabled, setDisabled] = useState(false);

  const [selectedRole, setSelectedRole] = useState<Role>(props.role);

  const [selectedStatus, setSelectedStatus] = useState<Status>(props.status);

  const [modalData, setModalData] = useState<IModalData>({ modalOpen: false });

  const { t } = useTranslation();

  const { track, trackNotification } = useActions(appLogic);

  const RoleItems: ISelectItem[] = [
    {
      id: Role.SuperAdmin,
      label: i18n.t("roles.owner"),
    },
    {
      id: Role.Admin,
      label: i18n.t("roles.admin"),
    },
    {
      id: Role.Member,
      label: i18n.t("roles.member"),
    },
    // TODO: These role will be enable in team portal V2
    // {
    //   id: Role.Reviewer,
    //   label: "Reviewer",
    // },
    // {
    //   id: Role.Suspended,
    //   label: "Suspended",
    // },
  ];

  const StatusItems: ISelectItem[] = [
    {
      id: Status.Active,
      label: i18n.t("status.active"),
    },
    {
      id: Status.Inactive,
      label: i18n.t("status.inactive"),
    },
    {
      id: Status.Pending,
      label: i18n.t("status.pending"),
    },
  ];

  const [availableRoleItems] = useState<ISelectItem[]>(
    RoleItems.filter((roleItem) => props.availableRoles.includes(roleItem.id))
  );

  const [availableStatusItems] = useState<ISelectItem[]>(
    StatusItems.filter((item) => props.availableStatuses.includes(item.id))
  );

  const handlePendingDeleteButtonClick = () => {
    setPendingCancelInvitationAlertOpen(true);
  };

  const handleEditButtonClick = () => {
    setEditing((editing) => !editing);
  };

  function handlePendingCancelAlertConfirm() {
    setPendingCancelInvitationAlertOpen(false);
    props.onCancelInvitation.call(null, props.email);
  }

  const handlePendingCancelAlertCancel = () => {
    setPendingCancelInvitationAlertOpen(false);
  };

  const handleResendInviteButtonClick = () => {
    props.onResendMemberInvitation.call(null, props.email);

    // TODO: Disable the resend button once the feature is finalized
    // setResendInviteButtonDisabled(true);
  };

  const handleDeleteButtonClick = () => {
    track("accountPortal.users.remove.click");
    props.onDeleteConfirm(props.email, props.name);
  };

  const getSelectedRoleItem = () => {
    return availableRoleItems?.find((item) => item.id === selectedRole);
  };

  const getSelectedStatusItem = () => {
    return availableStatusItems?.find((item) => item.id === selectedStatus);
  };

  const handleModelCancel = () => {
    track("dialog.accountPortal.users.click", {
      value1Type: "action",
      value1: "Cancel",
    });
    setSelectedRole(props.role);
    setSelectedStatus(props.status);
    setModalData({ modalOpen: false });
  };

  const handleRoleChange = (role: ISelectItem | null): void => {
    track("accountPortal.users.roleChange.click", {
      value1Type: "role",
      value1: role?.label,
    });
    if (!!!role) {
      return;
    }
    setSelectedRole(role.id);
    if (role.id === Role.Suspended) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    let data: IModalData = {
      modalOpen: true,
      type: "role",
      title: t("components.memberCard.roleChangeModel.title"),
      successButtonText: t(
        "components.memberCard.roleChangeModel.successButtonText"
      ),
      cancelButtonText: t(
        "components.memberCard.roleChangeModel.cancelButtonText"
      ),
      content: t("components.memberCard.roleChangeModel.content", {
        name: props.name,
        role: role?.label,
      }),
    };
    setModalData(data);
    trackNotification("dialog.accountPortal.users.load", {
      value1: tEn("components.memberCard.roleChangeModel.title"),
      value2: tEn("components.memberCard.roleChangeModel.content"),
    });
  };

  const handleStatusChange = (status: ISelectItem): void => {
    track("accountPortal.users.userStatus.click", {
      value1Type: "status",
      value1: status?.label,
    });
    if (!!!status) {
      return;
    }
    setSelectedStatus(status.id);
    if (status.id === Status.Pending) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    let data: IModalData = {
      modalOpen: true,
      type: "status",
      successButtonText: t(
        "components.memberCard.statusChangeModel.successButtonText"
      ),
      cancelButtonText: t(
        "components.memberCard.statusChangeModel.cancelButtonText"
      ),
    };
    if (props.userLimitReached() && props.status === Status.Inactive) {
      data.modalOpen = false;
      props.openUpgradeModel(true);
      setSelectedStatus(props.status);
    } else if (status.id === Status.Inactive) {
      data = {
        ...data,
        title: t("components.memberCard.statusChangeModalSuspend.title"),
        content: t("components.memberCard.statusChangeModalSuspend.content", {
          name: props.name,
        }),
      };
      trackNotification("dialog.accountPortal.users.load", {
        value1: tEn("components.memberCard.statusChangeModalSuspend.title"),
        value2: tEn("components.memberCard.statusChangeModalSuspend.content", {
          name: props.name,
        }),
      });
    } else if (status.id === Status.Active) {
      data = {
        ...data,
        title: t("components.memberCard.statusChangeModalActivate.title"),
        content: t("components.memberCard.statusChangeModalActivate.content", {
          name: props.name,
        }),
      };
      trackNotification("dialog.accountPortal.users.load", {
        value1: tEn("components.memberCard.statusChangeModalActivate.title"),
        value2: tEn("components.memberCard.statusChangeModalActivate.content", {
          name: props.name,
        }),
      });
    } else {
    }
    setModalData(data);
  };

  const handleDone = () => {
    setEditing(false);
  };

  const handleMemberUpdate = () => {
    track("dialog.accountPortal.users.click", {
      value1Type: "action",
      value1: "Change",
    });
    const userDetails = {
      email: props.email,
      role: selectedRole,
      status: selectedStatus,
    };
    setModalData({ modalOpen: false });
    props.onUpdate(userDetails);
  };

  useEffect(() => {
    if (!!props?.inviteLastSent) {
      const currentDate = new Date();
      const inviteSentDate = new Date(props.inviteLastSent);
      const inviteAvailableDate = new Date(
        inviteSentDate.setDate(inviteSentDate.getDate() + 3)
      );
      if (currentDate <= inviteAvailableDate) {
        setResendInviteButtonDisabled(true);
        setDisabled(true);
      }
    }
  }, [props?.inviteLastSent]);

  useEffect(() => {
    setDisabled(selectedRole === Role.Suspended);
  }, [selectedRole]);

  useEffect(() => {
    setDisabled(!props.confirmed);
  }, [props.confirmed]);

  const deleteTooltipContent = (
    <TooltipText
      title={t("components.memberCard.deleteTooltip.title")}
      description={t("components.memberCard.deleteTooltip.description")}
    />
  );

  const pendingDeleteTooltipContent = (
    <TooltipText
      title={t("components.memberCard.pendingDeleteTooltip.title")}
      description={t("components.memberCard.pendingDeleteTooltip.description")}
    />
  );

  const resendInvitationTooltipContent = (
    <TooltipText
      title={t("components.memberCard.resendInvitationTooltip.title")}
      description={t(
        "components.memberCard.resendInvitationTooltip.description"
      )}
    />
  );

  const editTooltipContent = (
    <TooltipText title={t("components.memberCard.editUserTooltip")} />
  );

  return (
    <>
      <Card
        className={classNames(
          defaultClass,
          props?.className,
          isEditing ? `${defaultClass}--editing` : null,
          isDisabled ? `${defaultClass}--disabled` : null,
          `${defaultClass}__row member-details`
        )}
      >
        <Row className={`${defaultClass}__content-row`}>
          <Col xs={12} sm={5} md={5}>
            <div className={`${defaultClass}__profile-container`}>
              <div className="w-20">
                <img
                  className={`${defaultClass}__profile-image`}
                  src={
                    profileImageUrl ||
                    "/resources/images/default-profile-img.png"
                  }
                />
              </div>
              <div className="w-80">
                {props.name && (
                  <div className={`${defaultClass}__name`}>{props.name}</div>
                )}
                <div className={`${defaultClass}__email`}>{props.email}</div>
              </div>
            </div>
          </Col>
          <Col xs={6} sm={7} md={2}>
            <SelectInput
              className={`${defaultClass}__role-select`}
              items={availableRoleItems}
              onChange={handleRoleChange}
              disabled={!isEditing}
              value={getSelectedRoleItem()}
            />
          </Col>
          <Col sm={6} md={2}>
            <span className={`${defaultClass}__last-active`}>
              <div className={`${defaultClass}__time`}>
                {props?.lastActive ? formatTime(props.lastActive) : ""}
              </div>
              <div className={`${defaultClass}__date`}>
                {props?.lastActive ? formatDate(props.lastActive) : ""}
              </div>
            </span>
          </Col>
          <Col xs={6} sm={7} md={2}>
            <SelectInput
              className={`${defaultClass}__role-select ${defaultClass}__${
                !isEditing && getSelectedStatusItem()?.label.toLowerCase()
              }`}
              items={availableStatusItems}
              onChange={handleStatusChange}
              disabled={!isEditing}
              value={getSelectedStatusItem()}
            />
          </Col>
          <Col sm={6} md={1} className="d-flex justify-content-md-end">
            {isEditing && (
              <Button
                className={`${defaultClass}__done-button`}
                onClick={handleDone}
              >
                {t("done")}
              </Button>
            )}
            {!isEditing && props.confirmed && (
              <>
                {props.editable && (
                  <Tooltip
                    content={editTooltipContent}
                    position={Position.BOTTOM}
                  >
                    <Button
                      className={`${defaultClass}__edit-button`}
                      icon={IconEdit}
                      minimal={true}
                      onClick={handleEditButtonClick}
                    />
                  </Tooltip>
                )}
                {props.deletable && (
                  <Tooltip
                    content={deleteTooltipContent}
                    position={Position.BOTTOM}
                  >
                    <Button
                      className={`${defaultClass}__delete-button`}
                      icon={IconTrash}
                      minimal={true}
                      onClick={handleDeleteButtonClick}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {!props.confirmed && props.inviteResendable && (
              <>
                <Tooltip
                  content={resendInvitationTooltipContent}
                  position={Position.BOTTOM}
                >
                  <Button
                    className={`${defaultClass}__resend-invite-button`}
                    icon={IconRefresh}
                    minimal={true}
                    onClick={handleResendInviteButtonClick}
                    disabled={isResendInviteButtonDisabled}
                  />
                </Tooltip>
                <Tooltip
                  content={pendingDeleteTooltipContent}
                  position={Position.BOTTOM}
                >
                  <Button
                    className={`${defaultClass}__pending-delete-button`}
                    icon={IconTrash}
                    minimal={true}
                    onClick={handlePendingDeleteButtonClick}
                  />
                </Tooltip>
              </>
            )}
          </Col>
        </Row>
      </Card>
      <Modal
        isOpen={modalData.modalOpen}
        icon="error"
        button1Text={modalData.successButtonText}
        button1Action={handleMemberUpdate}
        button1Intent="primary"
        button2Text={modalData.cancelButtonText}
        button2Action={handleModelCancel}
        onClose={handleModelCancel}
      >
        <h5 className="bp3-heading">{modalData.title}</h5>
        <p>{modalData.content}</p>
      </Modal>
      <Modal
        isOpen={isPendingCancelInvitationAlertOpen}
        icon="error"
        button1Text={t(
          "components.memberCard.pendingCancelUserAlert.button1Text"
        )}
        button1Action={handlePendingCancelAlertConfirm}
        button1Intent="primary"
        button2Text={t(
          "components.memberCard.pendingCancelUserAlert.button2Text"
        )}
        button2Action={handlePendingCancelAlertCancel}
        onClose={handlePendingCancelAlertCancel}
      >
        <h5 className="bp3-heading">
          {t("components.memberCard.pendingCancelUserAlert.heading")}
        </h5>
        <p>
          {" "}
          {t("components.memberCard.pendingCancelUserAlert.description", {
            email: props.email,
          })}
        </p>
      </Modal>
    </>
  );
};
