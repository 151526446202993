import { Card, Icon } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import classNames from "classnames";
import React from "react";
import { IComponentProps } from "../../component-props.i";
import "./stat-card.scss";

export interface IStatCardProps extends IComponentProps {
  title: string;
  icon: IconName;
  iconColor?: string;
  active?: boolean;
  stat: string;
  description?: string;
}

export const StatCard: React.FC<IStatCardProps> = (props) => {
  const defaultClass = "stat-card";
  const iconColors = [
    "primary",
    "dark-blue",
    "grey",
    "navy-blue",
    "orange",
    "crimson",
  ];

  const getIconClass = (): string => {
    if (props?.iconColor && iconColors.includes(props.iconColor)) {
      return `${defaultClass}__icon--${props.iconColor}`;
    } else {
      return `${defaultClass}__icon--primary`;
    }
  };

  return (
    <Card className={classNames(defaultClass, `${defaultClass}__card`, props?.className)}>
      <div>
        <Icon icon={props.icon} iconSize={40} className={getIconClass()} />
        <p className={`${defaultClass}__title`}>{props.title}</p>
        {props.description && (
          <p className={`${defaultClass}__description`}>{props.description}</p>
        )}
      </div>
      <h2 className={`${defaultClass}__stat`}>{props.stat}</h2>
    </Card>
  );
};
