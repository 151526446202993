import { graphql } from "./graphql.s";
import { Authentication } from "./authentication.s";
import { injectable } from "@creately/use-service";
import stringifyObject from "stringify-object";
import axios, { AxiosRequestConfig } from "axios";
import { IPage } from "../models/page.i";

@injectable()
export class ApiService {
  constructor(private auth: Authentication) {}

  /**
   * This will add new members to the organization
   * @param emails member emails list.
   * @param planId organization id
   * @param role role of the new member
   */
  async inviteMembers(emails: string[], planId: string, role: string) {
    const userList = emails.map((email) => {
      return { email, role: role, planId };
    });
    const stringBuilder = stringifyObject(userList, {
      singleQuotes: false,
      transform: (_userList: any, prop: any, originalResult: any) => {
        if (prop === "role") {
          return originalResult.replace(/["']/g, "");
        } else {
          return originalResult;
        }
      },
    });
    const querystring = `mutation{
      organizationAddMember(orgMember:${stringBuilder}){
        status, errorCode
      }
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function will update the given user role.
   * @param email
   * @param planId organization id
   * @param role
   * @param status user status
   */
  async updateMember(
    email: string,
    planId: string,
    role: string,
    status: number
  ) {
    const querystring = `mutation{
      organizationUpdateMember(orgMember:{
        email:"${email}",
        role:${role},
        planId:"${planId}",
        status:${status}
       })
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function will delete the given user from the group.inactivate the user account.user wont be able to join again using that email.
   * @param email
   */
  async deleteMember(email: string, transferEmail: string) {
    const querystring = `mutation{
      organizationRemoveMember( email:"${email}",transferEmail :"${transferEmail}")
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function will update relevent organization setting value
   * @param settingId setting id
   * @param userAccess setting uset access level
   */
  async updateOrganizationSettings(settingId: string, userAccess: number) {
    const querystring = `mutation{
      organizationUpdateSetting( settingId:"${settingId}",userAccess :${userAccess})
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function will add subscription extend offer to given user.
   * @param email
   */
  async addExtendSubOffer() {
    const querystring = `mutation{
      userPromotionalExtendSubEndTermDate( extendDatesAmount:30)
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function will extend and apply personal plan offer.
   */
  async claimPersonalMonPlanOffer() {
    const querystring = `mutation{
      userPromotionalPersonalMonthly
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function will delete the given user's all data.
   * @param email
   */
  async closeUserAccount() {
    const querystring = `mutation{
      userCloseAccount
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * Resend an invite email to the given user.
   * @param email the user's email
   * @param planId the user's plan ID
   * @param role the user's role
   */
  async resendMemberInvitation(email: string, planId: string, role: string) {
    const querystring = `mutation{
      organizationUpdateMember(orgMember:{
        email:"${email}",
        role:${role},
        planId:"${planId}"
        resendInvitation: true,
       })
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function upload given base64 image to aws.this will update user avatar.
   * @param fileBase64 image in base64 format
   */
  async uploadAvatar(fileBase64: string) {
    const querystring = `mutation{
      userUpdateAvatar( fileBase64:"${fileBase64}"){filename,url}
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This function upload given base64 image to aws.this will update user avatar.
   * @param fileBase64 image in base64 format
   */
  async uploadOrganizationImage(fileBase64: string) {
    const querystring = `mutation{
      organizationUpdateImage( fileBase64:"${fileBase64}"){filename,url}
      }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This update organization information
   * @param org
   */
  async updateOrganization(org: any) {
    const stringBuilder = stringifyObject(org, {
      singleQuotes: false,
      transform: (_org: any, prop: any, originalResult: any) => {
        if (prop === "planStatus" || prop === "country") {
          return originalResult.replace(/["']/g, "");
        } else {
          return originalResult;
        }
      },
    });
    const querystring = `mutation{
      organizationUpdate(org:${stringBuilder})
    }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * This update user's basic information
   * @param user user object
   */
  async updateUser(user: any) {
    const stringBuilder = stringifyObject(user, {
      singleQuotes: false,
      transform: (_org: any, prop: any, originalResult: any) => {
        if (prop === "planStatus") {
          return originalResult.replace(/["']/g, "");
        } else {
          return originalResult;
        }
      },
    });

    const querystring = `mutation{
      userUpdate(user:${stringBuilder})
    }`;
    const header = { "creately-gravity-token": this.auth.token };
    return await graphql(querystring, header);
  }

  /**
   * This update user's basic information
   * @param user user object
   */
  async closeAccountAnswers(answers: any) {
    const stringBuilder = stringifyObject(answers, {
      singleQuotes: false,
      filter: (object: any, prop: any) =>
        object[prop] !== null &&
        object[prop] !== undefined &&
        object[prop] !== "",
    });
    const querystring = `mutation{
        userCloseAccountQuestionnaire(answers:${stringBuilder})
      }`;
    const header = { "creately-gravity-token": this.auth.token };
    return await graphql(querystring, header);
  }

  async getOrgUsersDetails(
    page: IPage = {
      pageIndex: 0,
      pageSize: 10,
      orderBy: "asc",
      filter: {
        search: "",
        status: [],
        accountType: [],
        start: "",
        end: "",
      },
    }
  ) {
    const querystring = `{
        organization{
          size,
          userCount,
          users (offset: ${page.pageIndex * page.pageSize},
            limit: ${page.pageSize},
            orderBy: "${page.orderBy}",
            filter : {
              search : "${page.filter.search}",
              status : ["${page.filter.status.join('","')}"],
              accountType : ["${page.filter.accountType.join('","')}"],
              start :  "${page.filter.start}",
              end :  "${page.filter.end}",
            }) {
            id,
            avatar{
              url
            },
            firstName,
            lastName,
            email,
            role,
            status,
            lastActiveDate,
            teamConfirmPending,
            lastInviteDate
          }
          pageCount
          inviteUrl
      }
      }`;
    const data = await graphql(querystring, this.getCommonHeaders());
    data.organization.page = page;
    return data;
  }

  /**
   * get organization users. 
   * This API call is used by user search component to add billing contacts.
   */
  async getEnterpriseUsers(search:string){
    const querystring = `{
      organization{
        users (
          offset:0,
          limit: 8,
          orderBy: "asc",
          filter : {
            search : "${search}",
            status : ["${[].join("\",\"")}"],
            accountType : ["${[].join("\",\"")}"],
            start :  "${""}",
            end :  "${""}",
          }) {
          id,
          avatar{
            url
          },
          firstName,
          lastName,
          email,
          role,
          status,
          lastActiveDate,
          teamConfirmPending,
          lastInviteDate
        }
        pageCount
    }
    }`;
  return await graphql(querystring, this.getCommonHeaders());;
  }
  /**
   * this api will return all user related data
   */
  async getOrganizationDetails() {
    const querystring = `{
        organization{
          id,
          name,
          size,
          domain,
          planName,
          profileImage{
            url
          }
        },
      }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  /**
   * this api will return all user related data
   */
  async getUserDetails() {
    const querystring = `{
      user {
        id,
        firstName,
        lastName,
        email,
        jobTitle,
        industry,
        intendedUsage,
        role,
        lastActiveDate,
        planType,
        planName,
        languagePreference,
        mfaEnabled,
        organization{
          id,
          name,
          size,
          domain,
          planName,
          invoices{id,status,description,price,currency,date,saveURL,},
          billingAddress{
            street,
            country,
            city,
            zipCode,
            line1,
            line2,
            phone,
            company,
            firstName,
            lastName,
          },
          totalFolders,
          totalDocuments,
          billingContacts{email,label,role,avatar},
          remainingSeats,
          usedAccount,
          paymentDetails{
            type,
            status,
            maskedCardNumber,
            cardType
          },
          billingOwner{id,firstName,lastName,email,avatar}
          subscription{
            id,
            status,
            planId,
            currentTermStartDate,
            currentTermEndDate,
            previousTermEndDate,
            pauseDate,
            paused,
            pauseScheduled,
            cancelDate,
            cancelScheduled,
            cancelled,
            extendSubOfferStart,
            extendSubOfferExpired,
            extendSubOfferClaim,
            paymentFailedCount,
            createdDate,
            planUnitPrice,
            billingPeriodUnit,
            preferredCurrency
          },
          settings{
            id,
            settingId,
            setting,
            teamSettingType,
            userAccess
          }
        },
        avatar{
          url
        },
        totalFolders,
        totalDocuments
      }
      }`;
    return await graphql(querystring, this.getCommonHeaders());
  }

  private getCommonHeaders() {
    return { "creately-gravity-token": this.auth.token };
  }

  async getUsageList() {
    const options: AxiosRequestConfig = {
      method: "GET",
      url: "resources/usage.json",
    };
    try {
      const { data } = await axios(options);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getLanguagesList() {
    const options: AxiosRequestConfig = {
      method: "GET",
      url: "resources/languages.json",
    };
    try {
      const { data } = await axios(options);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getIndustriesList() {
    const options: AxiosRequestConfig = {
      method: "GET",
      url: "resources/industries.json",
    };
    try {
      const { data } = await axios(options);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getRolesList() {
    const options: AxiosRequestConfig = {
      method: "GET",
      url: "resources/roles.json",
    };
    try {
      const { data } = await axios(options);
      return data;
    } catch (error) {
      return error;
    }
  }

  async createPortalSession(data?: any) {
    const options: AxiosRequestConfig = {
      method: "POST",
      url: `${process.env.API_URL}/integration/chargebee/command`,
      data: { ...data, command: "createPortalSession" },
    };
    try {
      const { data } = await axios(options);
      return data;
    } catch (error) {
      return error;
    }
  }

  async collectAllUnpaidPayments(data?: any) {
    const options: AxiosRequestConfig = {
      method: "POST",
      url: `${process.env.API_URL}/integration/chargebee/command`,
      data: { ...data, command: "collectAllUnpaidPayments" },
    };
    try {
      const { data } = await axios(options);
      return data;
    } catch (error) {
      return error;
    }
  }
}
