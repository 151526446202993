import React from "react";
import classNames from "classnames";
import { IComponentProps } from "../../../component-props.i";
import "./filtered-user-tile.scss";
import { Icon } from "@blueprintjs/core";

export interface IUserSearchInputProps extends IComponentProps {
  id: string;
  value: string;
  thumbnail?: string;
  onRemove: (id: string) => void;
}

/**
 * This component will show the the filtered users for a typed user name
 * and will be added to the filtered users section when confirmation of adding.
 * @author Nuwan
 * @since 2021-04-08
 */
export const FilteredUser: React.FC<IUserSearchInputProps> = (props) => {
  const defaultClass = "filtered-user-tile";
  return (
    <div className={classNames(`${defaultClass}__card-container`)}>
      <div className={classNames(`${defaultClass}__tile-box`)}>
        <div>
          {!props.thumbnail && (
            <Icon
              iconSize={30}
              className={classNames(`${defaultClass}__thumbnail`)}
              icon="user"
              color="grey"
            />
          )}
          {props.thumbnail && (
            <img
              className={classNames(`${defaultClass}__thumbnail`)}
              src={props.thumbnail}
            />
          )}
        </div>
        <div className={classNames(`${defaultClass}__text-box`)}>
          <div className={classNames(`${defaultClass}__text-title`)}>
            {props.value}
          </div>
          <div>{props.id}</div>
        </div>
        <div className={classNames(`${defaultClass}__action-icon-section`)}>
          <a
            className={classNames(`${defaultClass}__close-icon`)}
            onClick={() => props.onRemove(props.id)}
          >
            x
          </a>
        </div>
      </div>
    </div>
  );
};
