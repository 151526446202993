import { kea } from "kea";
import { useService } from "@creately/use-service";
import { API_SERVICE, AUTHENTICATION, POST_MESSAGE_SERVICE } from "../token";
import { ApiService } from "../services/api.s";
import { Authentication } from "../services/authentication.s";
import { userLogic } from "./user.logic";
import {
  buildTranslatedSuccessNotification,
  buildTranslatedWarningNotification,
} from "../helpers/notification.h";
import { notificationLogic } from "./notification.logic";
import { routeLogic } from "./route.logic";
import { SubscriptionStatusChange } from "../pages/billing-page/billing-page";
import {
  PostMessageSendEventType,
  PostMessageService,
} from "../services/post-message.s";
import { appLogic } from "./app.logic";

const Chargebee: any = (window as any).Chargebee;

export const billingLogic = kea({
  connect: {
    values: [userLogic, ["user"]],
    actions: [
      userLogic,
      ["loadUsers"],
      notificationLogic,
      ["showNotification"],
      routeLogic,
      ["setMainSection"],
      appLogic,
      ["track", "closeIfEmbedded"],
    ],
  },

  actions: () => ({
    init: true,
    createPortalSession: true,
    retryAllUnpaidPayments: false,
    openBillingHistory: true,
    openPaymentSources: ( getRespons: ( data: any ) => void ) => ( { getRespons } ),
    openEditSubscription: true,
    logout: true,
    changePlanStatus: (planStatus: string) => ({ planStatus }),
    closeCBAccount: (answers: any) => ({ ...answers }),
    pauseCBAccount: (answers: any) => ({ ...answers }),
    closeCBTrialAccount: false,
    resumeCBAccount: false,
    reactivateCBAccount: false,
    removePauseCBAccount: false,
    removeCloseCBAccount: false,
    removeCloseCBAccountAndPaused: false,
    changedPlanSubscription: (isPlanChanged: boolean) => ({ isPlanChanged }),
  }),

  reducers: () => ({
    planChanged: [
      false,
      {
        changedPlanSubscription: (
          _: any,
          { isPlanChanged }: { isPlanChanged: boolean }
        ) => isPlanChanged,
      },
    ],
  }),

  loaders: () => ({
    changePlanStatus: [
      null,
      {
        changePlanStatus: async (planStatus: any) => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser(planStatus);
          return res;
        },
      },
    ],

    closeCBAccount: [
      null,
      {
        closeCBAccount: async (answers: any) => {
          const api = useService<ApiService>(API_SERVICE);
          await api.closeAccountAnswers(answers);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.Cancel,
          });
          return res;
        },
      },
    ],

    closeCBTrialAccount: [
      null,
      {
        closeCBTrialAccount: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.Cancel,
          });
          return res;
        },
      }
    ],

    pauseCBAccount: [
      null,
      {
        pauseCBAccount: async (answers: any) => {
          const api = useService<ApiService>(API_SERVICE);
          await api.closeAccountAnswers(answers);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.Pause,
          });
          return res;
        },
      },
    ],

    resumeCBAccount: [
      null,
      {
        resumeCBAccount: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.Resume,
          });
          return res;
        },
      },
    ],

    reactivateCBAccount: [
      null,
      {
        reactivateCBAccount: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.Reactivate,
          });
          return res;
        },
      },
    ],

    removePauseCBAccount: [
      null,
      {
        removePauseCBAccount: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.RemoveScheduledPause,
          });
          return res;
        },
      },
    ],

    removeCloseCBAccount: [
      null,
      {
        removeCloseCBAccount: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.RemoveScheduledCancellation,
          });
          return res;
        },
      },
    ],

    removeCloseCBAccountAndPaused: [
      null,
      {
        removeCloseCBAccountAndPaused: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateUser({
            planStatus: SubscriptionStatusChange.RemoveScheduledCancellationAndPaused,
          });
          return res;
        },
      },
    ],

    retryAllUnpaidPayments: [
      null,
      {
        retryAllUnpaidPayments: async () => {
          const api = useService<ApiService>(API_SERVICE);
          const auth = useService<Authentication>(AUTHENTICATION);
          const data = {
            authToken: auth.token,
          };
          const res = await api.collectAllUnpaidPayments(data);
          return res?.transaction;
        },
      },
    ],

    chargebeeInstance: [
      null,
      {
        init: () => {
          return Chargebee.init({
            site: process.env.CHARGEBEE_SITE_NAME,
          });
        },
      },
    ],
  }),

  listeners: ({ values, actions }: any) => ({
    createPortalSession: async () => {
      const api = useService<ApiService>(API_SERVICE);
      const auth = useService<Authentication>(AUTHENTICATION);
      const data = {
        authToken: auth.token,
      };
      await values.chargebeeInstance.setPortalSession(async () => {
        return await api.createPortalSession(data);
      });
    },
    openBillingHistory: async () => {
      await actions.createPortalSession();
      let portal = values.chargebeeInstance.createChargebeePortal();
      portal.openSection({
        sectionType: Chargebee.getPortalSections().BILLING_HISTORY,
      });
    },
    openPaymentSources: async ( payload: any ) => {
      await actions.createPortalSession();
      let portal = values.chargebeeInstance.createChargebeePortal();
      let paymentSourceUpdated = false;
      await portal.openSection(
        {
          sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
        },
        {
          paymentSourceUpdate: function (data: any) {
            if (data === "success") {
              paymentSourceUpdated = true;
            }
          },
          close: async function () {
            if(paymentSourceUpdated) {
              const api = useService<ApiService>(API_SERVICE);
              const auth = useService<Authentication>(AUTHENTICATION);
              const data = {
                authToken: auth.token,
              };
              const res = await api.collectAllUnpaidPayments(data);
              payload.getRespons(res?.transaction);
            }
          },
        }
      );
    },
    openEditSubscription: async () => {
      await actions.createPortalSession();
      let portal = values.chargebeeInstance.createChargebeePortal();
      portal.openSection(
        {
          sectionType: Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
          params: {
            subscriptionId: values.user.organization.subscription.id,
          },
        },
        {
          subscriptionChanged: function (data: any) {
            if (data.subscription.id) {
              // if the changed subcription id is not null show the notification
              actions.changedPlanSubscription(true);
            }
          },
        }
      );
    },
    logout: () => {
      values.chargebeeInstance.logout();
    },
    changePlanStatusSuccess: ({ changePlanStatus }: any) => {
      if (!changePlanStatus.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.planStatusChanged.heading",
            "notifications.planStatusChanged.description"
          )
        );
        actions.setMainSection(true);
        actions.loadUsers();
      }
    },
    closeCBAccountSuccess: ({ closeCBAccount }: any) => {
      if (!closeCBAccount.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.accountCloseStart.heading",
            "notifications.accountCloseStart.description"
          )
        );
        actions.setMainSection(true);
        actions.loadUsers();
      }
    },
    closeCBTrialAccountSuccess: ({ closeCBTrialAccount }: any) => {
      if (!closeCBTrialAccount.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.accountCloseTrialStart.heading",
            "notifications.accountCloseTrialStart.description"
          )
        );
        actions.setMainSection(true);
        const auth = useService<Authentication>(AUTHENTICATION);
        auth.logOut();
        actions.closeIfEmbedded();
      }
    },
    pauseCBAccountSuccess: ({ pauseCBAccount }: any) => {
      if (!pauseCBAccount.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.scheduledAccountPause.heading",
            "notifications.scheduledAccountPause.description"
          )
        );
        actions.setMainSection(true);
        const postMessageService = useService<PostMessageService>(
          POST_MESSAGE_SERVICE
        );
        postMessageService.sendToParent(PostMessageSendEventType.Reload, {});
        actions.loadUsers();
      }
    },
    removeCloseCBAccountAndPausedSuccess: ({ removeCloseCBAccountAndPaused }: any) => {
      if (!removeCloseCBAccountAndPaused.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.scheduledAccountPause.heading",
            "notifications.scheduledAccountPause.description"
          )
        );
        actions.setMainSection(true);
        const postMessageService = useService<PostMessageService>(
          POST_MESSAGE_SERVICE
        );
        postMessageService.sendToParent(PostMessageSendEventType.Reload, {});
        actions.loadUsers();
      }
    },
    retryAllUnpaidPaymentsSuccess: ({ retryAllUnpaidPayments }: any) => {
      if (retryAllUnpaidPayments.status === "success") {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.paymentRetry.heading",
            "notifications.paymentRetry.description"
          )
        );
        actions.setMainSection(true);
        actions.loadUsers();
      } else {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      }
    },
    reactivateCBAccountSuccess: ({ reactivateCBAccount }: any) => {
      if (!reactivateCBAccount.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.accountResume.heading",
            "notifications.accountResume.description"
          )
        );
        actions.setMainSection(true);
        const postMessageService = useService<PostMessageService>(
          POST_MESSAGE_SERVICE
        );
        postMessageService.sendToParent(PostMessageSendEventType.Reload, {});
        actions.loadUsers();
      }
    },
    resumeCBAccountSuccess: ({ resumeCBAccount }: any) => {
      if (!resumeCBAccount.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.accountResume.heading",
            "notifications.accountResume.description"
          )
        );
        actions.setMainSection(true);
        const postMessageService = useService<PostMessageService>(
          POST_MESSAGE_SERVICE
        );
        postMessageService.sendToParent(PostMessageSendEventType.Reload, {});
        actions.loadUsers();
      }
    },
    removePauseCBAccountSuccess: ({ removePauseCBAccount }: any) => {
      if (!removePauseCBAccount.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.reactivateScheduledAccountPause.heading",
            "notifications.reactivateScheduledAccountPause.description"
          )
        );
        actions.setMainSection(true);
        actions.loadUsers();
      }
    },
    removeCloseCBAccountSuccess: ({ removeCloseCBAccount }: any) => {
      if (!removeCloseCBAccount.userUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.removeScheduledAccountClose.heading",
            "notifications.removeScheduledAccountClose.description"
          )
        );
        actions.setMainSection(true);
        actions.loadUsers();
      }
    },
  }),

  events: ({ actions }: any) => ({
    afterMount: [actions.init],
    beforeUnmount: [actions.logout],
  }),
});
