import { Button, Icon, IconName, Intent } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { IComponentProps } from "../../component-props.i";
import "./pop-up.scss";

/**
 * Pop up component can be used from a parent component to
 * 1. make confirmationas(success and cancel buttons).
 * 2. show some tips(single button).
 *
 * variables
 * title: title of the pop-up.
 * desciption: description under the title.
 * closeAction: a function to toggle the state variable(boolean) which used to show and hidethe popup.
 * success(optional): Button to success action.
 * button1name : optional button title.
 * button1Action : action of the optional button.
 */
export interface IPopUpProps extends IComponentProps {
  title: string;
  desctiption: string;
  icon: IconName | JSX.Element;
  iconIntent: Intent;
  closeAction: () => void;
  closeIconAction: () => void;
  closeButtonName: string;
  button1Name?: string;
  closeButtonIntent: Intent;
  button1Intent?: Intent;
  showCloseIcon?: boolean;
  button1Action: () => void;
}
/**
 * ---Pop up component---
 * title: main title of the pop-up.
 * description: description under the title.
 * closeAction: function to toggle the isOpen state(boolean). A state<boolean>
 * should be maintained to show/hide popup in parent component
 * Button 1 is optional and can be used to perform any action.
 * @author Nuwan
 * @since 2021-08-04
 */
export const PopUp: React.FC<IPopUpProps> = (props) => {
  const defaultClass = "pop-up";

  return (
    <div className={classNames(`${defaultClass}__popup-box`)}>
      <div className={classNames(`${defaultClass}__box`)}>
        <div className={classNames(`${defaultClass}__section-1`)}>
          <Row>
            <Col sm={12} md={12}>
              {props.showCloseIcon && (
                
                    <a
                      onClick={() => props.closeIconAction()}
                      className={classNames(`${defaultClass}__close-icon`)}
                    >
                      <Icon icon="cross" />
                    </a>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={2} md={2}>
              <Icon
                icon={props.icon}
                intent={props.iconIntent}
                iconSize={62}
                className={classNames(`${defaultClass}__top-icon`)}
              />
            </Col>
            <Col sm={10} md={10}>
              <div className={classNames(`${defaultClass}__head1`)}>
                {props.title}
              </div>
              <p className={classNames(`${defaultClass}__description`)}>
                {props.desctiption}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={2} md={2} />
            <Col sm={10} md={10}>
              {props.children}
            </Col>
          </Row>
        </div>
        <div className={classNames(`${defaultClass}__section-2`)}>
          <Row>
            <Col sm={6} md={6}></Col>
            <Col sm={4} md={4}>
              {props.button1Name && (
                <Button
                  className={classNames(`${defaultClass}__optional-btn`)}
                  onClick={() => props.button1Action()}
                  intent={ props.button1Intent }
                >
                  {props.button1Name}
                </Button>
              )}
            </Col>
            <Col sm={2} md={2}>
              <Button
                fill={true}
                className={classNames(`${defaultClass}__close-btn`)}
                onClick={() => props.closeAction()}
                intent= { props.closeButtonIntent }
              >
                {props.closeButtonName}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
