import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useActions, useValues } from "kea";
import React from "react";
import { appLogic, AppMode } from "../../logics/app.logic";
import "./page-container.scss";

/**
 * Page container.
 * Applies elements above pages depending on the app mode.
 * @param props Page container props
 */
export const PageContainer: React.FC<{}> = (props) => {
  const defaultClass = "page-container";

  const { appMode } = useValues(appLogic);

  const { closeIfEmbedded } = useActions(appLogic);

  return (
    <div className={defaultClass}>
      {appMode === AppMode.Embedded && (
        <Button
          icon={IconNames.CROSS}
          minimal={true}
          className={`bp3-btn ${defaultClass}__iframe-close-button`}
          onClick={closeIfEmbedded}
        />
      )}

      {props.children}
    </div>
  );
};
