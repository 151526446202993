import { kea } from "kea";
import { useService } from "@creately/use-service";
import { API_SERVICE } from "../token";
import { ApiService } from "../services/api.s";

interface IAction {
  actions: {
    loadLists: any;
  };
}
export const listLogic = kea({
  actions: () => ({
    loadLists: true,
  }),

  listeners: ({ actions }: any) => ({
    loadLists: () => {
      actions.loadUsageList();
      actions.loadIndustriesList();
      actions.loadLanguagesList();
      actions.loadRolesList();
    },
  }),

  loaders: () => ({
    industries: [
      [],
      {
        loadIndustriesList: async () => {
          const api = useService<ApiService>(API_SERVICE);
          return await api.getIndustriesList();
        },
      },
    ],
    languages: [
      [],
      {
        loadLanguagesList: async () => {
          const api = useService<ApiService>(API_SERVICE);
          return await api.getLanguagesList();
        },
      },
    ],
    usage: [
      [],
      {
        loadUsageList: async () => {
          const api = useService<ApiService>(API_SERVICE);
          return await api.getUsageList();
        },
      },
    ],
    roles: [
      [],
      {
        loadRolesList: async () => {
          const api = useService<ApiService>(API_SERVICE);
          return await api.getRolesList();
        },
      },
    ],
  }),
  events: ({ actions }: IAction) => ({
    afterMount: [actions.loadLists],
  }),
});
