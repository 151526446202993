import { Button, Card, Intent, Position, Tooltip } from "@blueprintjs/core";
import { useActions, useValues } from "kea";
import React, { useEffect, useState, MouseEvent } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";
import { PageTitle } from "../../components/page-title/page-title";
import { MultiEmailInput } from "../../components/multi-email-input/multi-email-input";
import { TooltipText } from "../../components/tooltip-text/tooltip-text";
import { PageSection } from "../../components/page-section/page-section";
import {
  hasPermission,
  Permission,
  PermissionType,
  Role,
  Status,
} from "../../components/permissions/permissions";
import { organizationLogic } from "../../logics/organization.logic";
import { notificationLogic } from "../../logics/notification.logic";
import { userLogic } from "../../logics/user.logic";
import { IUser } from "../../models/user.i";
import { Table } from "../../components/table/table";
import "./users-page.scss";
import { IPage } from "../../models/page.i";
import { IOrganization } from "../../models/organization.i";
import { UpgradeModal } from "../../components/upgrade-modal/upgrade-modal";
import { IconCopy } from "../../components/icons/icons";
import {
  ISelectSearchItem,
  SelectSearch,
} from "../../components/select-search/select-search";
import { Modal } from "../../components/modal/modal";
import {
  INotification,
  NotificationType,
} from "../../services/notification.s";
import { appLogic } from "../../logics/app.logic";
import { tEn } from "../../services/en-translate.s";

export const UsersPage: React.FC<{}> = () => {
  const defaultClass = "users-page";

  const { track, trackNotification } = useActions(appLogic);
  const {
    orgUserList,
    orgUserListLoading,
    user,
    userLoading,
  }: {
    orgUserList: IOrganization;
    orgUserListLoading: boolean;
    user: IUser;
    userLoading: boolean;
  } = useValues(userLogic);

  const {
    inviteMembers,
    updateMember,
    deleteMember,
    resendMemberInvitation,
    cancelInvitation,
    loadOrgUsers,
  } = useActions(organizationLogic);

  const [emails, setEmails] = useState<string[]>([]);
  const { t } = useTranslation();

  const [currentRole, setCurrentRole] = useState(user.role);
  const [remainingSeats ] = useState(user?.organization?.remainingSeats);
  const [users, setUsers] = useState(orgUserList?.users);
  const [workspaceUser, setWorkspaceUser] = useState<ISelectSearchItem>();
  const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const initialPage: IPage = {
    pageIndex: 0,
    pageSize: 10,
    orderBy: "asc",
    filter: {
      search: "",
      status: [],
      accountType: [],
      start: "",
      end: "",
    },
  };
  const [page, setPage] = useState(initialPage);
  const [upgradeModalOpen, setUpgradeModal] = useState(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState({
    email: "",
    name: "",
  });

  const handleMemberConfirmDelete = (email: string, name: string) => {
    trackNotification("dialog.accountPortal.users.load", {
      value1: tEn("components.memberCard.deleteMemberAlert.heading"),
      value2: tEn("components.memberCard.deleteMemberAlert.description"),
    });
    loadOrgUsers({
      ...page,
      ...{
        pageIndex: 0,
        pageSize: 4,
        orderBy: "asc",
        filter: {
          search: "",
          status: [1],
          accountType: [],
          start: "",
          end: "",
        },
      },
    });
    setDeleteAlertOpen(true);
    setSelectedDeleteUser({ email, name });
  };

  const hanldeUsersSearch = (search: string) => {
    track("dialog.accountPortal.users.transfer.click");
    let newPage = {
      ...page,
      ...{
        pageIndex: 0,
        pageSize: 4,
        orderBy: "asc",
        filter: {
          search: "",
          status: [1],
          accountType: [],
          start: "",
          end: "",
        },
      },
    };
    newPage.filter.search = search;
    loadOrgUsers(newPage);
  };

  function handleDeleteAlertConfirm() {
    track("dialog.accountPortal.users.click", {
      value1Type: "action",
      value1: "Remove",
    });
    loadOrgUsers({
      ...initialPage,
    });
    setDeleteAlertOpen(false);
    handleMemberDelete(selectedDeleteUser.email, workspaceUser?.id);
  }

  const handleDeleteAlertCancel = () => {
    track("dialog.accountPortal.users.click", {
      value1Type: "action",
      value1: "Cancel",
    });
    setDeleteAlertOpen(false);
    loadOrgUsers({
      ...initialPage,
    });
  };

  // TODO: Enable this when invite link functionality is complete
  const { showNotification } = useActions(notificationLogic);

  const inviteTooltipContent = (
    <TooltipText
      title={t("pages.organization.inviteTooltipTitle")}
      description={t("pages.organization.inviteTooltipDescription")}
    />
  );

  const copyInviteLinkToClipboard = (
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ): void => {
    track("accountPortal.users.invite.click",{
      value1Type: "action",
      value1: "Copy Invite Button",
    });
    event.preventDefault();
    navigator.clipboard
      .writeText(orgUserList?.inviteUrl)
      .then(() => {
        trackNotification("notification.accountPortal.organisation.success", {
          value1: tEn("notifications.inviteLinkCopied.heading"),
          value2: tEn("notifications.inviteLinkCopied.description"),
        });
        const notification: INotification = {
          id: "invite-link-copied-notification",
          heading: t("notifications.inviteLinkCopied.heading"),
          description: t("notifications.inviteLinkCopied.description"),
          type: NotificationType.Neutral,
          autoDismiss: true,
        };
        showNotification(notification);
      })
      .catch(() => {
        trackNotification("notification.accountPortal.organisation.fail", {
          value1: tEn("notifications.inviteLinkCopied.heading"),
          value2: tEn("notifications.inviteLinkCopied.description"),
        });
      });
  };

  const handleMemberDelete = (email: string, transferEmail: string) => {
    deleteMember({ user: email, transferEmail });
  };

  const handleCancelInvitation = (email: string) => {
    cancelInvitation({ email });
  };

  const handleLoadOrgUsers = (newPage: IPage) => {
    loadOrgUsers({
      ...page,
      ...newPage,
    });
  };

  const handleResendMemberInvitation = (email: string) => {
    const payload = {
      email: email,
      planId: user.organization.id,
      role: Role.Member,
    };
    resendMemberInvitation(payload);
  };

  const handleMemberUpdate = (data: {
    email: string;
    role: Role | undefined;
    status: Status | undefined;
  }) => {
    const payload = {
      email: data.email,
      planId: user.organization.id,
      role: data.role,
      status: data.status,
    };
    updateMember(payload);
  };

  const handleEmailOnChange = (updatedEmails: string[]) => {
    setEmails(updatedEmails);
  };

  const sendInviteEmails = () => {
    track("accountPortal.users.invite.click",{
      value1Type: "action",
      value1: "Send Invite",
    });
    const emailList = [...emails];
    const payload = {
      emails: emailList,
      planId: user.organization.id,
      role: Role.Member,
    };
    inviteMembers(payload);
    setEmails([]);
  };

  // TODO: Handle plan change
  const getDeleteMemberPermission = (memberRole: Role) => {
    if (memberRole === Role.SuperAdmin) {
      return hasPermission(currentRole, PermissionType.CanRemoveSuperAdmin);
    } else if (memberRole === Role.Admin) {
      return hasPermission(currentRole, PermissionType.CanRemoveAdmin);
    } else if (memberRole === Role.Reviewer) {
      return hasPermission(currentRole, PermissionType.CanRemoveReviewer);
    } else {
      return hasPermission(currentRole, PermissionType.CanRemoveMember);
    }
  };

  /**
   * Get the available role types that the given role can change, based on permissions
   * granted to the given role
   * @param role the role to check permissions for
   */
  const getAvailableRoleChanges = (role: Role) => {
    let permissions: Role[] = [];
    //To Do to fix multiple owner issue available scenario is not handled 
    // if (hasPermission(role, PermissionType.CanUpgradeAdmin)) {
    //   permissions.push(Role.SuperAdmin);
    // }
    if (hasPermission(role, PermissionType.CanUpgradeMember)) {
      permissions.push(Role.Admin);
    }
    if (hasPermission(role, PermissionType.CanUpgradeReviewer)) {
      permissions.push(Role.Member);
    }
    if (hasPermission(role, PermissionType.CanUpgradeSuspended)) {
      permissions.push(Role.Reviewer);
    }
    if (hasPermission(role, PermissionType.CanDowngradeSuperAdmin)) {
      permissions.push(Role.Admin);
    }
    if (hasPermission(role, PermissionType.CanDowngradeAdmin)) {
      permissions.push(Role.Member);
    }
    if (hasPermission(role, PermissionType.CanDowngradeMember)) {
      permissions.push(Role.Reviewer);
    }
    if (hasPermission(role, PermissionType.CanDowngradeReviewer)) {
      permissions.push(Role.Suspended);
    }
    return permissions.filter(
      (value, index, self) => self.indexOf(value) === index
    );
  };

  /**
   * Get the allowed role changes based on the role type of the current user (the user who is
   * attempting to make the changes) and the role type of the user that needs to be changed.
   * @param role the current user role
   * @param userRole the role of the user whose permissions are being changed
   */
  const getAllowedRoleChanges = (role: Role, userRole: Role) => {
    const permissions = getAvailableRoleChanges(role);
    if (permissions.includes(userRole)) {
      return permissions;
    }
    return [userRole];
  };

  const validateEmail = (email: string) => {
    return email !== user.email;
  };

  const userLimitReached = () => {
    return remainingSeats === 0;
  };

  useEffect(() => {
    loadOrgUsers(page);
  }, []);

  useEffect(() => {
    setCurrentRole(user.role);
  }, [user]);

  useEffect(() => {
    setPage(orgUserList?.page);
    setUsers(orgUserList?.users);
  }, [orgUserList]);

  return userLoading ? (
    LoadingIndicator
  ) : (
    <div className={defaultClass}>
      <Grid fluid>
        <PageSection>
          <PageTitle>{t("users")}</PageTitle>
        </PageSection>
        <Permission permission={PermissionType.CanInviteUsers}>
          <PageSection>
            <Card className="d-flex flex-column user-pg">
              <Row>
                <Col xs>
                  <h1 className={`${defaultClass}__sub-title`}>
                    {t("invitePeople")}
                  </h1>
                </Col>
              </Row>
              <div className={`d-flex ${defaultClass}__invite-box`}>
                <div className={`${defaultClass}__invite-label-box`}>
                  <label className={`${defaultClass}__invite-label`}>
                    Invite by Email
                  </label>
                </div>
              </div>
                <div className="flex-grow-1">
                  <MultiEmailInput
                    value={emails}
                    onChange={handleEmailOnChange}
                    validate={validateEmail}
                  />
                </div>
                <div className="d-flex">
                  <Button
                    intent={Intent.PRIMARY}
                    className={`${defaultClass}__invite-button`}
                    onClick={sendInviteEmails}
                    disabled={!!!emails?.length}
                  >
                    {t("invite")}
                  </Button>
                  <Button className={`${defaultClass}__invite-link ${defaultClass}__invite-button bp3-button bp3-intent-secondary`}>
                  {IconCopy}
                  <Tooltip
                    content={inviteTooltipContent}
                    position={Position.BOTTOM}
                  >
                    <h2>
                      <a href="#" onClick={copyInviteLinkToClipboard}>
                        {t("pages.organization.inviteByLink")}
                      </a>
                    </h2>
                  </Tooltip>
                  </Button>
                </div>
            </Card>
          </PageSection>
        </Permission>

        <Permission permission={PermissionType.CanViewMembers}>
          <PageSection>
            {users && (
              <Table
                user={user}
                currentRole={currentRole}
                handleCancelInvitation={handleCancelInvitation}
                handleMemberDelete={handleMemberConfirmDelete}
                handleMemberUpdate={handleMemberUpdate}
                getAllowedRoleChanges={getAllowedRoleChanges}
                handleResendMemberInvitation={handleResendMemberInvitation}
                getDeleteMemberPermission={getDeleteMemberPermission}
                tablePage={page}
                pageLoading={orgUserListLoading}
                pageCount={orgUserList.pageCount}
                userLimitReached={userLimitReached}
                handleLoadOrgUsers={handleLoadOrgUsers}
                openUpgradeModel={setUpgradeModal}
                data={users}
              ></Table>
            )}
          </PageSection>
        </Permission>
      </Grid>
      <UpgradeModal
        isOpen={upgradeModalOpen}
        button1Text="Upgrade"
        button1Action={() => {}}
        button1Intent="primary"
        button2Text="Cancel"
        button2Action={() => {
          setUpgradeModal(false);
        }}
        onClose={() => {
          setUpgradeModal(false);
        }}
      >
        <h5 className={`${defaultClass}__header`}>
          {t("pages.users.upgradePlanModal.heading")}
        </h5>
        <p>{t("pages.users.upgradePlanModal.description")}</p>
      </UpgradeModal>
      <Modal
        isOpen={isDeleteAlertOpen}
        icon="error"
        button1Text="Remove"
        button1Action={handleDeleteAlertConfirm}
        button1Intent="primary"
        button2Text="Cancel"
        button2Action={handleDeleteAlertCancel}
        onClose={handleDeleteAlertCancel}
      >
        <h5 className="bp3-heading">
          {t("components.memberCard.deleteMemberAlert.heading")}
        </h5>
        <p>
          {t("components.memberCard.deleteMemberAlert.description", {
            name: selectedDeleteUser?.name,
          })}
        </p>
        <p>
          {t("components.memberCard.deleteMemberAlert.workspaceLabel", {
            name: selectedDeleteUser?.name,
          })}
        </p>
        <SelectSearch
          placeholder="Choose a member"
          onChange={setWorkspaceUser}
          onSearch={hanldeUsersSearch}
          items={users
            ?.filter((u) => selectedDeleteUser.email !== u.email && u.status == Status.Active)
            .map((user: IUser) => ({
              id: user.email,
              label: `${user.firstName} ${user.lastName}`,
            }))}
        ></SelectSearch>
      </Modal>
    </div>
  );
};
