import { Card } from "@blueprintjs/core";
import classNames from "classnames";
import React, { createRef, FC, useEffect, useState } from "react";
import { IComponentProps } from "../../component-props.i";
import "./rating-card.scss";

export interface IRatingCardProps extends IComponentProps {
  name: string;
  value?: string;
  startValue: number;
  endValue: number;
  startText?: string;
  endText?: string;
  onChange: (value: string) => void;
}

export const RatingCard: FC<IRatingCardProps> = (props) => {
  const defaultClass = "rating-card";

  const [value, setValue] = useState(props?.value);
  const [radioContainerPadding, setRadioContainerPadding] = useState({
    paddingLeft: "0",
    paddingRight: "0",
  });
  const startTextRef = createRef<HTMLSpanElement>();
  const endTextRef = createRef<HTMLSpanElement>();

  const handleOnChange = (value: string) => {
    setValue(value);
    props.onChange.call(null, value);
  };

  const createRatingRadioButtons = () => {
    let radioButtons = [];

    for (let i = props.startValue; i <= props.endValue; i++) {
      radioButtons.push(
        <div
          className={`${defaultClass}__radio-button-container`}
          key={`radio-container__${i}`}
        >
          <input
            className={`${defaultClass}__radio`}
            type="radio"
            name={props.name}
            key={`radio__${i}`}
            id={`radio-card__${props.name}_${i}`}
            value={i.toString()}
            checked={value === i.toString()}
            onChange={() => handleOnChange(i.toString())}
          />
          <label
            className={`${defaultClass}__label`}
            htmlFor={`radio-card__${props.name}_${i}`}
          >
            <span className={`${defaultClass}__label__text`}>{i}</span>
          </label>
        </div>
      );
    }

    return radioButtons;
  };

  useEffect(() => {
    updateRadioContainerPadding();
  }, [props?.startText, props?.endText]);

  const updateRadioContainerPadding = () => {
    if (
      !!!props.startText ||
      !!!props.endText ||
      !!!startTextRef.current ||
      !!!endTextRef.current
    ) {
      return;
    }

    const paddingLeft =
      startTextRef.current.getBoundingClientRect().width / 2 - 22;
    const paddingRight =
      endTextRef.current.getBoundingClientRect().width / 2 - 22;

    setRadioContainerPadding({
      paddingLeft: `${paddingLeft}px`,
      paddingRight: `${paddingRight}px`,
    });
  };

  return (
    <Card className={classNames(defaultClass, props?.className)}>
      <div
        className={`${defaultClass}__radio-buttons-container`}
        style={radioContainerPadding}
      >
        {createRatingRadioButtons()}
      </div>
      {!!props.startText && !!props.endText && (
        <div className={`${defaultClass}__text-container`}>
          <span ref={startTextRef}>{props.startText}</span>
          <span ref={endTextRef}>{props.endText}</span>
        </div>
      )}
    </Card>
  );
};
