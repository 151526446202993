import { format, isToday } from "date-fns";

/**-
 * Convenience function to format UNIX date timestamps.
 * @param timestamp the timestamp for the date to format
 * @param showTime optionally show time
 */
export const formatUnixDate = (timestamp: string, showTime?: boolean) => {
  const timestampNumber = parseInt(timestamp, 10) * 1000;
  if (showTime) {
    return format(timestampNumber, "dd-MMM-yyyy H:mmaaaa");
  } else {
    return format(timestampNumber, "dd-MMM-yyyy");
  }
};

/**-
 * Convenience function to format UNIX date timestamps.
 * Used to format the next billing date in billing page.
 * @param timestamp the timestamp for the date to format
 * @param showTime optionally show time
 */
export const formatNextBillingDate = (timestamp: string) => {
  const timestampNumber = parseInt(timestamp, 10) * 1000;

  return format(timestampNumber, "MMM dd, yyyy");
};

/**
 * Convenience function to format date timestamps.
 * @param timestamp the timestamp for the date to format
 * @param showTime optionally show time
 */
export const formatDateTime = (timestamp: string, showTime?: boolean) => {
  const timestampNumber = parseInt(timestamp, 10);
  if (showTime) {
    return format(timestampNumber, "dd-MMM-yyyy H:mmaaaa");
  } else {
    return format(timestampNumber, "dd-MMM-yyyy");
  }
};

/**
 * Convenience function to format date timestamps date portion.
 * @param timestamp the timestamp for the date to format
 */
export const formatDate = (timestamp: string) => {
  const timestampNumber = parseInt(timestamp, 10);
  const date = new Date(timestampNumber);
  if (isToday(date)) {
    return "Today";
  } else {
    return format(timestampNumber, "PP");
  }
};

/**
 * Convenience function to format date timestamps and return time portion.
 * @param timestamp the timestamp for the date to format
 */
export const formatTime = (timestamp: string) => {
  const timestampNumber = parseInt(timestamp, 10);
  return format(timestampNumber, "p");
};
