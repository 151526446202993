import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import { IComponentProps } from "../../component-props.i";
import "./multi-email-input.scss";

export interface IMultiEmailInputProps extends IComponentProps {
  onChange: Function;
  value?: string[];
  validate?: (email: string) => boolean;
}

export const MultiEmailInput: React.FC<IMultiEmailInputProps> = (props) => {
  const defaultClass = "multi-email-input";

  const [emails, setEmails] = useState<string[]>([]);
  const [focused, setFocused] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    !!props?.value && setEmails(props.value);
  });

  const handleOnChange = (updatedEmails: string[]) => {
    setEmails(updatedEmails);
    props.onChange.call(null, updatedEmails);
  };

  const validate = (email: string) => {
    return (
      isEmail(email) &&
      (props?.validate ? props?.validate?.call(null, email) : true)
    );
  };

  return (
    <div
      className={classNames(
        defaultClass,
        props?.className,
        focused ? `${defaultClass}--focused` : null
      )}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <ReactMultiEmail
        className={`${defaultClass}__input-container`}
        placeholder={t("components.multiEmailInput.placeholder")}
        emails={emails}
        onChange={handleOnChange}
        validateEmail={validate}
        getLabel={(
          email: string,
          index: number,
          removeEmail: (index: number) => void
        ) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};
