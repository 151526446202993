import { kea } from "kea";
import { router } from "kea-router";
import { Tracker, ITrackingEventProperty } from "../services/tracker";
import { useService } from "@creately/use-service";
import {
  PostMessageService,
  PostMessageSendEventType,
} from "../services/post-message.s";
import { POST_MESSAGE_SERVICE } from "../token";

export enum AppMode {
  Normal = "normal",
  Embedded = "embedded",
}

export const appLogic = kea({
  actions: () => ({
    setMode: true,
    setLanguage: true,
    trackInit: true,
    trackLoad: true,
    trackExit: true,
    closeIfEmbedded: true,
    trackNotification: (
      eventName: string,
      eventProperties?: ITrackingEventProperty
    ) => ({
      eventName,
      eventProperties,
    }),
    track: (eventName: string, eventProperties?: ITrackingEventProperty) => ({
      eventName,
      eventProperties,
    }),
  }),

  loaders: () => ({
    appMode: [
      AppMode.Normal,
      {
        setMode: () => {
          if (
            window.self !== window.top ||
            router.values.searchParams?.mode === AppMode.Embedded
          ) {
            return AppMode.Embedded;
          }
          return AppMode.Normal;
        },
      },
    ],
    appLanguage: [
      "en",
      {
        setLanguage: () => {
          if (router.values.searchParams?.lng) {
            return router.values.searchParams?.lng;
          }
          return "en";
        },
      },
    ],
  }),

  listeners: ({ values }: any) => ({
    trackLoad: async () => {
      await Tracker.trackLoad();
    },
    track: ({ eventName, eventProperties }: any) => {
      Tracker.track(eventName, eventProperties);
      return [eventName, eventProperties];
    },
    trackNotification: ({ eventName, eventProperties }: any) => {
      Tracker.track(eventName, {
        value1Type: "title",
        value1: eventProperties.value1,
        value2Type: "description",
        value2: eventProperties.value2,
      });
      return [eventName, eventProperties];
    },
    trackExit: () => {
      Tracker.trackExit();
    },
    closeIfEmbedded: () => {
      if (values.appMode === AppMode.Embedded) {
        const postMessageService = useService<PostMessageService>(
          POST_MESSAGE_SERVICE
        );
        Tracker.trackExit();
        postMessageService.sendToParent(PostMessageSendEventType.Close, {});
      }
    },
  }),

  events: ({ actions }: any) => ({
    afterMount: [actions.setMode, actions.trackLoad],
    beforeUnmount: [actions.trackExit],
  }),
});
