import { EditableText } from "@blueprintjs/core";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { IComponentProps } from "../../component-props.i";
import "./editable-text-input.scss";
import { useTranslation } from "react-i18next";

export interface IEditableTextInputProps extends IComponentProps {
  name: string;
  value?: string;
  onConfirm: (value: string) => void;
  onChange?: (value: string) => void;
  disabled?: boolean;
  onInvalid?: (value: boolean) => void;
  maxLength?: number;
  validate?: (value: string) => boolean;
}

export const EditableTextInput: FC<IEditableTextInputProps> = (props) => {
  const defaultClass = "editable-text-input";

  const [initialValue, setInitialValue] = useState("");

  const [value, setValue] = useState(props?.value || "");
  const [editMode, setEditMode] = useState(false);

  const { t } = useTranslation();

  const validate = (checkValue: string) => {
    if (props?.validate) {
      return props.validate.call(null, checkValue);
    }
    return true;
  };

  const [isInvalid, setInvalid] = useState(false);

  const handleOnChange = (value: string) => {
    setValue(value);
    props?.onChange?.call(null, value);
    const valid = validate(value);
    setInvalid(!valid);
    props?.onInvalid?.call(null, !valid);
  };

  const handleOnConfirm = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue !== initialValue && validate(trimmedValue)) {
      setValue(trimmedValue);
      setInitialValue(trimmedValue);
      props.onConfirm(trimmedValue);
    } else {
      setValue(initialValue);
      props?.onChange?.call(null, initialValue);
    }
    setInvalid(false);
    props?.onInvalid?.call(null, false);
  };

  const handleOnCancel = () => {
    setValue(initialValue);
    props?.onChange?.call(null, initialValue);
    setInvalid(false);
    props?.onInvalid?.call(null, false);
  };

  useEffect(() => {
    setInitialValue(props?.value || "");
  }, []);

  return (
    <>
      <EditableText
        className={classNames(
          defaultClass,
          props?.className,
          isInvalid && `${defaultClass}--error`,
          props.disabled && `${defaultClass}--disabled`
        )}
        value={value}
        onCancel={handleOnCancel}
        onEdit={() => {
          setEditMode(true);
        }}
        isEditing={editMode}
        onChange={handleOnChange}
        onConfirm={handleOnConfirm}
        disabled={props.disabled}
        placeholder={props.disabled ? "" : t("clickToEdit")}
      />
    </>
  );
};
