import classNames from "classnames";
import React from "react";
import { IComponentProps } from "../../component-props.i";
import "./tooltip-text.scss";

export interface ITooltipTextProps extends IComponentProps {
  title?: string;
  description?: string;
}

export const TooltipText: React.FC<ITooltipTextProps> = (
  props: ITooltipTextProps
) => {
  const defaultClass = "tooltip-text";

  return (
    <div className={classNames(defaultClass, props?.className)}>
      {props.title && <p className={`${defaultClass}__title`}>{props.title}</p>}
      {props.description && (
        <p className={`${defaultClass}__description`}>{props.description}</p>
      )}
    </div>
  );
};
