import {
  Button,
  Callout,
  // Card,
  Classes,
  Intent,
  Radio,
  RadioGroup,
  TextArea,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
// import { Col, Grid, Row } from "react-flexbox-grid";
import { Grid } from "react-flexbox-grid";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";
import { PageSection } from "../../components/page-section/page-section";
import { PageTitle } from "../../components/page-title/page-title";
import { useValues, useActions } from "kea";
import { userLogic } from "../../logics/user.logic";
import {
  format,
  // formatDistanceToNowStrict,
  // differenceInDays,
  addMonths,
} from "date-fns";
import { IUser } from "../../models/user.i";
import { useTranslation } from "react-i18next";
import "./close-account-page.scss";
import { appLogic } from "../../logics/app.logic";
import { billingLogic } from "../../logics/billing.logic";
import {
  Permission,
  PermissionType,
  Plan,
} from "../../components/permissions/permissions";
import { routeLogic } from "../../logics/route.logic";
import { Crisp } from "crisp-sdk-web";
import Cookies from 'js-cookie';

enum AccountClosePageSection {
  Main = "main",
  OfferClaimed = "offerClaimed",
  OfferExpired = "offerExpired",
  CancelStepOne = "cancelStepOne",
  CancelStepTwo = "cancelStepTwo",
  PauseStepOne = "pauseStepOne",
  PauseStepTwo = "pauseStepTwo",
  CancelWarning = "cancelWarning",
  PausedAccount = "pausedAccount",
  DeleteAccount = "deleteAccount",
}

export const AccountClosePage: React.FC<{}> = () => {
  const defaultClass = "close-account-page";

  const { t } = useTranslation();

  const {
    closeCBAccount,
    pauseCBAccount,
    resumeCBAccount,
    reactivateCBAccount,
    removePauseCBAccount,
    removeCloseCBAccount,
    removeCloseCBAccountAndPaused,
  } = useActions(billingLogic);

  const { reset } = useActions(routeLogic);

  const refContainer = useRef(null);

  const {
    closeCBAccountLoading,
    closeCBTrialAccountLoading,
    pauseCBAccountLoading,
    resumeCBAccountLoading,
    reactivateCBAccountLoading,
    removePauseCBAccountLoading,
    removeCloseCBAccountLoading,
    removeCloseCBAccountAndPausedLoading,
  }: {
    closeCBAccountLoading: boolean;
    closeCBTrialAccountLoading: boolean;
    pauseCBAccountLoading: boolean;
    resumeCBAccountLoading: boolean;
    reactivateCBAccountLoading: boolean;
    removePauseCBAccountLoading: boolean;
    removeCloseCBAccountLoading: boolean;
    removeCloseCBAccountAndPausedLoading: boolean;
  } = useValues(billingLogic);

  const { closePageMainSection }: { closePageMainSection: boolean } = useValues(
    routeLogic
  );

  const {
    user,
    userLoading,
  }: {
    user: IUser;
    userLoading: boolean;
  } = useValues(userLogic);

  const { track } = useActions(appLogic);

  const {
    closeUserAccount,
    deleteUserAccount,
  } = useActions(userLogic);

  const nonRenewingPlans: string[] = [
    "cb-pro-desktop-sep20",
    "cb-desktop-team-jan20",
  ];

  const [currentSection, setCurrentSection] = useState("");

  const planLoading = () =>
    closeCBAccountLoading ||
    closeCBTrialAccountLoading ||
    pauseCBAccountLoading ||
    resumeCBAccountLoading ||
    reactivateCBAccountLoading ||
    removePauseCBAccountLoading ||
    removeCloseCBAccountLoading ||
    removeCloseCBAccountAndPausedLoading;

  const isProMonthly = () => {
    const planIdParts = (user?.organization?.subscription?.planId || "").split(
      "-"
    );
    return (
      !!planIdParts.find((p: string) => p === "pro") &&
      !!planIdParts.find((p: string) => p === "monthly")
    );
  };
  const isAnnualOfferPlan = () => {
    const planIdParts = (user?.organization?.subscription?.planId || "").split(
      "-"
    );
    return (
      !!planIdParts.find((p: string) => p === "pro") &&
      !!planIdParts.find((p: string) => p === "offer") &&
      (!!planIdParts.find((p: string) => p === "m11") ||
        !!planIdParts.find((p: string) => p === "m10"))
    );
  };

  const isPlanPersonalMonthlyUsers = () =>
    isProMonthly() || isAnnualOfferPlan();

  const handlePlanPauseClick = () => {
    const answers = {
      stepOneAnswer: cancelAnswers.questionOneMain,
      stepOneSubAnswer: cancelAnswers.questionOneSub,
      stepTwoAnswer: cancelAnswers.questionTwoMain,
      stepTwoSubAnswer: cancelAnswers.questionTwoSub,
    };
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.pause.click");
    } else {
      track("left.account.close.warning.scheduledPause.click");
    }
    pauseCBAccount(answers);
  };

  const handlePauseStepOneClick = () => {
    setCurrentSection(AccountClosePageSection.PauseStepOne);
  };

  const handleDeleteAccountClick = () => {
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.click");
    } else {
      track("left.account.close.delete.click");
    }
    deleteUserAccount();
  };

  const handlePauseStepTwoClick = () => {
    setCurrentSection(AccountClosePageSection.PauseStepTwo);
  };

  const handlePausedSectionClick = () => {
    setCurrentSection(AccountClosePageSection.PausedAccount);
  };

  const handleDeleteSectionClick = () => {
    setCurrentSection(AccountClosePageSection.DeleteAccount);
  };

  const handlePlanResumeClick = () => {
    if (currentSection === AccountClosePageSection.Main) {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "resume account",
        });
      } else {
        track("left.account.close.resume.click");
      }
    } else {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "resume account",
        });
      } else {
        track("left.account.close.warning.resume.click");
      }
    }
    resumeCBAccount();
  };

  const handlePlanReactivateClick = () => {
    reactivateCBAccount();
  };

  const handlePlanRemoveCancelClick = () => {
    if (currentSection === AccountClosePageSection.Main) {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.delete.cancelReq.click", {
          value1: "cancel scheduled deletion",
        });
      } else {
        track("left.account.close.removeScheduledClose.click");
      }
    } else {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.delete.cancelReq.click", {
          value1: "cancel scheduled deletion",
        });
      } else {
        track("left.account.close.warning.removeScheduledClose.click");
      }
    }
    removeCloseCBAccount();
  };

  const handlePlanRemovePauseClick = () => {
    if (currentSection === AccountClosePageSection.Main) {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "cancel scheduled pause",
        });
      } else {
        track("left.account.close.removeScheduledPause.click");
      }
    } else {
      if (isPlanPersonalMonthlyUsers()) {
        track("left.account.manageAccount.reactivate.click", {
          value1: "cancel scheduled pause",
        });
      } else {
        track("left.account.close.warning.removeScheduledPause.click");
      }
    }
    removePauseCBAccount();
  };

  const handleRemoveCanceledAndPausedClick = () => {
    removeCloseCBAccountAndPaused();
  }
  const [isPlanCancelLoading, setPlanCancelLoading] = useState(false);

  const cancelPlan = () => {
    const answers = {
      stepOneAnswer: cancelAnswers.questionOneMain,
      stepOneSubAnswer: cancelAnswers.questionOneSub,
      stepTwoAnswer: cancelAnswers.questionTwoMain,
      stepTwoSubAnswer: cancelAnswers.questionTwoSub,
    };
    if (user?.planType === Plan.Free || user?.planType === Plan.PublicTeam ) {
      track("left.account.close.close.click", {
        value1Type: "buttonName",
        value1: "noThanksCloseMyAccount",
      });
      closeUserAccount(answers);
    } else {
      track("left.account.close.dialog.close.click", {
        value1Type: "buttonName",
        value1: "cancel",
      });
      closeCBAccount(answers);
    }
  };

  const [cancelAnswers, setCancelAnswers] = useState({
    questionOneMain: "",
    questionOneSub: "",
    questionTwoMain: "",
    questionTwoSub: "",
  });

  const handleCancelStepOneClick = () => {
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.confirm.click", {
        value1: isAnnualOfferPlan() === true ? "with offer" : "without offer",
      });
    } else {
      track("left.account.close.warning.close.click", {
        value1Type: "buttonName",
        value1: "closeMyAccount",
      });
    }
    setCurrentSection(AccountClosePageSection.CancelStepOne);
  };

  const handleCancelStepTwoClick = () => {
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.q1.reason", {
        value1Type: "answer",
        value1: cancelAnswers.questionOneMain,
        value2Type: "reason",
        value2: cancelAnswers.questionOneSub,
      });
    } else {
      track("left.account.close.cancellation.q1.reason", {
        value1Type: "answer",
        value1: cancelAnswers.questionOneMain,
        value2Type: "reason",
        value2: cancelAnswers.questionOneSub,
      });
    }
    setCurrentSection(AccountClosePageSection.CancelStepTwo);
  };

  const handleFinalPlanCancelClick = () => {
    setPlanCancelLoading(true);
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.q2.altTool", {
        value1Type: "answer",
        value1: cancelAnswers.questionTwoMain,
        value2Type: "tool",
        value2: cancelAnswers.questionTwoSub,
      });
    } else {
      track("left.account.close.cancellation.q2.altTool", {
        value1Type: "answer",
        value1: cancelAnswers.questionTwoMain,
        value2Type: "tool",
        value2: cancelAnswers.questionTwoSub,
      });
    }
    cancelPlan();
  };

  const handleDoNotCancelClick = () => {
    setCancelAnswers({
      questionOneMain: "",
      questionOneSub: "",
      questionTwoMain: "",
      questionTwoSub: "",
    });
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.cancel.click", {
        value1Type: "location",
        value1:
          currentSection === AccountClosePageSection.CancelStepOne
            ? "q1"
            : currentSection === AccountClosePageSection.CancelStepTwo
            ? "q2"
            : "with-offer",
      });
    }
    setCurrentSection(AccountClosePageSection.Main);
  };

  const handleDoNotPauseClick = () => {
    setCancelAnswers({
      questionOneMain: "",
      questionOneSub: "",
      questionTwoMain: "",
      questionTwoSub: "",
    });
    setCurrentSection(AccountClosePageSection.Main);
  };

  const handleDialogPlanCancelClick = () => {
    if (isPlanPersonalMonthlyUsers()) {
      track("left.account.manageAccount.delete.click", {
        value1: isAnnualOfferPlan() === true ? "with offer" : "without offer",
      });
    } else {
      track("left.account.close.navigate.warning.click", {
        value1Type: "buttonName",
        value1: "noThanksCloseMyAccount",
      });
    }
    if ( user.organization.subscription.pauseScheduled ) {
      setCurrentSection(AccountClosePageSection.CancelStepOne);
    } else {
      setCurrentSection(AccountClosePageSection.CancelWarning);
    }
    if(user?.organization?.subscription?.planId !== "null") {
      showCrispChat();
    }
  };

  useEffect(() => {
    if (!document.getElementById('crisp-chatbox')) {
      Crisp.configure(`${process.env.CRISP_CHAT}`);
      Crisp.chat.hide();
      Crisp.setTokenId(btoa(user?.id));
    }
  }, []);

  const showCrispChat = () => {
    const smallScreen = window.innerHeight < 600;

    if (!Cookies.get('cancellationMsgSent')) {
      Crisp.message.show("text", "Hold On! Before You Go...");
      if (!smallScreen) {
        Crisp.message.show("text", "We're truly sorry to see you consider canceling your Creately subscription. We value your feedback and want to make sure you're getting the most out of Creately. Could you share a bit about why you're considering canceling?");
      } else {
        Crisp.chat.onChatOpened(() => {
          // Executed once the chat was opened
          Crisp.message.show("text", "We're truly sorry to see you consider canceling your Creately subscription. We value your feedback and want to make sure you're getting the most out of Creately. Could you share a bit about why you're considering canceling?");
          Crisp.chat.offChatOpened();
        })
      }
      Crisp.message.onMessageReceived(() => {
        // Executed once a message is received by the visitor
        Crisp.chat.show();

        if (!smallScreen) {
          Crisp.chat.open(); 
        }

        track("left.account.cancellationFlow.message.delivered");

        Crisp.message.offMessageReceived();
      })
  
      Cookies.set('cancellationMsgSent', 'true', { expires: 1 });
    } else {
      Crisp.chat.show();

      if (!smallScreen) {
        Crisp.chat.open(); 
      }
    }

    Crisp.message.onMessageSent(() => {
      // Executed once a message is submitted by the visitor
      Crisp.user.setEmail(user?.email);
      Crisp.user.setNickname(user?.firstName);
      Crisp.session.setSegments(["cancellation_flow"], false);
      Crisp.message.offMessageSent();
    })
  };

  const notCancelSheduledButtons = (
    <div className={`${defaultClass}__main-description-buttons`}>
      <Button className="bp3-button bp3-intent-secondary" onClick={handleDialogPlanCancelClick}>
        Cancel Subscription
      </Button>
      {!user.organization?.subscription?.pauseScheduled && (
        <Button intent={Intent.PRIMARY} onClick={handlePauseStepOneClick}>
        Pause Subscription
      </Button>
      )}
      {user.organization?.subscription?.pauseScheduled && (
        <Button intent={Intent.PRIMARY} onClick={handlePlanRemovePauseClick}>
        Don’t Pause My Account
      </Button>
      )}
    </div>
  )

  const cancelSheduledButtons = (
    <div className={`${defaultClass}__main-description-buttons`}>
      <Button className="bp3-button bp3-intent-secondary" loading={isPlanCancelLoading || planLoading()} onClick={handleRemoveCanceledAndPausedClick}>
        Pause for 3 Months Instead
      </Button>
      <Button intent={Intent.PRIMARY} onClick={handlePlanRemoveCancelClick}>
          Don’t Cancel My Account
      </Button>
    </div>
  )

  const buttonSection = (
    user.organization?.subscription?.cancelScheduled ? cancelSheduledButtons : notCancelSheduledButtons
  )

  const loadingSection = () => (
    <PageSection>
      <div>
        <div>
          {planLoading() && LoadingIndicator}
          {user?.organization?.subscription?.pauseScheduled && (
            <PageSection>
              <Callout intent={Intent.WARNING}>
                  Your subscription is scheduled to pause on &nbsp; {
                  format(
                    new Date(
                      parseInt(user.organization.subscription.pauseDate) *
                        1000
                    ),
                    "PPP"
                  )}
                <Permission permission={PermissionType.CanCancelPlan}>
                  <a
                    onClick={handlePlanRemovePauseClick}
                    className={`${defaultClass}__warning-card-btn`}
                  >
                    {t("pages.close.dontPauseMyAccount")}
                  </a>
                </Permission>
              </Callout>
            </PageSection>
          )}
          {user?.organization?.subscription?.cancelScheduled &&
            !nonRenewingPlans.includes(
              user?.organization?.subscription?.planId
            ) && (
              <PageSection>
                <Callout intent={Intent.WARNING}>
                  Your subscription is scheduled to cancelled on &nbsp;
                    {format(
                      new Date(
                        parseInt(user.organization.subscription.cancelDate) *
                          1000
                      ),
                      "PPP"
                    )}
                </Callout>
              </PageSection>
            )}
        </div>
        {user?.organization?.subscription?.cancelScheduled && (
          <div className="mt-2"></div>
        )}
      </div>
    </PageSection>
  )

  const detailCard = ( image: string, label: string, name: string, title: string ) => (
    <div className={`${defaultClass}__card-detail`}>
      <div className="card-icon-section">
        <div className="card-icon">
          <img
            src={`resources/images/close-account/${image}`}
            width="75"
          />
        </div>
        <div className="card-title">{title}</div>
      </div>
      <div className="sub-text">{name}</div>
      <label>{label}</label>
    </div>
  )

  const getTeamSize = () => user.organization?.size != -1 ? `up to ${user.organization?.size} Users` : 'Unlimited Users'; 

  const getTeamDocument = () => user.planType == Plan.PublicTeam ? `up to 3 Workspaces` : 'Unlimited Workspaces'; 

  const accountDetails = () => (
    <div className="account-details">
      {detailCard(
        'close-account-plan-tile-team-old.svg',
        'Unlimited shape libraries',
        user?.planName,
        'PLAN',
      )}
      {detailCard(
        'close-account-users-tile.svg',
        getTeamSize(),
        `${user?.organization?.id ? user?.organization?.usedAccount : ' '} Users`,
        'USERS',
      )}
      {detailCard(
        'close-account-document-tile.svg',
        getTeamDocument(),
        `${user?.organization?.id ? user?.organization?.totalDocuments : user?.totalDocuments} Workspaces`,
        'WORKSPACES'
      )}
    </div>
  )

  const mainHeaderSection = () => (
    <>
      {loadingSection()}
      {accountDetails()}
    </>
  );

  const exploreCreatelySection = () => (
    <div className={`${defaultClass}__explore-creately`}>
      <h2 className="explore-title">{t("pages.close.exploreCreately")}</h2>
      <p className="explore-text">
        {t("pages.close.mainDescriptionOne")} <br />
        {t("pages.close.mainDescriptionTwo")} <br />
        {t("pages.close.mainDescriptionFour")} <br />
        {t("pages.close.mainDescriptionFive")}
      </p>
      <Permission permission={PermissionType.CanCancelPlan}>
        <a className="link-with-icon" href="https://support.creately.com/en/" target="_blank">
          <img src={`resources/images/close-account/close-account-idea.svg`} />Know more about cancelation and pause...
        </a>
        {!user?.organization?.subscription.cancelled && buttonSection}
      </Permission>
    </div>
  )

  const pauseResumeSection = () => (
    <div className={`${defaultClass}__explore-creately`}>
      <div className="resume-header-section">
        <img src={`resources/images/close-account/paused-icon-md.svg`}/>
        <h2 className="resume-title">Your Account has been Paused !</h2>
        <div className="resume-text">
          Your Account will be automatically renewed on &nbsp;
          {format(
            addMonths(
              new Date(parseInt(user.organization.subscription.pauseDate) * 1000), 
              3 // Add 3 months
            ),
            "PPP"
          )}
        </div>
      </div>
      <a className="link-with-icon" onClick={handlePausedSectionClick}>
        <img src={`resources/images/close-account/close-account-idea.svg`} />
        What happens when you pause your account
      </a>
      <Permission permission={PermissionType.CanCancelPlan}>
        <Button intent={Intent.PRIMARY} onClick={handlePlanResumeClick}>
          Resume Account
        </Button>
      </Permission>
    </div>
  )

  const lockedAccountSection = () => (
    <div className={`${defaultClass}__explore-creately`}>
      <div className="resume-header-section">
        <img src={`resources/images/close-account/locked-icon-md.svg`}/>
        <h2 className="resume-title">Your Account Is Locked !</h2>
        <div className="resume-text">
          You cancelled your Creately subscription on {user.organization?.name ? user.organization.name : 'Your Organization'}. Your data is still securely stored on Creately, you can resume your subscription any time to unlock your account.
        </div>
      </div>
      <a className="link-with-icon" href="https://support.creately.com/en/" target="_blank">
        <img src={`resources/images/close-account/close-account-idea.svg`} />
        Know more about cancelation and pause... 
      </a>
      <div className="resume-button-section">
        <Button intent={Intent.NONE} onClick={handleDeleteSectionClick}>
          Delete My Account
        </Button>
        <Button intent={Intent.PRIMARY} onClick={handlePlanReactivateClick}>
          Unlock My Account
        </Button>
      </div>
    </div>
  )

  const accountManageSection = (
    <>
      {mainHeaderSection()}
      {( 
        user?.organization?.subscription.cancelled ? lockedAccountSection()
        : ( user?.organization?.subscription.paused ? pauseResumeSection()
        : exploreCreatelySection()
      ))}
    </>
  );

  useEffect(() => {
    if (!!closePageMainSection) {
      setCurrentSection(AccountClosePageSection.Main);
      reset();
    }
  }, [closePageMainSection]);

  const executeScrollTop = () => {
    if (refContainer != null && refContainer.current != null) {
      (refContainer.current as any).scrollIntoView(true);
    }
  };

  const getCurrentSection = () => {
    executeScrollTop();
    switch (currentSection) {
      case AccountClosePageSection.Main:
        return accountManageSection;
      case AccountClosePageSection.CancelStepOne:
        return cancelStepOneSection;
      case AccountClosePageSection.CancelStepTwo:
        return cancelStepTwoSection;
      case AccountClosePageSection.PauseStepOne:
          return pauseStepOneSection;
      case AccountClosePageSection.PauseStepTwo:
          return pauseStepTwoSection;
      case AccountClosePageSection.CancelWarning:
        return accountCancelWarningSections;
      case AccountClosePageSection.PausedAccount:
        return pausedAccountSection;
      case AccountClosePageSection.DeleteAccount:
        return deleteAccountSection;
      default:
        return accountManageSection;
    }
  };

  // Shuffle function to randomize array order
  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const [radioOptions, setRadioOptions] = useState<any[]>([]);

  useEffect(() => {
    const options = [
      {
        label: t("pages.billing.cancelSectionTwo.answerOne"),
        value: "Bugs/issues in the app",
      },
      {
        label: t("pages.billing.cancelSectionTwo.answerTwo"),
        value: "Project/work is finished",
      },
      {
        label: t("pages.billing.cancelSectionTwo.answerThree"),
        value: "Too expensive",
        showSpecialPlan: true,
      },
      {
        label: t("pages.billing.cancelSectionTwo.answerFour"),
        value: "Don't understand how to use the app",
      },
      {
        label: t("pages.billing.cancelSectionTwo.answerFive"),
        value: "Lacks features and functionality",
        isTextArea: true,
        placeholder: t("pages.billing.cancelSectionTwo.answerFiveDetailLabel"),
      },
    ];
    
    // Shuffle the options array
    setRadioOptions(shuffleArray(options));
  }, []);

  const cancelStepOneSection = (
    <>
      <h3>Step 1 of 2</h3>
      <PageSection>
        <p>{t("pages.billing.cancelSectionTwo.question")}</p>
        <RadioGroup
          onChange={(event) =>
            setCancelAnswers({
              ...cancelAnswers,
              questionOneMain: event?.currentTarget.value,
            })
          }
          selectedValue={cancelAnswers.questionOneMain}
        >
          {radioOptions.map((option) => (
            <Radio
              key={option.value}
              label={option.label}
              value={option.value}
              className={Classes.CARD}
            >
              {option.isTextArea && (
                <div className="bp3-radio-description">
                  <TextArea
                    rows={4}
                    fill={true}
                    placeholder={option.placeholder}
                    onChange={(event) =>
                      setCancelAnswers({
                        ...cancelAnswers,
                        questionOneSub: event?.currentTarget.value,
                      })
                    }
                  />
                </div>
              )}
            </Radio>
          ))}
        </RadioGroup>
      </PageSection>

      <PageSection>
        <div className="step-footer">
          <a href="#" onClick={handleDoNotCancelClick}>
            I don’t want to cancel my subscription
          </a>
          <Button
            disabled={
              (!!!cancelAnswers.questionOneMain.length &&
                cancelAnswers.questionOneMain !== "Other") ||
              (cancelAnswers.questionOneMain === "Other" &&
                !!!cancelAnswers.questionOneSub.trim().length)
            }
            className="ml-3"
            intent={Intent.PRIMARY}
            onClick={handleCancelStepTwoClick}
          >
            {t("next")}
          </Button>
        </div>
      </PageSection>
    </>
  );

  const cancelStepTwoSection = (
    <>
      <h3>Step 2 of 2</h3>
      <PageSection>
        <p>{t("pages.billing.cancelSectionThree.question")}</p>
        <RadioGroup
          onChange={(event) => {
            if (event?.currentTarget.value == "no") {
              setCancelAnswers({
                ...cancelAnswers,
                questionTwoMain: event?.currentTarget.value,
                questionTwoSub: "",
              });
            } else {
              setCancelAnswers({
                ...cancelAnswers,
                questionTwoSub: "",
                questionTwoMain: event?.currentTarget.value,
              });
            }
          }}
          selectedValue={cancelAnswers.questionTwoMain}
        >
          <Radio label={t("yes")} value="yes" className={Classes.CARD}>
            <div className="bp3-radio-description">
              <RadioGroup
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionTwoSub: event?.currentTarget.value,
                  })
                }
                selectedValue={cancelAnswers.questionTwoSub}
              >
                <Radio
                  label={t("pages.billing.cancelSectionThree.lucidChart")}
                  value="lucidChart"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.drawIo")}
                  value="drawIo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.miro")}
                  value="miro"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.googleDraw")}
                  value="google draw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.mural")}
                  value="mural"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.msVisio")}
                  value="ms visio"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.smartDraw")}
                  value="smartDraw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.cacoo")}
                  value="cacoo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.other")}
                  value="other"
                />
              </RadioGroup>
            </div>
          </Radio>
          <Radio label={t("no")} value="no" className={Classes.CARD} />
        </RadioGroup>
      </PageSection>
      <PageSection>
        <div className="step-footer">
          <a href="#" onClick={handleDoNotCancelClick}>
            I don’t want to cancel my subscription
          </a>
          <Button
            disabled={
              !(
                (cancelAnswers.questionTwoSub.length > 0 &&
                  cancelAnswers.questionTwoMain !== "no" &&
                  cancelAnswers.questionTwoSub.length > 0) ||
                cancelAnswers.questionTwoMain === "no"
              )
            }
            className="ml-3"
            intent={Intent.PRIMARY}
            loading={isPlanCancelLoading || planLoading()}
            onClick={handleFinalPlanCancelClick}
          >
            Cancel My Subscription
          </Button>
        </div>
      </PageSection>
    </>
  );

  const pausedAccountSection = (
    <div className="paused-account-section">
      <div className="header">
        <div className="icon"><img src={`resources/images/close-account/close-account-paused-bg-1.svg`}/></div>
        <div className="text">Your account has been placed on pause</div>
      </div>
      <div className="content">
        <div className="description-section">
          <div className="main">What happens when you pause your account,</div>
          <div className="points">
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/pause-point-1.svg`}/>
              </div>
              <div className="point-text">Your account will be paused for 3 months, and you will not be charged for the 3 months period.</div>
            </div>
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/pause-point-2.svg`}/>
              </div>
              <div className="point-text">We will notify you [days] before the auto renewal of your account.</div>
            </div>
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/pause-point-3.svg`}/>
              </div>
              <div className="point-text">Your account and workspaces will be safe and will be available to view. However, you won't be able to edit or comment on them.</div>
            </div>
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/pause-point-4.svg`}/>
              </div>
              <div className="point-text">When you want to work on your workspaces again, you can reactivate your subscription. You will only be charged from the reactivated date onwards.</div>
            </div>
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/pause-point-5.svg`}/>
              </div>
              <div className="point-text">You can lock in the current price. Even if we increase the subscription charges, you can still pay the same amount if you chose to reactivate the account.</div>
            </div>
          </div>
        </div>
        <div className="button-section">
        <Permission permission={PermissionType.CanCancelPlan}>
          <Button intent={Intent.PRIMARY} onClick={handlePlanResumeClick}>
            Resume Account
          </Button>
        </Permission>
        </div>
      </div>
    </div>
  )

  const deleteAccountSection = (
    <div className="paused-account-section">
      <div className="header">
        <div className="icon"><img src={`resources/images/close-account/delete-icon-bg.svg`}/></div>
        <div className="text">You are about to delete your Creately Account !</div>
      </div>
      <div className="content">
        <div className="description-section">
          <div className="main">What happens when you delete your account,</div>
          <div className="points">
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/delete-point-1.svg`}/>
              </div>
              <div className="point-text">Deleting your account means your Creately account will no longer exists.</div>
            </div>
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/delete-point-2.svg`}/>
              </div>
              <div className="point-text">All the workspaces created in this plan will be deleted from our Creately servers.</div>
            </div>
            <div className="point">
              <div className="point-icon">
                <img src={`resources/images/close-account/delete-point-3.svg`}/>
              </div>
              <div className="point-text">Deleted workspaces cannot be recovered even if you choose to reopen a Creately account in the future.</div>
            </div>
          </div>
        </div>
        <div className="delete-account-button-section">
          <div>
            <Button intent={Intent.NONE} onClick={() => setCurrentSection(AccountClosePageSection.Main) }>
              Keep my account
            </Button>
          </div>
          <div>
            <Button intent={Intent.PRIMARY} onClick={handleDeleteAccountClick}>
              Delete My Account
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  const pauseStepOneSection = (
    <>
      <div className="step-header">
        <div>{t("pages.billing.pauseSectionTwo.question")}</div>
        <div>Step 1 of 2</div>
      </div>
      <PageSection>
        <RadioGroup
          onChange={(event) =>
            setCancelAnswers({
              ...cancelAnswers,
              questionOneMain: event?.currentTarget.value,
            })
          }
          selectedValue={cancelAnswers.questionOneMain}
        >
          {radioOptions.map((option) => (
            <Radio
              key={option.value}
              label={option.label}
              value={option.value}
              className={Classes.CARD}
            >
              {option.isTextArea && (
                <div className="bp3-radio-description">
                  <TextArea
                    rows={4}
                    fill={true}
                    placeholder={option.placeholder}
                    onChange={(event) =>
                      setCancelAnswers({
                        ...cancelAnswers,
                        questionOneSub: event?.currentTarget.value,
                      })
                    }
                  />
                </div>
              )}
            </Radio>
          ))}
        </RadioGroup>
      </PageSection>

      <PageSection>
        <div className="step-footer">
          <a href="#" onClick={handleDoNotPauseClick}>
            {t("pages.billing.doNotPause")}
          </a>
          <Button
            disabled={
              (!!!cancelAnswers.questionOneMain.length &&
                cancelAnswers.questionOneMain !== "Other") ||
              (cancelAnswers.questionOneMain === "Other" &&
                !!!cancelAnswers.questionOneSub.trim().length)
            }
            className="ml-3"
            intent={Intent.PRIMARY}
            onClick={handlePauseStepTwoClick}
          >
            {t("next")}
          </Button>
        </div>
      </PageSection>
    </>
  );

  const pauseStepTwoSection = (
    <>
      <div className="step-header">
        <div>{t("pages.billing.cancelSectionThree.question")}</div>
        <div>Step 2 of 2</div>
      </div>
      <PageSection>
        <RadioGroup
          onChange={(event) => {
            if (event?.currentTarget.value == "no") {
              setCancelAnswers({
                ...cancelAnswers,
                questionTwoMain: event?.currentTarget.value,
                questionTwoSub: "",
              });
            } else {
              setCancelAnswers({
                ...cancelAnswers,
                questionTwoSub: "",
                questionTwoMain: event?.currentTarget.value,
              });
            }
          }}
          selectedValue={cancelAnswers.questionTwoMain}
        >
          <Radio label={t("yes")} value="yes" className={Classes.CARD}>
            <div className="bp3-radio-description">
              <RadioGroup
                onChange={(event) =>
                  setCancelAnswers({
                    ...cancelAnswers,
                    questionTwoSub: event?.currentTarget.value,
                  })
                }
                selectedValue={cancelAnswers.questionTwoSub}
              >
                <Radio
                  label={t("pages.billing.cancelSectionThree.lucidChart")}
                  value="lucidChart"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.drawIo")}
                  value="drawIo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.miro")}
                  value="miro"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.googleDraw")}
                  value="google draw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.mural")}
                  value="mural"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.msVisio")}
                  value="ms visio"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.smartDraw")}
                  value="smartDraw"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.cacoo")}
                  value="cacoo"
                />
                <Radio
                  label={t("pages.billing.cancelSectionThree.other")}
                  value="other"
                />
              </RadioGroup>
            </div>
          </Radio>
          <Radio label={t("no")} value="no" className={Classes.CARD} />
        </RadioGroup>
      </PageSection>
      <PageSection>
        <div className="step-footer">
          <a href="#" onClick={handleDoNotPauseClick}>
            {t("pages.billing.doNotPause")}
          </a>
          <Button
            disabled={
              !(
                (cancelAnswers.questionTwoSub.length > 0 &&
                  cancelAnswers.questionTwoMain !== "no" &&
                  cancelAnswers.questionTwoSub.length > 0) ||
                cancelAnswers.questionTwoMain === "no"
              )
            }
            className="ml-3"
            intent={Intent.PRIMARY}
            loading={isPlanCancelLoading || planLoading()}
            onClick={handlePlanPauseClick}
          >
            {t("pages.billing.pauseAccount")}
          </Button>
        </div>
      </PageSection>
    </>
  );

  const cancelWarningSection = (
    <div className="cancel-warning-section">
      <div className="header-section">
        <div className="header">
          You are About to Cancel Your Subscription !
        </div>
        <div className="sub-header">
          You can pause your account instead and keep all your workspaces ready for later
        </div>
      </div>
      <div className="content-section">
        <div className="content-card">
          <div className="card-header">
            <div className="icon">
              <img src={`resources/images/close-account/pause-icon-small.svg`}/>
            </div>
            <div className="text">Pause My Account</div>
          </div>
          <div className="card-points">
            <div className="points">
              <div className="point">
                <div className="point-icon">
                  <img src={`resources/images/close-account/pause-point-1.svg`}/>
                </div>
                <div className="point-text">Your account will be paused for 3 months, and you will not be charged for the 3 months period.</div>
              </div>
              <div className="point">
                <div className="point-icon">
                  <img src={`resources/images/close-account/pause-point-2.svg`}/>
                </div>
                <div className="point-text">We will notify you [days] before the auto renewal of your account.</div>
              </div>
              <div className="point">
                <div className="point-icon">
                  <img src={`resources/images/close-account/pause-point-3.svg`}/>
                </div>
                <div className="point-text">Your account and workspaces will be safe and will be available to view. However, you won't be able to edit or comment on them.</div>
              </div>
              <div className="point">
                <div className="point-icon">
                  <img src={`resources/images/close-account/pause-point-4.svg`}/>
                </div>
                <div className="point-text">When you want to work on your workspaces again, you can reactivate your subscription. You will only be charged from the reactivated date onwards.</div>
              </div>
              <div className="point">
                <div className="point-icon">
                  <img src={`resources/images/close-account/pause-point-5.svg`}/>
                </div>
                <div className="point-text">You can lock in the current price. Even if we increase the subscription charges, you can still pay the same amount if you chose to reactivate the account.</div>
              </div>
            </div>
            <div className="button-section">
              <Permission permission={PermissionType.CanCancelPlan}>
                <Button intent={Intent.PRIMARY} onClick={handlePauseStepOneClick}>
                  Pause for 3 Months Instead
                </Button>
              </Permission>
            </div>
          </div>
        </div>
        <div className="content-card">
          <div className="card-header">
            <div className="icon">
              <img src={`resources/images/close-account/cancele-icon-small.svg`}/>
            </div>
            <div className="text">Cancel Subscription</div>
          </div>
          <div className="card-points">
            <div className="points">
              <div className="point">
                  <div className="point-icon">
                    <img src={`resources/images/close-account/cancel-point-1.svg`}/>
                  </div>
                  <div className="point-text">Your account will be downgraded to a free user.</div>
              </div>
              <div className="point">
                  <div className="point-icon">
                    <img src={`resources/images/close-account/cancel-point-2.svg`}/>
                  </div>
                  <div className="point-text">Your existing workspaces will be locked.</div>
              </div>
              <div className="point">
                  <div className="point-icon">
                    <img src={`resources/images/close-account/cancel-point-3.svg`}/>
                  </div>
                  <div className="point-text">You can renew your subscription back to access and edit the content.</div>
              </div>
            </div>
            <div className="button-section">
              <Permission permission={PermissionType.CanCloseAccount}>
                <Button className="bp3-button bp3-intent-secondary" onClick={handleCancelStepOneClick}>
                  Cancel Subscription
                </Button>
              </Permission>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const accountCancelWarningSections = (
    <>
      {cancelWarningSection}
    </>
  );
  return userLoading ? (
    LoadingIndicator
  ) : (
    <div className={defaultClass} ref={refContainer}>
      <Grid fluid>
        <PageSection>
          <PageTitle>{t("closeAccount")}</PageTitle>
        </PageSection>
        <div className="page-content">
          {getCurrentSection()}
        </div>
      </Grid>
    </div>
  );
};
