import { useService } from "@creately/use-service";
import { kea } from "kea";
import { Authentication } from "../services/authentication.s";
import { ErrorHandler } from "../services/error-handler.s";
import { AUTHENTICATION, ERROR_HANDLER } from "../token";
import { userLogic } from "./user.logic";
import { notificationLogic } from "./notification.logic";
import { buildTranslatedSuccessNotification } from "../helpers/notification.h";

export const authenticationLogic = kea({
  connect: {
    values: [userLogic, ["user"]],
    actions: [
      userLogic,
      ["loadUsers", "updateEmail"],
      notificationLogic,
      ["showNotification"],
    ],
  },

  actions: () => ({
    checkAuth: true,
    setAuth: (isAuth: boolean) => ({ isAuth }),
    setAuthLoading: (loading: boolean) => ({ loading }),
    changeEmail: (email: string) => ({ email }),
    changePassword: (passwords: any) => ({ passwords }),
    initAuth: true,
    showLogin: true,
  }),

  reducers: () => ({
    isAuthenticated: [
      false,
      {
        setAuth: (_: any, { isAuth }: { isAuth: boolean }) => isAuth,
      },
    ],
    authLoading: [
      false,
      {
        setAuthLoading: (_: any, { loading }: { loading: boolean }) => loading,
      },
    ],
  }),

  listeners: ({ actions }: any) => ({
    initAuth: () => {
      const auth: Authentication = useService<Authentication>(AUTHENTICATION);
      auth.initialize();
    },
    checkAuth: async () => {
      actions.initAuth();
      const auth: Authentication = useService<Authentication>(AUTHENTICATION);
      actions.setAuth(auth.isAuthenticated);
      if (!auth.isAuthenticated) {
        const isLoggedIn = await auth.showLogin();
        if (isLoggedIn) {
          actions.setAuth(auth.isAuthenticated);
          actions.loadUsers();
        }
      }
    },
    changeEmail: async ({ email }: any) => {
      const auth: Authentication = useService<Authentication>(AUTHENTICATION);
      const errorHandler: ErrorHandler = useService<ErrorHandler>(
        ERROR_HANDLER
      );
      actions.setAuthLoading(true);
      const result = await auth.changeEmail(email);
      if (result.status !== 0) {
        errorHandler.handleOnGravityError(result.status, result.message);
      } else {
        await actions.updateEmail(email);
      }
      actions.setAuthLoading(false);
      actions.checkAuth();
    },
    changePassword: async ({ passwords }: any) => {
      const auth: Authentication = useService<Authentication>(AUTHENTICATION);
      const errorHandler: ErrorHandler = useService<ErrorHandler>(
        ERROR_HANDLER
      );
      actions.setAuthLoading(true);
      const result = await auth.changePassword(
        passwords.password,
        passwords.newPassword
      );
      if (result.status !== 0) {
        errorHandler.handleOnGravityError(result.status, result.message);
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.userPasswordUpdated.heading",
            "notifications.userPasswordUpdated.description"
          )
        );
      }
      actions.setAuthLoading(false);
      actions.checkAuth();
    },
  }),

  events: ({ actions }: any) => ({
    afterMount: [actions.checkAuth],
  }),
});
