import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IComponentProps } from "../../component-props.i";
import "./language-change-component.scss";
import { useActions, useValues } from "kea";
import { Col, Row } from "react-flexbox-grid";
import { appLogic } from "../../logics/app.logic";
import {
  ISelectItem,
  SelectInput,
} from "../../components/select-input/select-input";
import { Button, Card } from "@blueprintjs/core";
import { listLogic } from "../../logics/list.logic";
import { useEffect } from "react";

export interface LanguageItem extends IComponentProps {
  LanguageCode: string;
  onSubmit: Function;
}

export const LanguageChangeComponent: React.FC<LanguageItem> = (props) => {
  const { track } = useActions(appLogic);
  const { t } = useTranslation();
  const [selectedLanguageItem, setSelectedLanguageItem] = useState<
    ISelectItem | undefined
  >();
  const { languages }: { languages: ISelectItem[] } = useValues(listLogic);
  const [isEditingLanguage, setEditingLanguage] = useState(false);

  const filteredLanguageItem = (id: any) =>
    languages.find((language) => language.id === id);

  useEffect(() => {
    if (!selectedLanguageItem) {
      let item: ISelectItem = getLanguageItem(props.LanguageCode);
      !!props?.LanguageCode && setSelectedLanguageItem(item);
    }
  });

  useEffect(() => {
    setSelectedLanguageItem(filteredLanguageItem(props.LanguageCode));
  }, [languages]);

  const getLanguageItem = (id: string) => {
    let item = languages.find((element) => element.id == id) || {
      id: "en",
      label: "English",
    };
    return item;
  };

  const handleLanguageItemChanges = (data: any) => {
    track("left.account.myaccount.language.change", {
      value1: data.label,
    });
    setSelectedLanguageItem(data);
  };

  const updateLanguage = () => {
    setEditingLanguage(false);
    let lng = selectedLanguageItem?.id;
    props.onSubmit.call(null, lng);
  };

  return (
    <Card className="account-label">
      {!isEditingLanguage && (
        <Row className="align-items-center">
          <Col xs={3} sm={3} md={2} className="account-bp">
                      {t("pages.account.default")}
          </Col>
          <Col sm={12} md={5} className="lan-lb">
          { <SelectInput
              items={languages}
              onChange={handleLanguageItemChanges}
              value={selectedLanguageItem}
            /> }
          </Col>
          <Col sm={12} md={2} className="justify-content-md-end">
            <Button className="bp3-intent-secondary"
              type="submit"
              onClick={() => updateLanguage()}
            >
              {t("change")}
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
};