import { provide } from "@creately/use-service";
import {
  API_SERVICE,
  AUTHENTICATION,
  POST_MESSAGE_SERVICE,
  NOTIFICATION_SERVICE,
  ERROR_HANDLER,
} from "./token";
import { Authentication } from "./services/authentication.s";
import { ApiService } from "./services/api.s";
import { PostMessageService } from "./services/post-message.s";
import { NotificationService } from "./services/notification.s";
import { ErrorHandler } from "./services/error-handler.s";

export function bootstrapProviders() {
  provide(AUTHENTICATION, { useClass: Authentication });
  provide(API_SERVICE, { useClass: ApiService });
  provide(POST_MESSAGE_SERVICE, { useClass: PostMessageService });
  provide(NOTIFICATION_SERVICE, { useClass: NotificationService });
  provide(ERROR_HANDLER, { useClass: ErrorHandler });
}
