import { useService } from "@creately/use-service";
import { kea } from "kea";
import { ApiService } from "../services/api.s";
import {
  buildTranslatedErrorNotification,
  buildTranslatedWarningNotification,
  buildTranslatedSuccessNotification,
} from "../helpers/notification.h";
import { notificationLogic } from "./notification.logic";
import { userLogic } from "./user.logic";
import { appLogic } from "./app.logic";
import { API_SERVICE } from "../token";
import { tEn } from "../services/en-translate.s";

export const organizationLogic = kea({
  connect: {
    actions: [
      notificationLogic,
      ["showNotification"],
      userLogic,
      [
        "loadUsers",
        "loadOrgUsers",
        "loadOrganization",
        "organizationUpdate",
        "organizationUpdateImage",
        "organizationRemoveMember",
        "organizationUpdateMember",
        "removeBillingContact",
      ],
      appLogic,
      ["track", "trackNotification"],
    ],
  },

  loaders: ({ actions }: any) => ({
    inviteMembers: [
      [],
      {
        inviteMembers: async (user: any) => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.inviteMembers(
            user.emails,
            user.planId,
            user.role
          );

          return { ...user, ...res };
        },
      },
    ],
    updateMember: [
      false,
      {
        updateMember: async (user: any) => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.updateMember(
            user.email,
            user.planId,
            user.role,
            user.status
          );
          return { ...user, ...res };
        },
        resendMemberInvitation: async (user: any) => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.resendMemberInvitation(
            user.email,
            user.planId,
            user.role
          );
          return { ...user, ...res };
        },
      },
    ],
    deleteMember: [
      false,
      {
        deleteMember: async ({ user, transferEmail }: any) => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.deleteMember(user, transferEmail);
          return { email: user, ...res };
        },
        cancelInvitation: async ({ email }: any) => {
          const api = useService<ApiService>(API_SERVICE);
          const res = await api.deleteMember(email, "");
          return { email: email, ...res };
        },
      },
    ],
    updateOrganization: [
      false,
      {
        updateOrganization: async (data: any) => {
          try {
            const api = useService<ApiService>(API_SERVICE);
            const res = await api.updateOrganization(data);
            return { ...data, ...res };
          } catch (ex) {
            actions.trackNotification(
              "notification.accountPortal.organisation.fail",
              {
                value1: "Name Update Failed",
                value2: tEn("errors.unknownTryAgain"),
              }
            );
            throw ex;
          }
        },
      },
    ],
    uploadImage: [
      "",
      {
        uploadImage: async (data: { fileBase64: string }) => {
          actions.track("accountPortal.organisation.image.change");
          const api = useService<ApiService>(API_SERVICE);
          let payloadData;
          await api
            .uploadOrganizationImage(data.fileBase64)
            .then((file) => {
              const payload = {
                profileImage: { url: file?.organizationUpdateImage?.url },
              };
              payloadData = payload;
            })
            .catch(() => {
              actions.trackNotification(
                "notification.accountPortal.organisation.fail",
                {
                  value1: "Image Upload Failed",
                  value2: tEn("errors.unknownTryAgain"),
                }
              );
            });
          return payloadData;
        },
      },
    ],
    updateOrganizationSetting: [
      false,
      {
        updateOrganizationSetting: async (data: any) => {
          try {
            const api = useService<ApiService>(API_SERVICE);
            const res = await api.updateOrganizationSettings(data.settingId, data.userAccess);
            return { ...data, ...res };
          } catch (ex) {
            actions.trackNotification(
              "notification.accountPortal.organisation.fail",
              {
                value1: "Setting Update Failed",
                value2: tEn("errors.unknownTryAgain"),
              }
            );
            throw ex;
          }
        },
      },
    ],
  }),

  listeners: ({ actions }: any) => ({
    inviteMembersSuccess: ({ inviteMembers }: any) => {
      if (!inviteMembers.organizationAddMember) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.sendingInvitesFailed",
            "errors.unknownTryAgain"
          )
        );
      } else if (!inviteMembers.organizationAddMember.status) {
        if ( inviteMembers.organizationAddMember.errorCode === "4115" ) {
          actions.showNotification(
            buildTranslatedErrorNotification(
              "errors.sendingInvitesFailed",
              "errors.userAlreadyInOrganization"
            )
          ) 
        } else if ( inviteMembers.organizationAddMember.errorCode === "4119" ) {
          actions.showNotification(
            buildTranslatedErrorNotification(
              "errors.sendingInvitesFailed",
              "errors.userAlreadyInAnotherRegion"
            )
          );
        } else if ( inviteMembers.organizationAddMember.errorCode === "4112" ) {
          actions.showNotification(
            buildTranslatedErrorNotification(
              "errors.sendingInvitesFailed",
              "errors.permissionError"
            )
          );
        } else if ( inviteMembers.organizationAddMember.errorCode === "4114" ) {
          actions.showNotification(
            buildTranslatedErrorNotification(
              "errors.sendingInvitesFailed",
              "errors.userAlreadyInAnotherOrganization"
            )
          );
        } else {
          actions.showNotification(
            buildTranslatedErrorNotification(
              "errors.sendingInvitesFailed",
              "errors.unknownTryAgain"
            )
          );
        }
      } else {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.inviteEmailsSent.heading",
            "notifications.inviteEmailsSent.description"
          )
        );

        actions.trackNotification(
          "notification.accountPortal.organisation.success",
          {
            value1: tEn("notifications.inviteEmailsSent.heading"),
            value2: tEn("notifications.inviteEmailsSent.description"),
          }
        );

        actions.loadUsers();
      }
    },

    updateOrganizationSuccess: ({ updateOrganization }: any) => {
      if (!updateOrganization.organizationUpdate) {
        actions.showNotification(
          buildTranslatedWarningNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );

        actions.trackNotification(
          "notification.accountPortal.organisation.success",
          {
            value1: "Name update failed",
            value2: tEn("errors.unknownTryAgain"),
          }
        );
      } else if (updateOrganization?.billingContacts) {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.billingContactsAdded.heading",
            "notifications.billingContactsAdded.description",
            undefined,
            {
              emails: updateOrganization.billingContacts.join(", ").trim(),
              count: updateOrganization.billingContacts.length,
            }
          )
        );
        actions.loadUsers();
        actions.trackNotification(
          "notification.accountPortal.billing.success",
          {
            value1: tEn("notifications.billingContactsAdded.heading"),
            value2: tEn("notifications.billingContactsAdded.description"),
          }
        );
      } else if (updateOrganization?.planStatus) {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.planStatusChanged.heading",
            "notifications.planStatusChanged.description"
          )
        );
        actions.loadUsers();
      } else if (updateOrganization?.removeBillingContact) {
        actions.removeBillingContact(updateOrganization?.removeBillingContact);
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.billingContactRemoved.heading",
            "notifications.billingContactRemoved.description"
          )
        );
        actions.trackNotification(
          "notification.accountPortal.billing.success",
          {
            value1: tEn("notifications.billingContactRemoved.heading"),
            value2: tEn("notifications.billingContactRemoved.description"),
          }
        );
      } else if (updateOrganization?.billingAddress) {
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.addressUpdated.heading",
            "notifications.addressUpdated.description",
            undefined,
            {
              email: updateOrganization.removeBillingContact,
            }
          )
        );
        actions.loadUsers();
        actions.trackNotification(
          "notification.accountPortal.billing.success",
          {
            value1: tEn("notifications.addressUpdated.heading"),
            value2: tEn("notifications.addressUpdated.description"),
          }
        );
      } else {
        actions.trackNotification(
          "notification.accountPortal.organisation.success",
          {
            value1: tEn("notifications.organizationUpdated.heading"),
            value2: tEn("notifications.organizationUpdated.description"),
          }
        );

        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.organizationUpdated.heading",
            "notifications.organizationUpdated.description"
          )
        );
        actions.organizationUpdate(updateOrganization);
      }
    },

    deleteMemberSuccess: ({ deleteMember }: any) => {
      if (!deleteMember.organizationRemoveMember) {
        actions.trackNotification(
          "notification.accountPortal.organisation.fail",
          {
            value1: "Delete Member Failed",
            value2: tEn("errors.unknownTryAgain"),
          }
        );
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.trackNotification(
          "notification.accountPortal.organisation.success",
          {
            value1: tEn("notifications.userDeleted.heading"),
            value2: tEn("notifications.userDeleted.description"),
          }
        );

        actions.organizationRemoveMember(deleteMember.email);
        actions.loadUsers();
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.userDeleted.heading",
            "notifications.userDeleted.description"
          )
        );
      }
    },

    updateMemberSuccess: ({ updateMember }: any) => {
      if (!updateMember.organizationUpdateMember) {
        actions.trackNotification(
          "notification.accountPortal.organisation.success",
          {
            value1: "Organization member role/status failed",
            value2: tEn("errors.unknownTryAgain"),
          }
        );

        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.trackNotification(
          "notification.accountPortal.organisation.success",
          {
            value1: tEn("notifications.orgUserUpdated.heading"),
            value2: tEn("notifications.orgUserUpdated.description"),
          }
        );

        actions.organizationUpdateMember(updateMember);
        actions.loadUsers();
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.orgUserUpdated.heading",
            "notifications.orgUserUpdated.description"
          )
        );
      }
    },

    resendMemberInvitationSuccess: ({ updateMember }: any) => {
      if (!updateMember.organizationUpdateMember) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.organizationUpdateMember(updateMember);
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.inviteEmailResent.heading",
            "notifications.inviteEmailResent.description"
          )
        );
      }
    },

    cancelInvitationSuccess: ({ deleteMember }: any) => {
      if (!deleteMember.organizationRemoveMember) {
        actions.showNotification(
          buildTranslatedErrorNotification(
            "errors.failed",
            "errors.unknownTryAgain"
          )
        );
      } else {
        actions.organizationRemoveMember(deleteMember.email);
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.invitationCancelled.heading",
            "notifications.invitationCancelled.description"
          )
        );
      }
    },
    uploadImageSuccess: ({ uploadImage }: any) => {
      if (uploadImage.profileImage.url) {
        actions.trackNotification(
          "notification.accountPortal.organisation.success",
          {
            value1: tEn("notifications.organizationImageUpdated.heading"),
            value2: tEn("notifications.organizationImageUpdated.description"),
          }
        );
        actions.showNotification(
          buildTranslatedSuccessNotification(
            "notifications.organizationImageUpdated.heading",
            "notifications.organizationImageUpdated.description"
          )
        );
        actions.organizationUpdateImage(uploadImage);
      } else {
        actions.trackNotification(
          "notification.accountPortal.organisation.fail",
          {
            value1: tEn("notifications.uploadFailed.heading"),
            value2: tEn("notifications.uploadFailed.description"),
          }
        );

        actions.showNotification(
          buildTranslatedErrorNotification(
            "notifications.uploadFailed.heading",
            "notifications.uploadFailed.description"
          )
        );
      }
    },
  }),
});
