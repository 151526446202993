import { Intent, Position, Toaster } from "@blueprintjs/core";
import { injectable, useService } from "@creately/use-service";
import React from "react";
import { appLogic, AppMode } from "../logics/app.logic";
import { PostMessageService, PostMessageSendEventType } from "./post-message.s";
import { POST_MESSAGE_SERVICE } from "../token";

/**
 * Notification types available in app.
 */
export enum NotificationType {
  Neutral = "neutral",
  Warning = "warning",
  Error = "error",
  Success = "success",
}

/**
 * An interface that described notification data.
 */
export interface INotification {
  /**
   * A unique identifier for the notification.
   */
  id: string;

  /**
   * The notification heading.
   */
  heading: string;

  /**
   * The notification description.
   */
  description: string;

  /**
   * The notification type.
   */
  type: NotificationType;

  /**
   * Whether or not the notification should dismiss itself.
   */
  autoDismiss?: boolean;

  /**
   * The period of time (in seconds) after which the notification
   * should dismiss itself.
   */
  dismissAfter?: number;
}

// A Toast instance currently cannot be created inside the React lifecycle.
// Docs https://blueprintjs.com/docs/#core/components/toast.static-usage
// TODO: Replace Toaster with notification component in V2
export const AppToaster = Toaster.create({
  className: "app-toaster",
  position: Position.TOP,
});

/**
 * Notification service
 * An injectable service which shows and hides app notifications.
 */
@injectable()
export class NotificationService {
  private appMode: AppMode;

  constructor() {
    this.appMode = appLogic?.values?.appMode;
  }

  public show(notification: INotification) {
    if (this.appMode === AppMode.Embedded) {
      const postMessageService = useService<PostMessageService>(
        POST_MESSAGE_SERVICE
      );
      postMessageService.sendToParent(
        PostMessageSendEventType.ShowNotification,
        notification
      );

      return;
    }

    AppToaster.show(
      {
        message: (
          <span>
            <strong>{notification.heading}</strong>
            <br />
            {notification.description}
          </span>
        ),
        intent:
          notification.type === NotificationType.Error
            ? Intent.DANGER
            : Intent.PRIMARY,
      },
      notification.id
    );
  }

  public hide(id: string) {
    if (this.appMode === AppMode.Embedded) {
      const postMessageService = useService<PostMessageService>(
        POST_MESSAGE_SERVICE
      );

      postMessageService.sendToParent(
        PostMessageSendEventType.HideNotification,
        {
          id: id,
        }
      );

      return;
    }

    AppToaster.dismiss(id);
  }
}
