import { MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Suggest } from "@blueprintjs/select";
import { useActions } from "kea";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IComponentProps } from "../../component-props.i";
import "./select-search.scss";
import { useDebounce } from "../../services/debounce";
import { appLogic } from "../../logics/app.logic";

export interface ISelectInputProps extends IComponentProps {
  placeholder?: string;
  value?: ISelectSearchItem;
  onChange?: (value: ISelectSearchItem) => void;
  onSearch?: (search: string) => void;
  items: ISelectSearchItem[];
  disabled?: boolean;
  filterable?: boolean;
}

export interface ISelectSearchItem {
  id: any;
  label: string;
  url?: string;
}

export const SelectSearch: FC<ISelectInputProps> = (props) => {
  const defaultClass = "select-search";

  const [selectedValue, setSelectedValue] = useState<ISelectSearchItem>();
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchValue, 500);
  const { track } = useActions(appLogic);
  const { t } = useTranslation();

  const CustomSelect = Suggest.ofType<ISelectSearchItem>();

  const renderSelectItem: ItemRenderer<ISelectSearchItem> = (
    item: ISelectSearchItem,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <div
        onClick={handleClick}
        key={item.id}
        className={`${defaultClass}__profile-container`}
      >
        <div className="w-25">
          <img
            className={`${defaultClass}__profile-image`}
            src={item.url || "/resources/images/default-profile-img.png"}
          />
        </div>
        <div className="w-75">
          {item.label && (
            <div className={`${defaultClass}__name`}>{item.label}</div>
          )}
          <div className={`${defaultClass}__email`}>{item.id}</div>
        </div>
      </div>
    );
  };

  const handleItemSelect = (item: ISelectSearchItem | null) => {
    track("n.dialog.accountPortal.users.transfer.click");
    !!item && setSelectedValue(item);
    !!item && props?.onChange?.call(null, item);
  };

  const renderInputValue = (item: ISelectSearchItem) => item.label;

  const searchItems = (search: string) => {
    setSearchValue(search);
  };

  useEffect(() => {
    if (!!props.value) {
      setSelectedValue(props.value);
    }
  });

  useEffect(() => {
    props?.onSearch?.call(null, debouncedValue);
  }, [debouncedValue]);

  return (
    <CustomSelect
      className={classNames(defaultClass, props?.className)}
      items={props.items}
      closeOnSelect={true}
      onItemSelect={handleItemSelect}
      inputValueRenderer={renderInputValue}
      itemRenderer={renderSelectItem}
      activeItem={selectedValue}
      onQueryChange={searchItems}
      noResults={<MenuItem disabled={true} text={t("noResults")} />}
      popoverProps={{ usePortal: true, minimal: true }}
      disabled={false}
      resetOnClose={true}
      resetOnQuery={true}
      resetOnSelect={false}
    ></CustomSelect>
  );
};
