import { Card } from "@blueprintjs/core";
import { Col, Row } from "react-flexbox-grid";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IconRightLight,
  IconRightDark,
  IconOwner,
  IconAdmin,
  IconMember,
  IconGuest,
} from "../icons/icons";
import "./organization-privileges.scss";
import {
  PermissionsMatrix,
  IPrivilege,
  IRoleType,
} from "./../permissions/permissions";

export const OrganizationPrivileges: FC<{}> = () => {
  const defaultClass = "organization-privileges";
  const { t } = useTranslation();
  const [permissions] = useState<IPrivilege[]>(PermissionsMatrix);

  return (
    <Card className="mb-3 ">
      <Row>
        <Col>
          <h1 className={`${defaultClass}__title`}>
            {t("pages.organization.organizationPrivileges")}
          </h1>
        </Col>
      </Row>
      <table className={`${defaultClass}__table`}>
        <thead>
          <tr>
            <th>
              <h1 className={`${defaultClass}__header`}>
                {t("pages.organization.accessSettings")}
              </h1>
            </th>
            <th>{IconOwner}</th>
            <th>{IconAdmin}</th>
            <th>{IconMember}</th>
            <th>{IconGuest}</th>
          </tr>
        </thead>
        <tbody>
           {permissions.map((p: IPrivilege,index : number) => (
            <tr key={index}>
              <td>
                {t(`components.organizationPrivileges.permissions.${p.name}`)}
              </td>
              {p.roles.map((roleTyle: IRoleType, index: number) =>
                roleTyle.editable ? (
                  <td key={index}>{IconRightDark}</td>
                ) : (
                  <td key={index}>{IconRightLight}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};
