import i18n from "../i18n";

/**
 * This function translates a key and return english translation irrespective of what user selects.
 * @param key namespace key
 * @return  english translation of the text
 */
function tEn(key: string, options: any = {}) {
  return i18n.t(key, { ...options, lng: "en" });
}

export { tEn };
