import { useService } from "@creately/use-service";
import { kea } from "kea";
import { INotification, NotificationService } from "../services/notification.s";
import { NOTIFICATION_SERVICE } from "../token";

export const notificationLogic = kea({
  actions: () => ({
    showNotification: (notification: INotification) => notification,
    hideNotification: (id: string) => id,
  }),

  listeners: () => ({
    /**
     * Shows notifications according on app mode.
     * Uses PostMessageService in embedded mode and NotificationService in normal mode.
     * @param notification the notification data to display.
     */
    showNotification: (notification: INotification) => {
      const notificationService = useService<NotificationService>(
        NOTIFICATION_SERVICE
      );
      notificationService.show(notification);
    },

    /**
     * Hides notifications according on app mode.
     * Uses PostMessageService in embedded mode and NotificationService in normal mode.
     * @param id the id of the notification to hide
     */
    hideNotification: (id: string) => {
      const notificationService = useService<NotificationService>(
        NOTIFICATION_SERVICE
      );
      notificationService.hide(id);
    },
  }),
});
