import { notificationLogic } from "../logics/notification.logic";
import { buildTranslatedErrorNotification } from "../helpers/notification.h";

export class ErrorHandler {
  constructor() {}

  private serverI18nErrorKey = "serverErrors";
  private serverErrorCodes = [
    5000,
    4003,
    5021,
    5022,
    5023,
    1602,
    900,
    4111,
    4112,
    4113,
    4114,
    4115,
  ];
  private gravityErrorI18nKey = "gravityErrors";
  private gravityErrorCodes = [
    100,
    101,
    102,
    200,
    201,
    202,
    203,
    204,
    205,
    206,
    207,
    208,
    2019,
    210,
    211,
    212,
    213,
    214,
    215,
    216,
    217,
    250,
  ];

  private getServerErrorI18nKey(code: number) {
    if (this.serverErrorCodes.includes(code)) {
      return `${this.serverI18nErrorKey}.${code}`;
    }
    return "";
  }

  private getGravityErrorI18nKey(code: number) {
    if (this.gravityErrorCodes.includes(code)) {
      return `${this.gravityErrorI18nKey}.${code}`;
    }
    return "errors.unknown";
  }

  public handleOnGravityError(code: number, message?: string) {
    const unmount = notificationLogic.mount();
    if (!!message) {
      notificationLogic.actions.showNotification(
        buildTranslatedErrorNotification(
          "errors.unknown",
          undefined,
          undefined,
          undefined,
          message
        )
      );
    } else {
      const errorKey = this.getGravityErrorI18nKey(code);
      notificationLogic.actions.showNotification(
        buildTranslatedErrorNotification("errors.authentication", errorKey)
      );
    }
    unmount();
  }

  public handleOnError(code: string) {
    const unmount = notificationLogic.mount();
    code = code === "Network Error" ? "900" : code;
    const errorKey = this.getServerErrorI18nKey(parseInt(code, 10));
    notificationLogic.actions.showNotification(
      buildTranslatedErrorNotification("errors.unknown", errorKey)
    );
    unmount();
  }
}
