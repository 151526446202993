import i18n from "../i18n";
import { INotification, NotificationType } from "../services/notification.s";

export const buildNotification = (
  id: string | null,
  heading: string,
  description: string,
  type: NotificationType,
  autoDismiss: boolean = true
): INotification => {
  return {
    id: id ? id : `${heading.replace(/\s+/g, "-")}-notification`,
    heading: heading,
    description: description,
    type: type,
    autoDismiss: autoDismiss,
    dismissAfter: 3000,
  };
};

export const buildSuccessNotification = (
  heading: string,
  description: string
): INotification => {
  return buildNotification(
    null,
    heading,
    description,
    NotificationType.Success
  );
};

export const buildWarningNotification = (
  heading: string,
  description: string
): INotification => {
  return buildNotification(
    null,
    heading,
    description,
    NotificationType.Warning
  );
};

export const buildErrorNotification = (
  heading: string,
  description: string
): INotification => {
  return buildNotification(null, heading, description, NotificationType.Error);
};

export const buildTranslatedSuccessNotification = (
  headingId: string,
  descriptionId?: string,
  headingArgs: {} = {},
  descriptionArgs: {} = {},
  descriptionExtra: string = ""
): INotification => {
  const heading = i18n.t(headingId, headingArgs);
  const description = descriptionId
    ? i18n.t(descriptionId, descriptionArgs) + " " + descriptionExtra
    : descriptionExtra;
  return buildSuccessNotification(heading, description);
};

export const buildTranslatedWarningNotification = (
  headingId: string,
  descriptionId?: string,
  headingArgs: {} = {},
  descriptionArgs: {} = {},
  descriptionExtra: string = ""
): INotification => {
  const heading = i18n.t(headingId, headingArgs);
  const description = descriptionId
    ? i18n.t(descriptionId, descriptionArgs) + " " + descriptionExtra
    : descriptionExtra;
  return buildSuccessNotification(heading, description);
};

export const buildTranslatedErrorNotification = (
  headingId: string,
  descriptionId?: string,
  headingArgs: {} = {},
  descriptionArgs: {} = {},
  descriptionExtra: string = ""
): INotification => {
  const heading = i18n.t(headingId, headingArgs);
  const description = descriptionId
    ? i18n.t(descriptionId, descriptionArgs) + " " + descriptionExtra
    : descriptionExtra;
  return buildErrorNotification(heading, description);
};
