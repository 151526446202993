import { Card, Icon, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { IComponentProps } from "../../../component-props.i";
import { formatNextBillingDate } from "../../../helpers/date.h";
import { appLogic } from "../../../logics/app.logic";
import "./billing-invoices-table.scss";
import { useActions } from "kea";
import { IInvoice } from "../../../models/organization.i";
import {
  INotification,
  NotificationType,
} from "../../../services/notification.s";
import { notificationLogic } from "../../../logics/notification.logic";

export interface IBillingInvoicesTableProps extends IComponentProps {
  data?: IInvoice[];
}

/**
 * This component will show the Billing contacts.
 * @author Nuwan
 * @since 2021-04-08
 */
export const BillingInvoicesTable: React.FC<IBillingInvoicesTableProps> = (
  props
) => {
  const defaultClass = "billing-invoices-table";
  const { t } = useTranslation();
  const { track } = useActions(appLogic);
  const { showNotification } = useActions(notificationLogic);

  const formatPriceWithCurrency = (currency: string, ammount: string) => {
    if (currency === "AUD") {
      return `A$ ${+ammount / 100}`;
    } else if (currency === "EUR") {
      return `€ ${+ammount / 100}`;
    } else {
      return `$ ${+ammount / 100}`;
    }
  };

  const getInvoiceStatus = (status: string) => {
    switch (status) {
      case "paid":
        return t("pages.billing.invoiceStatus.paid");

      case "posted":
        return t("pages.billing.invoiceStatus.posted");

      case "payment_due":
        return t("pages.billing.invoiceStatus.payment_due");

      case "not_paid":
        return t("pages.billing.invoiceStatus.not_paid");

      case "voided":
        return t("pages.billing.invoiceStatus.voided");

      case "pending":
        return t("pages.billing.invoiceStatus.pending");

      default:
        return "";
    }
  };

  const handleDownLoadInvoice = () => {
    track("accountPortal.billing.invoices.download.click");

    // show the invoice download notification
    const notification: INotification = {
      id: "billing-invoice-download",
      heading: t("notifications.downloadInvoice.heading"),
      description: t("notifications.downloadInvoice.description"),
      type: NotificationType.Success,
      autoDismiss: true,
    };
    showNotification(notification);
  };

  return (
    <>
      {props.data && (
        <div className={classNames(`${defaultClass}__invoices-container`)}>
          <div>
            <Row className={classNames(`${defaultClass}__header-row-styles`)}>
              <Col sm={2} md={2}>
                {t("pages.billing.invoiceIDLabel")}
              </Col>
              <Col sm={2} md={2}>
                {t("pages.billing.invoiceDateLabel")}
              </Col>
              <Col sm={5} md={3}>
                {t("pages.billing.invoiceDescriptionLabel")}
              </Col>
              <Col sm={1} md={2}>
                {t("pages.billing.invoicePriceLabel")}
              </Col>
              <Col sm={1} md={1}>
                {t("pages.billing.invoiceStatusLabel")}
              </Col>
              <Col sm={1} md={1}></Col>
            </Row>
            {props.data?.map((row) => (
              <Row
                key={row.id}
                className={classNames(`${defaultClass}__row-styles`)}
              >
                <Col sm={2} md={2}>
                  <span className={classNames(`${defaultClass}__other-cells`)}>
                    {row.id}
                  </span>
                </Col>
                <Col sm={2} md={2}>
                  <span className={classNames(`${defaultClass}__other-cells`)}>
                    {formatNextBillingDate(row.date)}
                  </span>
                </Col>
                <Col sm={5} md={3}>
                  <span className={classNames(`${defaultClass}__other-cells`)}>
                    {row.description}
                  </span>
                </Col>
                <Col sm={1} md={2}>
                  <span className={classNames(`${defaultClass}__other-cells`)}>
                    {formatPriceWithCurrency(row.currency, row.price)}
                  </span>
                </Col>
                <Col sm={1} md={1}>
                  <span className={classNames(`${defaultClass}__other-cells`)}>
                    {getInvoiceStatus(row.status)}
                  </span>
                </Col>
                <Col sm={1} md={1}>
                  <Tooltip content={t("pages.billing.invoicesSaveTooltip")}>
                    <a
                      href={row.saveURL}
                      className={classNames(`${defaultClass}__download-icon`)}
                      onClick={handleDownLoadInvoice}
                    >
                      <Icon icon="import" />
                    </a>
                  </Tooltip>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
      {!props.data && (
        <div>
          <Card>
            <img
              className={classNames(`${defaultClass}__empty-invoices-banner`)}
              src="resources/images/empty_invoices.png"
            ></img>
          </Card>
        </div>
      )}
    </>
  );
};
