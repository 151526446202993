import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { IComponentProps } from "../../component-props.i";
import { PageRoute } from "../../logics/route.logic";
import "./sidebar.scss";

export interface ISidebarItem {
  id: string;
  title: string;
  route: PageRoute;
  disabled?: boolean;
}

export interface ISidebarProps extends IComponentProps {
  items: ISidebarItem[];
  onClick: Function;
  activeItemId?: string;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
  const defaultClass = "sidebar";

  const [activeItemId, setActiveItemId] = useState(props?.activeItemId);

  const handleClick = (route: string) => {
    props.onClick.call(null, route);
  };

  useEffect(() => {
    if (props.activeItemId) {
      setActiveItemId(props.activeItemId);
    }
  }, [props?.activeItemId]);

  return (
    <div className={classNames(defaultClass, props?.className)}>
      <div className={`${defaultClass}__logo`}>
        <img src="resources/images/sidebar/sidebar-logo.svg" width="150" />
      </div>
      {props?.items &&
        props.items.map((item) => (
          <a
            key={item.id}
            className={classNames(
              `${defaultClass}__nav-item`,
              activeItemId === item.id
                ? `${defaultClass}__nav-item--active`
                : null
            )}
            onClick={() => handleClick(item.route)}
          >
            {item.title}
          </a>
        ))}
    </div>
  );
};
