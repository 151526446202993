import { Button, Card, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { useActions, useValues } from "kea";
import React, { useEffect, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormError } from "../../components/form-error/form-error";
import { ImageViewUploadInput } from "../../components/image-view-upload-input/image-view-upload-input";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";
import { PageSection } from "../../components/page-section/page-section";
import { PageTitle } from "../../components/page-title/page-title";
import { LanguageChangeComponent } from "../../components/language-change-component/language-change-component";
import {
  ISelectItem,
  SelectInput,
} from "../../components/select-input/select-input";
import { appLogic } from "../../logics/app.logic";
import { authenticationLogic } from "../../logics/authentication.logic";
import { listLogic } from "../../logics/list.logic";
import { userLogic } from "../../logics/user.logic";
import { IUser } from "../../models/user.i";
import "./account-page.scss";
import { useService } from "@creately/use-service";
import i18n from "../../i18n";
import { POST_MESSAGE_SERVICE } from "../../token";
import {
  PostMessageService,
  PostMessageSendEventType,
} from "../../services/post-message.s";

export const AccountPage: React.FC<{}> = () => {
  const defaultClass = "account-page";

  const {
    user,
    userLoading,
  }: { user: IUser; userLoading: boolean } = useValues(userLogic);

  const { updateUser, uploadAvatar } = useActions(userLogic);

  const { changePassword, changeEmail } = useActions(authenticationLogic);

  const { authLoading }: { authLoading: boolean } = useValues(
    authenticationLogic
  );

  const { track } = useActions(appLogic);

  const {
    usage,
    industries,
    roles,
  }: { usage: ISelectItem[]; industries: ISelectItem[], roles: ISelectItem[] } = useValues(listLogic);

  const [userDetails, setUserDetails] = useState({
    profileImageUrl: "",
    email: "",
    language: "en",
    mfaEnabled: false,
  });

  const [disable2FAError, setDisable2FAError] = useState('');

  const { appLanguage } = useValues(appLogic);

  const [selectedUsageItem, setSelectedUsageItem] = useState<
    ISelectItem | undefined
  >();

  const [selectedIndustryItem, setSelectedIndustryItem] = useState<
    ISelectItem | undefined
  >();

  const [selectedRoleItem, setSelectedRoleItem] = useState<
    ISelectItem | undefined
  >();

  const [isEditingEmail, setEditingEmail] = useState(false);

  const [isEditingPassword, setEditingPassword] = useState(false);

  const [isEmailUpdateButtonDisabled, setEmailUpdateButtonDisabled] = useState(
    true
  );

  const [
    isPasswordUpdateButtonDisabled,
    setUpdatePasswordButtonDisabled,
  ] = useState(true);

  const handleImageUpload = (image: any) => {
    uploadAvatar({ fileBase64: image });
  };

  const {
    register: registerUserName,
    errors: errorsUserName,
    handleSubmit: handleSubmitUserName,
    setValue: setValueUserName,
  } = useForm();

  const {
    register: registerEmail,
    errors: errorsEmail,
    handleSubmit: handleSubmitEmail,
    triggerValidation: triggerValidationEmail,
    getValues: getValuesEmail,
  } = useForm();

  const {
    register: registerPassword,
    errors: errorsPassword,
    handleSubmit: handleSubmitPassword,
    triggerValidation: triggerValidationPassword,
    getValues: getValuesPassword,
  } = useForm();

  const { t } = useTranslation();
  let language: string = user?.languagePreference || appLanguage;

  const handleUsageItemChanges = (data: any) => {
    const payload = {
      intendedUsage: data.id,
    };
    track("left.account.myAccount.q1.purpose.change", {
      value1Type: "answer",
      value1: data.label,
    });
    setSelectedUsageItem(data);
    updateUser(payload);
  };

  const handleRoleItemChanges = (data: any) => {
    const payload = {
      jobTitle: data.id,
    };
    track("left.account.myAccount.q1.purpose.change", {
      value1Type: "answer",
      value1: data.label,
    });
    setSelectedRoleItem(data);
    updateUser(payload);
  };

  const handleIndustriesItemChanges = (data: any) => {
    const payload = {
      industry: data.id,
    };
    track("left.account.myAccount.q1.sector.change", {
      value1: data.label,
      value1Type: "answer",
    });
    setSelectedIndustryItem(data);
    updateUser(payload);
  };

  const handlePasswordChange = (data: any) => {
    setEditingPassword(false);
    changePassword({
      password: data.currentPassword,
      newPassword: data.newPassword,
    });
  };

  const handleEmailChange = (data: any) => {
    setEditingEmail(false);
    changeEmail(data.email);
  };

  const handleUserNameChange = (data: any) => {
    if (user.firstName === data.firstName && user.lastName === data.lastName) {
      return;
    }
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
    };
    updateUser(payload);
  };

  const filteredUsageItem = () =>
    usage.find((usage) => usage.id === user.intendedUsage);

  const filteredRoleItem = () =>
    roles.find((role) => role.id === user.jobTitle);

  const filteredIndustryItem = () =>
    industries.find((industry) => industry.id === user.industry);

  const showRoleSelect = () => {
    return (selectedUsageItem?.id === 'education')
  }

  useEffect(() => {
    setEmailUpdateButtonDisabled(
      !!errorsEmail.email || !!!getValuesEmail("email")
    );
    setUpdatePasswordButtonDisabled(
      !!errorsPassword.currentPassword ||
      !!errorsPassword.newPassword ||
      !!!getValuesPassword("currentPassword") ||
      !!!getValuesPassword("newPassword")
    );
  });

  useEffect(() => {
    setValueUserName([
      { firstName: user?.firstName },
      { lastName: user?.lastName },
    ]);
    setUserDetails({
      ...userDetails,
      profileImageUrl: user?.avatar?.url || "",
      email: user?.email || "",
      mfaEnabled: user?.mfaEnabled || false,
    });
  }, [user]);

  useEffect(() => {
    setSelectedUsageItem(filteredUsageItem());
  }, [user, usage]);

  useEffect(() => {
    setSelectedIndustryItem(filteredIndustryItem());
  }, [user, industries]);

  useEffect(() => {
    setSelectedRoleItem(filteredRoleItem());
  }, [user, roles]);

  useEffect(() => {
    if (user.languagePreference) {
      language = user.languagePreference;
    }
  }, [user]);

  // language change function
  const updateLanguage = (lng: string) => {
    // update in Nucleus
    const postMessageService = useService<PostMessageService>(
      POST_MESSAGE_SERVICE
    );
    postMessageService.sendToParent(PostMessageSendEventType.ChangeLanguage, {
      language: lng,
    });
    
    // update in TP
    i18n.changeLanguage(lng);

    // update in database
    const payload = {
      languagePreference: lng,
    };
    updateUser(payload);
  };

  // toggle 2FA function
  const toggle2FA = () => {
    if (!userDetails.mfaEnabled) {
      const postMessageService = useService<PostMessageService>(
        POST_MESSAGE_SERVICE
      );
      postMessageService.sendToParent(PostMessageSendEventType.Setup2FA, {
        id: 'setup-2fa',
      });
    } else {
      window.gravity.api.disable2FA().then( result => {
        if ( result.status !== 0 ) {
          setDisable2FAError( result.message );
        } else {
          setDisable2FAError( '' );
          setUserDetails({
            ...userDetails,
            mfaEnabled: false,
          })
        }
      });
    }
  };

  return userLoading ? (
    LoadingIndicator
  ) : (
      <div className={defaultClass}>
        <Grid fluid>
          <PageSection>
            <PageTitle>{t("myAccount")}</PageTitle>
          </PageSection>

          <PageSection>
            <Row>
              <Col xs={12} md={2} className={`${defaultClass}__logo-cell`}>
                <Card className={`${defaultClass}__logo-container`} >
                  <ImageViewUploadInput
                    imageUrl={user?.avatar?.url}
                    className={`${defaultClass}__logo`}
                    uploadImage={handleImageUpload}
                    placeholderIcon={IconNames.CAMERA}
                  />
                </Card>
              </Col>
              <Col xs={12} md={10}>
                <Card className="account-label">
                  <form onBlur={handleSubmitUserName(handleUserNameChange)}>
                    <Row>
                      <Col xs={12} md={6}>
                        <label className="small-label">
                          {t("pages.account.firstNameLabel")}
                        </label>
                        <InputGroup
                          name="firstName"
                          inputRef={registerUserName({
                            required: {
                              value: true,
                              message: t("formErrors.required", {
                                field: t("pages.account.firstNameLabel"),
                              }),
                            },
                            validate: (value) =>
                              window.gravity.validator.isValidName(value),
                          })}
                        />
                        {!!errorsUserName.firstName && (
                          <FormError>
                            {errorsUserName.firstName.message}
                          </FormError>
                        )}
                      </Col>
                      <Col xs={12} md={6}>
                        <label className="small-label">
                          {t("pages.account.lastNameLabel")}
                        </label>
                        <InputGroup
                          name="lastName"
                          inputRef={registerUserName({
                            required: {
                              value: true,
                              message: t("formErrors.required", {
                                field: t("pages.account.lastNameLabel"),
                              }),
                            },
                            validate: (value) =>
                              window.gravity.validator.isValidName(value),
                          })}
                        />
                        {!!errorsUserName.lastName && (
                          <FormError>{errorsUserName.lastName.message}</FormError>
                        )}
                      </Col>
                    </Row>
                  </form>
                </Card>
              </Col>
            </Row>
          </PageSection>
          <PageSection>
            <h2>{t("pages.account.workDetails")}</h2>
            <Card className="account-label">
              <Row>
                <Col sm={12} md={6}>
                  <label className="small-label">
                    {t("pages.account.usedFor")}
                  </label>
                  <SelectInput
                    items={usage}
                    onChange={handleUsageItemChanges}
                    value={selectedUsageItem}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <label className="small-label">
                    {t("pages.account.teamFunction")}
                  </label>
                  {!showRoleSelect() && (
                  <SelectInput
                    items={industries}
                    onChange={handleIndustriesItemChanges}
                    value={selectedIndustryItem}
                  />
                  )}
                  {showRoleSelect() && (
                    <SelectInput
                      items={roles}
                      onChange={handleRoleItemChanges}
                      value={selectedRoleItem}
                    />)}
                </Col>
              </Row>
            </Card>
          </PageSection>
          
          <PageSection>
            <h2>{t("pages.account.language")}</h2>
            <LanguageChangeComponent
              LanguageCode={language}
              onSubmit={updateLanguage}
            />
          </PageSection>

          <PageSection>
            <h2>{t("pages.account.accountEmailPassword")}</h2>
            {authLoading ? LoadingIndicator : <></>}
            <Card className="account-label">
            <Row className="align-items-center">
              <Col xs={3} sm={3} md={2} className="account-bp">
                {t("pages.account.emailAddress")}
              </Col>
              <Col sm={12} md={4} className="bp3-input account-pw">
                {userDetails.email}
              </Col>
              <Col
                  xs={12}
                  sm={4}
                  md={4}
                  className="justify-content-md-end account-pb-btn"
                >
                  {!isEditingEmail && (
                    <Button className="bp3-intent-secondary"
                      onClick={() => setEditingEmail(true)}
                    >
                      {t("change")}
                    </Button>
                  )}
                </Col>
            </Row>
              {isEditingEmail && (
                <form onSubmit={handleSubmitEmail(handleEmailChange)}>
                  <Row className="align-items-center new-email">
                    <Col xs={3} sm={3} md={2} className="account-bp">
                      {t("pages.account.newEmailLabel")}
                    </Col>
                    <Col sm={12} md={4}>
                      <InputGroup
                        className={classNames(
                          !!errorsEmail.email && "input--error"
                        )}
                        onKeyUp={async () =>
                          await triggerValidationEmail("email")
                        }
                        placeholder={t("pages.account.newEmailPlaceholder")}
                        type="email"
                        name="email"
                        formNoValidate={true}
                        inputRef={registerEmail({
                          required: true,
                          validate: (value) =>
                            window.gravity.validator.isValidEmail(value),
                        })}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={4} className="justify-content-md-end email-btn">
                    <Button className="bp3-intent-secondary"
                        type="submit"
                        disabled={isEmailUpdateButtonDisabled}
                      >
                        {t("update")}
                      </Button>
                    </Col>
                  </Row>
                </form>
              )}
            </Card>

            <Card className="account-label">
              <Row className="align-items-center">
                <Col xs={3} sm={3} md={2} className="account-bp">
                  {t("pages.account.passwordLabel")}
                </Col>
                <Col sm={12} md={4} className="bp3-input account-pw">
                  ***********
                </Col>
                <Col
                  xs={12}
                  sm={4}
                  md={4}
                  className="justify-content-md-end account-pb-btn"
                >
                  {!isEditingPassword && (
                    <Button className="bp3-intent-secondary"
                      onClick={() => setEditingPassword(true)}
                    >
                      {t("change")}
                    </Button>
                  )}
                </Col>
              </Row>
              {isEditingPassword && (
                <>
                  <form onSubmit={handleSubmitPassword(handlePasswordChange)}>
                    <Row className="mt-2">
                      <Col sm={12} md={10} mdOffset={2} className="mt-2 mb-3">
                        {errorsPassword.currentPassword?.message ||
                          errorsPassword.newPassword?.message ? (
                            <FormError>
                              {errorsPassword.currentPassword?.message ||
                                t("pages.account.passwordHint")}
                            </FormError>
                          ) : (
                            <div className="mb-2">
                              {t("pages.account.passwordHint")}
                            </div>
                          )}
                      </Col>
                      <Col sm={12} md={2} className="account-bp">
                        {t("pages.account.currentPasswordLabel")}
                      </Col>
                      <Col sm={12} md={6}>
                        <InputGroup
                          className={classNames(
                            !!errorsPassword.currentPassword && "input--error"
                          )}
                          onKeyUp={async () =>
                            await triggerValidationPassword("currentPassword")
                          }
                          onBlur={async () =>
                            await triggerValidationPassword("currentPassword")
                          }
                          placeholder={t(
                            "pages.account.currentPasswordPlaceholder"
                          )}
                          type="password"
                          name="currentPassword"
                          inputRef={registerPassword({
                            required: {
                              value: true,
                              message: t("formErrors.required", {
                                field: t("pages.account.currentPasswordLabel"),
                              }),
                            },
                          })}
                        />
                      </Col>
                      <Col sm={12} md={4}></Col>
                    </Row>

                    <Row>
                      <Col sm={12} md={2} className="account-bp">
                        {t("pages.account.newPasswordLabel")}
                      </Col>
                      <Col sm={12} md={6}>
                        <InputGroup
                          className={classNames(
                            !!errorsPassword.newPassword && "input--error"
                          )}
                          onKeyUp={async () =>
                            await triggerValidationPassword("newPassword")
                          }
                          placeholder={t("pages.account.newPasswordPlaceholder")}
                          type="password"
                          name="newPassword"
                          inputRef={registerPassword({
                            required: {
                              value: true,
                              message: t("pages.account.passwordHint"),
                            },
                            validate: (value) =>
                              window.gravity.validator.isValidPassword(value),
                          })}
                        />
                      </Col>
                      <Col
                        sm={12}
                        md={4}
                        className="d-flex justify-content-md-end"
                      >
                        <Button className="bp3-intent-secondary"
                          type="submit"
                          disabled={isPasswordUpdateButtonDisabled}
                        >
                          {t("update")}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </>
              )}
            </Card>
          </PageSection>
          <PageSection>
            <h2>{t("pages.account.setup2F")}</h2>
            <Card className="account-label">
              <Row className="align-items-center">
                <Col xs={3} sm={3} md={2} className="account-bp">
                  {userDetails.mfaEnabled ? t("pages.account.2faEnabledLabel") : t("pages.account.enable2FALabel")}
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={3}
                  className="justify-content-md-end account-pb-btn"
                >
                  <Button className="bp3-intent-secondary"
                    onClick={() => toggle2FA()}
                  >
                    {userDetails.mfaEnabled ? t("disable") : t("enable")}
                  </Button>
                </Col>
                <Col xs={6} sm={6} md={6} className="account-bp">
                  Need Help? <a href="https://support.creately.com/hc/en-us">Contact Support</a>
                </Col>
              </Row>
              { disable2FAError ? <Row  className="mt-2" >
                <Col xs={12} sm={12} md={12} className="account-bp">
                  <div className="form-error">{ disable2FAError }</div>
                </Col>
              </Row> : ''}
            </Card>
          </PageSection>
        </Grid>
      </div>
    );
};
