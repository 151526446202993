import { IconNames } from "@blueprintjs/icons";
import { useValues } from "kea";
import React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { PageSection } from "../../components/page-section/page-section";
import { PageTitle } from "../../components/page-title/page-title";
import { IStatCardProps, StatCard } from "../../components/stat-card/stat-card";
import { userLogic } from "../../logics/user.logic";
import { IUser } from "../../models/user.i";
import "./overview-page.scss";
import { LoadingIndicator } from "../../components/loading-indicator/loading-indicator";

export const OverviewPage: React.FC<{}> = () => {
  const defaultClass = "overview-page";
  const { user, userLoading } = useValues(userLogic);
  const userDetails: IUser = user;
  const { t } = useTranslation();

  const isTeamPlan: boolean = !!userDetails?.organization?.id;

  // Data for the personal plans
  const getPersonalStats = ():IStatCardProps[] => {
    return [
      {
        title: t("plan"),
        icon: IconNames.USER,
        active: true,
        iconColor: "grey",
        stat: `${ userDetails?.planName }`,
      },
      {
        title: t("folders"),
        icon: IconNames.FOLDER_OPEN,
        iconColor: "grey",
        active: true,
        stat: `${ userDetails?.totalFolders }`,
      },
      {
        title: t("workspaces"),
        icon: IconNames.DOCUMENT,
        iconColor: "grey",
        active: true,
        stat: `${ userDetails?.totalDocuments }`,
      },
    ];
  };

  // Data for the Team plans
  const getOrganizationStats = (): IStatCardProps[] => {
    return [
      {
        title: t("organization"),
        icon: IconNames.USER,
        active: userDetails?.organization?.id ? true : false,
        iconColor: "grey",
        stat: userDetails?.organization?.size == -1 
            ? `${userDetails?.organization?.usedAccount|| 0}` 
            : `${userDetails?.organization?.size - userDetails?.organization?.remainingSeats || 0}
              /${userDetails?.organization?.size || 0}`,
        description: userDetails?.organization?.size == -1 ? `${userDetails?.planName}` : `${userDetails?.planName} (${userDetails?.organization?.size})`,
      },
      {
        title: t("folders"),
        icon: IconNames.FOLDER_OPEN,
        iconColor: "grey",
        active: true,
        stat: `${
          userDetails?.organization?.id
            ? userDetails?.organization?.totalFolders
            : userDetails?.totalFolders
        }`,
      },
      {
        title: t("orgDocuments"),
        icon: IconNames.PEOPLE,
        iconColor: "grey",
        active: true,
        stat: `${
          userDetails?.organization?.id
            ? userDetails?.organization?.totalDocuments
            : userDetails?.totalDocuments
        }`,
      },
      {
        title: t("orgUserDocuments"),
        icon: IconNames.PERSON,
        iconColor: "grey",
        active: true,
        stat: `${ userDetails?.totalDocuments }`,
      },
      
      // TODO: Enable in V2
      // {
      //   title: t("teams"),
      //   icon: IconNames.USER,
      //   iconColor: "primary",
      //   stat: "50",
      // },
      // {
      //   title: t("customLibraries"),
      //   icon: IconNames.STACKED_CHART,
      //   iconColor: "crimson",
      //   stat: "50",
      // },
    ];
  };

  return (
    <div className={defaultClass}>
      <Grid fluid>
        <PageSection>
          <PageTitle>{t("overview")}</PageTitle>
        </PageSection>
        <PageSection>
        {
          !isTeamPlan 
          ? (<Row>
            {userLoading || !!!userDetails.organization
              ? LoadingIndicator
              : getPersonalStats()
                  .filter((item) => item.active)
                  .map((stat, index) => (
                    <Col xs={6} md={3} key={index}>
                      <StatCard {...stat} />
                    </Col>
                  ))}
            </Row>)
          : (<Row>
            {userLoading || !!!userDetails.organization
              ? LoadingIndicator
              : getOrganizationStats()
                  .filter((item) => item.active)
                  .map((stat, index) => (
                    <Col xs={6} md={3} key={index}>
                      <StatCard {...stat} />
                    </Col>
                  ))}
            </Row>)
        }
        </PageSection>
      </Grid>
    </div>
  );
};
