import {
  Intent,
  Spinner,
  Icon,
  IconName,
  MaybeElement,
} from "@blueprintjs/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { IComponentProps } from "../../component-props.i";
import { IconImage } from "../icons/icons";
import "./image-view-upload-input.scss";

export interface IImageViewUploadInputProps extends IComponentProps {
  imageUrl: string | undefined;
  uploadImage(image: string): void;
  disabled?: boolean;
  placeholderIcon?: IconName | MaybeElement;
  imageUnavailableIcon?: IconName | MaybeElement;
}

export const ImageViewUploadInput: React.FC<IImageViewUploadInputProps> = (
  props
) => {
  const defaultClass = "image-view-upload";

  const [imageUrl, setImageUrl] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [hasImage, setHasImage] = useState(false);

  const handleUpload = async (e: any) => {
    e.preventDefault();
    setIsUploading(true);
    let files = e.target.files;
    let file = files[0];
    if (!e.target.files.length) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function (readerEvt: any) {
      let binaryString = readerEvt.target.result;
      const base64 = btoa(binaryString);
      setIsUploading(false);
      props.uploadImage(base64);
    };
    setImage({
      preview: URL.createObjectURL(file),
      raw: "",
    });
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    setImageUrl(props.imageUrl);
    setHasImage(!!imageUrl || !!image?.preview);
  });

  return (
    <div
      className={classNames(
        defaultClass,
        props.className,
        isUploading && defaultClass + "--uploading",
        props.disabled && defaultClass + "--disabled",
        !hasImage && "--no-image"
      )}
    >
      {!hasImage && !props.disabled && !!props.placeholderIcon && (
        <div className={defaultClass + "__icon-default"}>
          <Icon icon={props.placeholderIcon} iconSize={50} />
        </div>
      )}
      <img
        className={`${defaultClass}__image`}
        src={!!image?.preview ? image.preview : imageUrl}
      />
      {!props.disabled && (
        <>
          <label
            htmlFor={`${defaultClass}__upload-input`}
            className={`bp3-button bp3-intent-primary bp3-minimal ${defaultClass}__upload-button`}
          >
            <div className={defaultClass + "__icon"}>
              <Icon icon={IconImage} iconSize={50} />
            </div>
          </label>
          <input
            type="file"
            id={`${defaultClass}__upload-input`}
            className={`${defaultClass}__upload-input`}
            onChange={handleUpload}
          />
          <Spinner
            intent={Intent.PRIMARY}
            className={`${defaultClass}__spinner`}
          />
        </>
      )}
    </div>
  );
};
