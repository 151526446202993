import React, { useEffect, useState } from "react";
import { IComponentProps } from "../../component-props.i";
import { FilteredUser } from "./filtered-user-tile/filtered-user-tile";
import AsyncSelect from "react-select/async";
import { useService } from "@creately/use-service";
import { API_SERVICE } from "../../token";
import { ApiService } from "../../services/api.s";
import { Button, Card, H6, Icon, Intent } from "@blueprintjs/core";
import { components } from "react-select";
import "./user-filter-input.scss";
import classNames from "classnames";
import { Col, Row } from "react-flexbox-grid";
import { BillingPageSection } from "../../pages/billing-page/billing-page";
import { useActions, useValues } from "kea";
import { organizationLogic } from "../../logics/organization.logic";
import { IUser } from "../../models/user.i";
import { userLogic } from "../../logics/user.logic";
import { useTranslation } from "react-i18next";
import { isEmail } from "react-multi-email";
import { appLogic } from "../../logics/app.logic";
import { Modal } from "../modal/modal";
import { tEn } from "../../services/en-translate.s";

/**
 * User interface used by the user search component.
 * @author Nuwan
 * @since 2021-04-08
 */
export interface SearchUser {
  id?: string;
  value: string; //value is email of the user
  avatarURL?: string;
  label: string;
}

export interface IUserSearchInputProps extends IComponentProps {
  navigator: (value: BillingPageSection) => void;
  currentBillingContacts?: any[];
}

/**
 * This component will show the the filtered users for a typed user name
 * and will be added to the filtered users section when confirmation of adding.
 */
export const UserFilterInput: React.FC<IUserSearchInputProps> = (props) => {
  const defaultClass = "user-filter-input";
  const [filteredUsers, setFilteredUsers] = useState<SearchUser[]>([]);
  const [userInput, setuserInput] = useState<string>("");
  const [searchBoxValue, setSearchBoxValue] = useState<any>("");
  const { updateOrganization } = useActions(organizationLogic);
  const { user }: { user: IUser; userLoading: boolean } = useValues(userLogic);
  const { t } = useTranslation();
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  // Customized option component to show icons on the dropdown
  const { Option } = components;
  const { track } = useActions(appLogic);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    track("accountPortal.billing.newContact.load");
  }, []);

  const customOption = (props: any) => (
    <Option {...props}>
      <div className={classNames(`${defaultClass}__custom-option-box`)}>
        <div>
          {!props.data.avatar.url && (
            <Icon
              className={classNames(`${defaultClass}__dropdown-icon`)}
              icon="user"
              iconSize={20}
              color="grey"
            />
          )}
          {props.data.avatar.url && (
            <img
              className={classNames(`${defaultClass}__dropdown-icon`)}
              src={props.data.avatar.url}
            />
          )}
        </div>
        <div className={classNames(`${defaultClass}__custom-option-text-box`)}>
          <div>{nameLabel(props.data)} </div>
          <div>{props.data.email}</div>
        </div>
      </div>
    </Option>
  );

  // custom styles of the react-select component
  const style = {
    control: (base: any) => ({
      ...base,
      border: "1px solid #C3C3DE",
      boxShadow: "none",
    }),
  };

  /**
   * Get the selected user from the dropdown.
   */
  const handleChanges = (user: any) => {
    const existingUser = filteredUsers.find((obj) => obj.value === user.email);
    if (!existingUser) {
      setFilteredUsers((filteredUsers) => [
        ...filteredUsers,
        {
          id: user.id,
          avatarURL: user.avatar.url,
          label: nameLabel(user),
          value: user.email,
        },
      ]);
      setSearchBoxValue(null);
      track("accountPortal.billing.newContact.add.click", {
        value1Type: "billingContact",
        value1: "Internal",
      });
    }
  };

  /**
   * Remove a user from the filtered user array.
   * @param id - Email id of the user to be removed.
   */
  const removeUser = (id: string) => {
    const out = filteredUsers.filter((o) => o.value !== id);
    setFilteredUsers(out);
  };

  /**
   * Add user by email on Enter key stroke.
   */
  const onkeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (isEmail(userInput)) {
        setFilteredUsers((filteredUsers) => [
          ...filteredUsers,
          { value: userInput, label: userInput },
        ]);
        setSearchBoxValue(null);
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
        track("accountPortal.billing.newContact.add.click", {
          value1Type: "billingContact",
          value1: "External",
        });
      }
    }
  };

  /**
   * get the user input and update the local state.
   * @param newValue User typed text on search area
   */
  const onInputChange = (newValue: string) => {
    setuserInput(newValue);
  };

  // retrieve and load users from API
  const loadOptions = async (inputValue: string) => {
    if (inputValue.length > 0) {
      const api = useService<ApiService>(API_SERVICE);
      return api.getEnterpriseUsers(inputValue).then((res) => {
        // filter the current existing users
        if (res?.organization?.users && props.currentBillingContacts) {
          const billingContactEmails: string[] = props.currentBillingContacts.map(
            (item) => item?.email
          );
          return res.organization.users.filter(
            (user: any) => !!!billingContactEmails.includes(user.email)
          );
        }
        return res?.organization?.users;
      });
    }
  };

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  /**
   * This method returns the label that should be shown on dropdown.
   * label= avatar + First name + last name
   * if frist name and last names are not available shows email along with avatar
   * @param obj user object from api response
   */
  const nameLabel = (obj: any) => {
    if (obj.firstName && obj.lastName) {
      return obj.firstName + " " + obj.lastName;
    } else {
      return obj.email;
    }
  };

  const handleSubmit = () => {
    const emails: string[] = filteredUsers.map((u) => u.value);
    updateOrganization({
      id: user.organization.id,
      billingContacts: emails,
    });
    track("accountPortal.billing.newContact.save.click");
  };

  const handleClose = () => {
    if (filteredUsers.length > 0) {
      toggleIsOpen();
      track("accountPortal.billing.newContact.close.click");
      track("dialog.accountPortal.billing.load", {
        value1: tEn("pages.billing.addContactsPopUpTitle"),
        value2: tEn("pages.billing.addContactsPopUpDescription"),
      });
    } else {
      props.navigator(BillingPageSection.Billing);
      track("accountPortal.billing.newContact.close.click");
    }
  };

  const returnToHomePage = () => {
    props.navigator(BillingPageSection.Billing);
    track("dialog.accountPortal.billing.click", {
      value1: "Close without Saving",
    });
  };

  const handlePopupClose = () => {
    toggleIsOpen();
    track("dialog.accountPortal.billing.click", { value1: "Cancel" });
  };

  const addBillingContactsPopUp = (
    <Row>
      <Col md={2}>
        {/* <Icon intent="primary" iconSize={60} icon="error"></Icon> */}
        <img src="/resources/images/exclamation-icon.svg"></img>
      </Col>
      <Col md={10}>
        <H6>{t("pages.billing.addContactsPopUpTitle")}</H6>
        <p>{t("pages.billing.addContactsPopUpDescription")}</p>
      </Col>
    </Row>
  );

  return (
    <Row>
      <div className={classNames(`${defaultClass}__popup-box`)}>
        <Card className="user-filter">
          <div>
            <span className={classNames(`${defaultClass}__heading`)}>
              {t("pages.billing.addBillingContactPopUpTitle")}
            </span>
            <a
              className={classNames(`${defaultClass}__close-icon`)}
              onClick={() => handleClose()}
            >
              <Icon icon="cross" iconSize={12} />
            </a>
          </div>
          <div className={classNames(`${defaultClass}__hr-line`)} />
          <p>{t("pages.billing.addBillingContactPopUpSubTitle")}</p>
          <div onKeyUp={onkeyUp}>
            <AsyncSelect
              value={searchBoxValue}
              defaultOptions
              getOptionLabel={(e) => nameLabel(e)}
              getOptionValue={(e) => e.email}
              onChange={handleChanges}
              onInputChange={onInputChange}
              loadOptions={loadOptions}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: customOption,
              }}
              placeholder={
                <div>
                  <Icon icon="search" />
                  {" Search People or Add Email"}
                </div>
              }
              styles={style}
            />
            {!isValidEmail && (
              <div className={classNames(`${defaultClass}__invalid-email`)}>
                {t("pages.billing.invalidEmail")}
              </div>
            )}
            <div>
              {filteredUsers.map((v) => (
                <FilteredUser
                  key={v.value}
                  value={v.label}
                  id={v.value}
                  thumbnail={v.avatarURL}
                  onRemove={removeUser}
                ></FilteredUser>
              ))}
            </div>
          </div>
          <p className={classNames(`${defaultClass}__note-text`)}>
            {t("pages.billing.addBillingContactNote")}
          </p>
          <div className={classNames(`${defaultClass}__hr-line`)} />
          <div className={classNames(`${defaultClass}__btn-box`)}>
            <Button
              onClick={() => handleSubmit()}
              intent={Intent.PRIMARY}
              disabled={!!!filteredUsers.length}
              className={classNames(`${defaultClass}__btn-save`)}
            >
              {t("save")}
            </Button>
            <Button
              onClick={() => handleClose()}
              className={classNames(`${defaultClass}__btn-cancel bp3-intent-secondary`)}
            >
              {t("cancel")}
            </Button>
            <Modal
              onClose={handleClose}
              isOpen={isOpen}
              button2Text={t("cancel")}
              button2Action={handlePopupClose}
              button1Text={t("pages.billing.closeWithoutSaving")}
              button1Action={returnToHomePage}
              button1Intent={"primary"}
              className={classNames(`${defaultClass}__confirm-popup-box`)}
            >
              {addBillingContactsPopUp}
            </Modal>
          </div>
        </Card>
      </div>
    </Row>
  );
};
