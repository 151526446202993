/**
 * This is an abstraction for the authentication. This class is just a skeleton
 * for the common functionality used across all authentication services among apps.
 * This is intended to be extended by a concrete service to provide the actual
 * implementation.
 */
export class AbstractAuthentication {
  /**
   * Returns the current authentication token
   */
  public get token(): string | null {
    return null;
  }

  /**
   * Returns if the user is authenticated or not
   */
  public get isAuthenticated(): boolean {
    return false;
  }
}
